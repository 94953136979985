<template>
  <ContentLimiter>
    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserLinksCard />
    </div>
    <div class="card col-9 col-t-8 col-m-12">
      <div class="card-section">
        <AccountInformationForm v-if="currentUser" :user="currentUser" @submit="submit" />
      </div>
      <div class="card-section">
        <h3 class="m-b-s">Deactivate profile</h3>

        <p class="m-b-s">
          Once you deactivate your Huff Books profile your ads and profile will
          no longer be visible to other users until you come back However all of
          your data, and ads will be saved.
        </p>

        <FormRow>
          <MainCTA color="gray" @click="deactivateAccount">Deactivate</MainCTA>
        </FormRow>
      </div>
      <div class="card-section">
        <h3 class="m-b-s">Delete profile</h3>
        <p class="m-b-s">
          Note that all of your data, sales, order history, etc. will be
          permanetly deleted.
        </p>
        <FormRow>
          <MainCTA color="red" @click="deleteAccount">Delete</MainCTA>
        </FormRow>
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import MainCTA from "@/components/form/MainCTA.vue";

import AccountInformationForm from "@/views/forms/AccountInformationForm.vue";

import constants from "@/util/constants";

export default {
  name: "AccountInfo",
  components: {
    ContentLimiter,
    NewsletterBlock,
    UserLinksCard,
    FormRow,
    MainCTA,
    AccountInformationForm,
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    }
  },
  methods: {
    async submit(data) {
      await this.$store.dispatch("profile/updateProfileInformation", {
        city: this.currentUser.city,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: this.currentUser.mobile,
        state: this.currentUser.state,
      });

      this.$store.dispatch("authentication/getUserData");

      Vue.$toast.open({
        message: `Profile information saved!`,
        type: "success",
        position: "bottom",
      });
    },
    deactivateAccount() {
      window.$prompt(
        "You are about to deactivate your account.",
        `Your account will be activated the next time you log in.`,
        () => {
          setTimeout(
            () =>
              window.$prompt(
                "Confirm",
                `Are you sure you want to proceed?`,
                () => {
                  this.$store.dispatch("authentication/deactivateAccount");
                  window.location.reload();
                }
              ),
            100
          );
        }
      );
    },
    deleteAccount() {
      window.$prompt(
        "You are about to delete your account.",
        `All the data related to your account will be permanently lost.`,
        () => {
          setTimeout(
            () =>
              window.$prompt(
                "Confirm",
                `Are you sure you want to proceed?`,
                () => {
                  this.$store.dispatch("authentication/deleteAccount");
                  window.location.reload();
                }
              ),
            100
          );
        }
      );
    },
  },
};
</script>
