<template>
  <ContentLimiter>
    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserLinksCard />
    </div>
    <div v-if="statistics && currentUser" class="card col-9 col-t-8 col-m-12">
      <div class="card-section">
        <h2 class="m-b-m">Sales Statistics</h2>

        <div class="statistics-row">
          <div class="col-3 col-t-6 col-m-12">
            <span>
              Books you posted:
              <br />
              <br />
            </span>
            <strong>{{ statistics.booksPosted }}</strong>
          </div>

          <div class="col-3 col-t-6 col-m-12">
            <span>
              Books you sold:
              <br />
              <br />
            </span>
            <strong>{{ statistics.booksSold }}</strong>
          </div>

          <div class="col-3 col-t-6 col-m-12">
            <span>
              Reviews on ads:
              <br />
              <br />
            </span>
            <strong>{{ currentUser.reviews.length }}</strong>
          </div>

          <div class="col-3 col-t-6 col-m-12">
            <span>
              Premium Seller
              <br />
              <br />
            </span>
            <strong class="primary-color-text">
              
              {{ currentUser.activeAds.length + currentUser.inactiveAds.length }}/100 Ads posted
              <img v-if="currentUser.activeAds.length + currentUser.inactiveAds.length >= 100" src="@/assets/svg/badge.svg" />
            </strong>
          </div>
        </div>
      </div>

      <div class="card-section m-b-m">
        <div class="statistics-row">
          <div class="col-3 col-t-6 col-m-12">
            <span>
              Total Earnings:
              <br />
              <br />
            </span>
            <strong>AUD$ {{ statistics.totalEarnings.toFixed() }}</strong>
          </div>

          <div class="col-3 col-t-6 col-m-12">
            <span>
              Watchlisted Ads:
              <br />
              <br />
            </span>
            <strong>{{ statistics.watchlistedAds }}</strong>
          </div>

          <div class="col-3 col-t-6 col-m-12">
            <span>
              Bestsold Subjects:
              <br />
              <br />
            </span>
            <strong v-if="statistics.bestSoldCategories" >{{ statistics.bestSoldCategories }}</strong>
            <span class="smaller-text" v-else >No categories available at this moment.</span>
          </div>
        </div>
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";

export default {
  name: "Statistics",
  components: {
    ContentLimiter,
    NewsletterBlock,
    UserLinksCard,
  },
  created() {
    this.$store.dispatch("profile/setStatistics");
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    statistics() {
      return this.$store.getters["profile/statistics"]();
    }
  }
};
</script>
