<template>
  <div class="topic-container card m-b-s">
    <h3>Topics</h3>

    <router-link
      v-for="topic in topics"
      :key="topic.slug"
      :to="`/forum/${topic.slug}`"
    >
      <img class="m-r-xs" src="@/assets/svg/logo-icon-only-orange.svg" />
      {{ topic.name }}
    </router-link>
  </div>
</template>

<script>
import { ForumTopics } from "@/util/constants/enums";

export default {
  name: "ForumTopics",
  data() {
    return {
      topics: ForumTopics,
    };
  },
};
</script>