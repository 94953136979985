<template>
  <div class="orange-body">
    <ContentLimiter>
      <div class="flex-center">
        <div class="card narrow">
          <ForgotPasswordForm @submit="submitForgotPassword" />
        </div>
      </div>
    </ContentLimiter>
  </div>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import ForgotPasswordForm from "@/views/forms/ForgotPasswordForm.vue";

export default {
  name: "ForgotPassword",
  components: {
    ContentLimiter,
    ForgotPasswordForm,
  },
  methods: {
    async submitForgotPassword(data) {
      await this.$store.dispatch("authentication/sendResetEmail", data.email);

      Vue.$toast.open({
        message: `E-mail sent! You can close this page and check your inbox!`,
        type: "success",
        position: "bottom",
      });
    },
  },
};
</script>