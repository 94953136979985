<template>
    <div class="modal-container" >
        <div class="curtain" @click="$emit('close')" >
        </div>
        <div class="modal" >
            <button class="close-button" @click="$emit('close')">
                <img src="@/assets/svg/close-white.svg" />
            </button>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal'
}
</script>