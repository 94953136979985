import Vue from "vue";

class TaskHelper {
    executeSafely = async (task) => {
        try {
            return await task();
        } catch (error) {
            Vue.$toast.open({
                message: error,
                type: "error",
                position: "bottom",
            });

            console.error(error);
        }
    };
}

export default new TaskHelper();