import WatchlistClient from "../clients/WatchlistClient";

class WatchlistService {
    async addToWatchlist(adId) {
        return await WatchlistClient.addToWatchlist(adId);
    }

    async removeFromWatchlist(adId) {
        return await WatchlistClient.removeFromWatchlist(adId);
    }

    async getItems() {
        return await WatchlistClient.getWatchlist();
    }
}

export default new WatchlistService();