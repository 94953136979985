<template>
  <div>
    <h1 class="m-b-s">Join Us</h1>

    <FormRow>
      <TextField
        label="First name"
        :defaultValue="form.firstName.value"
        placeholder="e.g. John"
        :errorMessage="form.firstName.error"
        @change="(value) => set('firstName', value, form)"
      />
    </FormRow>

    <FormRow>
      <TextField
        label="Last name"
        :defaultValue="form.lastName.value"
        placeholder="e.g. Doe"
        :errorMessage="form.lastName.error"
        @change="(value) => set('lastName', value, form)"
      />
    </FormRow>

    <FormRow>
      <TextField
        label="Email"
        :defaultValue="form.email.value"
        placeholder="e.g. john.doe@example.com"
        :errorMessage="form.email.error"
        @change="(value) => set('email', value, form)"
      />
    </FormRow>

    <FormRow v-if="passwordShown">
      <TextField
        label="Password"
        :defaultValue="form.password.value"
        placeholder="Must contain letters, numbers and symbols"
        :errorMessage="form.password.error"
        @change="(value) => set('password', value, form)"
      />
    </FormRow>

    <FormRow v-else>
      <PasswordField
        label="Password"
        :defaultValue="form.password.value"
        placeholder="Must contain letters, numbers and symbols"
        :errorMessage="form.password.error"
        @change="(value) => set('password', value, form)"
      />
    </FormRow>

    <FormRow>
      <CheckboxField text="Show password" @change="togglePasswordVisibility" />
    </FormRow>

    <div class="form-row flex-distributed-row full-width-button">
      <MainCTA @click="register" :disabled="!validate(form)"
        >Create your account</MainCTA
      >
    </div>

    <div class="form-row flex-distributed-row">
      <div class="google-login-btn social-login-btn col-6 full-width-button">
        <GoogleLogin
          :onSuccess="googleSignIn"
          :params="{ client_id: constants.googleAppId }">
          <span>Google</span>
        </GoogleLogin>
      </div>

      <div class="facebook-login-btn social-login-btn col-6 full-width-button">
        <FacebookLogin
          class="btn"
          loginLabel="Facebook"
          :appId="constants.facebookAppId"
          @login="facebookSignIn"
        >
        </FacebookLogin>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import GoogleLogin from "vue-google-login";
import FacebookLogin from "facebook-login-vuejs";
import {
  set,
  validate,
  required,
  isEmail,
  minLength,
  maxLength,
} from "vue-val";

import FormRow from "@/components/form/FormRow.vue";
import PasswordField from "@/components/form/PasswordField.vue";
import TextField from "@/components/form/TextField.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";

import constants from "@/util/constants";

export default {
  name: "RegisterForm",
  data() {
    return {
      passwordShown: false,
      constants,
      form: {
        firstName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        lastName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        email: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320), isEmail],
        },
        password: {
          valid: true,
          error: null,
          constraints: [required, minLength(8), maxLength(100)],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    togglePasswordVisibility(checked) {
      this.passwordShown = checked;
    },
    async register() {
      await this.$store.dispatch("authentication/register", {
        firstName: this.form.firstName.value,
        lastName: this.form.lastName.value,
        email: this.form.email.value,
        password: this.form.password.value,
      });

      Vue.$toast.open({
        message: `Verification e-mail sent! Check your inbox!`,
        type: "success",
        position: "bottom",
      });
    },
    async googleSignIn(googleUser) {
      await this.$store.dispatch("authentication/googleSignIn", googleUser);

      this.$store.dispatch("watchlist/setWatchlist");

      this.$router.push("/");
    },
    async facebookSignIn(facebookUser) {
      await this.$store.dispatch("authentication/facebookSignIn", facebookUser);
      
      this.$store.dispatch("watchlist/setWatchlist");

      this.$router.push("/");
    },
  },
  components: {
    GoogleLogin,
    FacebookLogin,
    FormRow,
    PasswordField,
    TextField,
    MainCTA,
    CheckboxField,
  },
};
</script>