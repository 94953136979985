import BaseApiClient from "./BaseApiClient";


class HeroSectionClient extends BaseApiClient {

    getHeroSection() {
        return this.get(`/api/hero-section`)
    }
}

export default new HeroSectionClient();
