import addressService from "@/services/addressService";

export default {
    namespaced: true,
    state: {
        currentUserAddresses: null,
        editedAddress: null,
    },
    mutations: {
        setCurrentUserAddresses: (state, payload) => state.currentUserAddresses = payload,
        setEditedAddress: (state, payload) => state.editedAddress = payload,
    },
    actions: {
        setCurrentUserAddresses: async (context) => context.commit("setCurrentUserAddresses", await addressService.getUserAddresses()),

        createAddress: async (context, address) => {
            await addressService.createAddress(address);

            context.commit("setCurrentUserAddresses", await addressService.getUserAddresses());
        },
        setEditedAddress: async (context, address) => {
            context.commit("setEditedAddress", address);
        },
        editAddress: async (context, address) => {
            await addressService.editAddress(address);

            context.commit("setCurrentUserAddresses", await addressService.getUserAddresses());
        },
        deleteAddress: async (context, address) => {
            await addressService.deleteAddress(address);

            context.commit("setCurrentUserAddresses", await addressService.getUserAddresses());
        },
    },
    getters: {
        currentUserAddresses: state => () => state.currentUserAddresses,
        editedAddress: state => () => state.editedAddress,
    }
};