<template>
  <ContentLimiter>
    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserLinksCard />
    </div>
    <div class="card col-9 col-t-8 col-m-12">
      <ContactInformationForm v-if="currentUser" :user="currentUser" @submit="submit" />
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";

import ContactInformationForm from "@/views/forms/ContactInformationForm.vue";

export default {
  name: "ContactInfo",
  components: {
    ContentLimiter,
    NewsletterBlock,
    UserLinksCard,
    ContactInformationForm,
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    }
  },
  methods: {
    async submit(data) {
      await this.$store.dispatch("profile/updateProfileInformation", {
        city: data.city,
        email: this.currentUser.email,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        mobile: data.mobile,
        state: data.state,
      });

      this.$store.dispatch("authentication/getUserData");

      Vue.$toast.open({
        message: `Profile information saved!`,
        type: "success",
        position: "bottom",
      });
    },
  },
};
</script>
