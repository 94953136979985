<template>
  <div>
    <h2 class="m-b-s m-t-xs">Newsletter</h2>

    <p class="m-b-m">Create and send a newsletter email to your users.</p>

    <div class="card-section">
      <wysiwyg v-model="form.content.value" />
    </div>

    <div class="card-section">
      <MainCTA @click="submit" :disabled="!form.content.value.length">Send</MainCTA>
    </div>
  </div>
</template>

<script>
import {
  set,
  validate,
  required,
  maxLength,
} from "vue-val";

import MainCTA from "@/components/form/MainCTA.vue";

export default {
  name: "AdminNewsletterForm",
  components: {
    MainCTA,
  },
  data() {
    return {
      form: {
        content: {
          value: "",
          valid: false,
          error: null,
          constraints: [required],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    submit() {
      const resultObject = {};

      Object.keys(this.form)
        .forEach(key => resultObject[key] = this.form[key].value);

      this.$emit("submit", resultObject);
    },
  },
};
</script>