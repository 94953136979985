import Vue from 'vue';
import VueRouter from 'vue-router';

import Admin from '../views/Admin.vue';
import Home from '../views/Home.vue';
import Account from '../views/Account.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import ChangePassword from '../views/ChangePassword.vue';
import Results from '../views/Results.vue';
import Ad from '../views/Ad.vue';
import Author from '../views/Author.vue';
import OrderHistory from '../views/OrderHistory.vue';
import Watchlist from '../views/Watchlist.vue';
import SellerProfile from '../views/SellerProfile.vue';
import Basket from '../views/Basket.vue';
import Checkout from '../views/Checkout.vue';
import Help from '../views/Help.vue';
import CreateAd from '../views/CreateAd.vue';
import EditAd from '../views/EditAd.vue';
import Contact from '../views/Contact.vue';
import AccountInfo from '../views/AccountInfo.vue';
import AddressBook from '../views/AddressBook.vue';
import ContactInfo from '../views/ContactInfo.vue';
import Statistics from '../views/Statistics.vue';
import PaymentCards from '../views/PaymentCards.vue';
import OrderPlaced from '../views/OrderPlaced.vue';
import Forum from '../views/Forum.vue';
import ForumPost from '../views/ForumPost.vue';
import PageNotFound from '../views/PageNotFound.vue';

import constants from '../util/constants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      protected: true,
      admin: true,
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/results',
    name: 'Results',
    component: Results
  },
  {
    path: '/ad/:id',
    name: 'Ad',
    component: Ad
  },
  {
    path: '/authors/:slug',
    name: 'Author',
    component: Author
  },
  {
    path: '/watchlist',
    name: 'Watchlist',
    component: Watchlist,
    meta: {
      protected: true
    }
  },
  {
    path: '/profile/:id',
    name: 'SellerProfile',
    component: SellerProfile,
    meta: {
      protected: true
    }
  },
  {
    path: '/profile',
    name: 'MyProfile',
    component: SellerProfile,
    meta: {
      protected: true
    }
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/create-ad',
    name: 'CreateAd',
    component: CreateAd,
    meta: {
      protected: true
    }
  },
  {
    path: '/edit-ad/:id',
    name: 'EditAd',
    component: EditAd,
    meta: {
      protected: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/account-info',
    name: 'AccountInfo',
    component: AccountInfo,
    meta: {
      protected: true
    }
  },
  {
    path: '/address-book',
    name: 'AddressBook',
    component: AddressBook,
    meta: {
      protected: true
    }
  },
  {
    path: '/contact-info',
    name: 'ContactInfo',
    component: ContactInfo,
    meta: {
      protected: true
    }
  },
  {
    path: '/order-history',
    name: 'OrderHistory',
    component: OrderHistory,
    meta: {
      protected: true
    }
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      protected: true
    }
  },
  {
    path: '/order-placed',
    name: 'OrderPlaced',
    component: OrderPlaced
  },
  {
    path: '/payment-cards',
    name: 'PaymentCards',
    component: PaymentCards
  },
  {
    path: '/forum/posts/:id',
    name: 'ForumPost',
    component: ForumPost
  },
  {
    path: '/forum/:topicId',
    name: 'ForumTopic',
    component: Forum
  },
  {
    path: '/forum',
    name: 'Forum',
    component: Forum
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(constants.tokenLocalStorageKey);

  if (token && to.name == "Account")
      next({ name: "Home" });
    
  if (to.meta.protected && !token) 
    next({ name: "Account" });
  else
    next();
})

export default router;
