<template>
  <div class="book-badge">
    <router-link
      :to="`/ad/${adId}`"
      class="image"
      :style="`background-image: url(${image});`"
      :title="name"
    >
    </router-link>

    <strong class="title" :title="name">
      <router-link :to="`/ad/${adId}`">{{ name }}</router-link>
    </strong>

    <div>
      <span class="author" :title="name"
        ><span>By:</span>
        <u :title="authorName">{{
          authorName
        }}</u>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookBadge",
  props: {
    adId: {
      required: true,
    },
    image: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    authorName: {
      type: String,
      required: false,
      default: "",
    },
    authorSlug: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>