<template>
  <div>
    <div class="card-section">
      <h2 class="m-b-s m-t-xs">Landing section</h2>

      <p class="m-b-m">Edit images and text inside page landing items.</p>

      <div>
        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.landingSection1Image"
              @load="(value) => set('landingSection1Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Text for the first item"
              :defaultValue="form.landingSection1Text.value"
              :errorMessage="form.landingSection1Text.error"
              @change="(value) => set('landingSection1Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.landingSection1Link.value"
              :errorMessage="form.landingSection1Link.error"
              @change="(value) => set('landingSection1Link', value, form)"
            />
          </FormRow>
        </div>

        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.landingSection2Image"
              @load="(value) => set('landingSection2Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Text for the second item"
              :defaultValue="form.landingSection2Text.value"
              :errorMessage="form.landingSection2Text.error"
              @change="(value) => set('landingSection2Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.landingSection2Link.value"
              :errorMessage="form.landingSection2Link.error"
              @change="(value) => set('landingSection2Link', value, form)"
            />
          </FormRow>
        </div>

        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.landingSection3Image"
              @load="(value) => set('landingSection3Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Text for the third item"
              :defaultValue="form.landingSection3Text.value"
              :errorMessage="form.landingSection3Text.error"
              @change="(value) => set('landingSection3Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.landingSection3Link.value"
              :errorMessage="form.landingSection3Link.error"
              @change="(value) => set('landingSection3Link', value, form)"
            />
          </FormRow>
        </div>
      </div>
    </div>

    <div class="card-section">
      <h2 class="m-b-s">Most popular authors</h2>

      <p class="m-b-m">
        Add image and name for 6 authors that should be displayed on the home
        page.
      </p>

      <div>
        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <h3 class="m-b-xs">Author 1</h3>

          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.author1Image"
              @load="(value) => set('author1Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Author's name"
              :defaultValue="form.author1Text.value"
              :errorMessage="form.author1Text.error"
              @change="(value) => set('author1Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.author1Link.value"
              :errorMessage="form.author1Link.error"
              @change="(value) => set('author1Link', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextareaField
              placeholder="Author's description"
              :defaultValue="form.author1Description.value"
              :errorMessage="form.author1Description.error"
              @change="(value) => set('author1Description', value, form)"
            />
          </FormRow>
        </div>

        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <h3 class="m-b-xs">Author 2</h3>

          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.author2Image"
              @load="(value) => set('author2Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Author's name"
              :defaultValue="form.author2Text.value"
              :errorMessage="form.author2Text.error"
              @change="(value) => set('author2Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.author2Link.value"
              :errorMessage="form.author2Link.error"
              @change="(value) => set('author2Link', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextareaField
              placeholder="Author's description"
              :defaultValue="form.author2Description.value"
              :errorMessage="form.author2Description.error"
              @change="(value) => set('author2Description', value, form)"
            />
          </FormRow>
        </div>

        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <h3 class="m-b-xs">Author 3</h3>

          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.author3Image"
              @load="(value) => set('author3Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Author's name"
              :defaultValue="form.author3Text.value"
              :errorMessage="form.author3Text.error"
              @change="(value) => set('author3Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.author3Link.value"
              :errorMessage="form.author3Link.error"
              @change="(value) => set('author3Link', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextareaField
              placeholder="Author's description"
              :defaultValue="form.author3Description.value"
              :errorMessage="form.author3Description.error"
              @change="(value) => set('author3Description', value, form)"
            />
          </FormRow>
        </div>
      </div>
      <div>
        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <h3 class="m-b-xs">Author 4</h3>

          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.author4Image"
              @load="(value) => set('author4Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Author's name"
              :defaultValue="form.author4Text.value"
              :errorMessage="form.author4Text.error"
              @change="(value) => set('author4Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.author4Link.value"
              :errorMessage="form.author4Link.error"
              @change="(value) => set('author4Link', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextareaField
              placeholder="Author's description"
              :defaultValue="form.author4Description.value"
              :errorMessage="form.author4Description.error"
              @change="(value) => set('author4Description', value, form)"
            />
          </FormRow>
        </div>
        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <h3 class="m-b-xs">Author 5</h3>

          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.author5Image"
              @load="(value) => set('author5Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Author's name"
              :defaultValue="form.author5Text.value"
              :errorMessage="form.author5Text.error"
              @change="(value) => set('author5Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.author5Link.value"
              :errorMessage="form.author5Link.error"
              @change="(value) => set('author5Link', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextareaField
              placeholder="Author's description"
              :defaultValue="form.author5Description.value"
              :errorMessage="form.author5Description.error"
              @change="(value) => set('author5Description', value, form)"
            />
          </FormRow>
        </div>
        <div class="hero-section-item-picker col-3 col-t-4 col-m-12 m-b-s">
          <h3 class="m-b-xs">Author 6</h3>

          <FormRow>
            <ImagePicker
              :defaultImagePath="defaults.author6Image"
              @load="(value) => set('author6Image', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Author's name"
              :defaultValue="form.author6Text.value"
              :errorMessage="form.author6Text.error"
              @change="(value) => set('author6Text', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextField
              placeholder="Link"
              :defaultValue="form.author6Link.value"
              :errorMessage="form.author6Link.error"
              @change="(value) => set('author6Link', value, form)"
            />
          </FormRow>

          <FormRow>
            <TextareaField
              placeholder="Author's description"
              :defaultValue="form.author6Description.value"
              :errorMessage="form.author6Description.error"
              @change="(value) => set('author6Description', value, form)"
            />
          </FormRow>
        </div>
      </div>
    </div>

    <div class="card-section m-b-s">
      <MainCTA @click="submit" :disabled="!validate(form)">Save</MainCTA>
    </div>
  </div>
</template>

<script>
import { set, validate, required, maxLength } from "vue-val";

import ImagePicker from "@/components/form/ImagePicker.vue";
import FormRow from "@/components/form/FormRow.vue";
import TextField from "@/components/form/TextField.vue";
import TextareaField from "@/components/form/TextareaField.vue";
import MainCTA from "@/components/form/MainCTA.vue";

export default {
  name: "AdminContentForm",
  components: {
    FormRow,
    ImagePicker,
    TextField,
    TextareaField,
    MainCTA,
  },
  data() {
    return {
      defaults: {
        landingSection1Image: "",
        landingSection2Image: "",
        landingSection3Image: "",
        author1Image: "",
        author2Image: "",
        author3Image: "",
        author4Image: "",
        author5Image: "",
        author6Image: "",
      },

      form: {
        landingSection1Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        landingSection1Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        landingSection1Link: {
          valid: true,
          error: null,
          constraints: [],
        },

        landingSection2Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        landingSection2Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        landingSection2Link: {
          valid: true,
          error: null,
          constraints: [],
        },

        landingSection3Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        landingSection3Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        landingSection3Link: {
          valid: true,
          error: null,
          constraints: [],
        },

        author1Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        author1Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        author1Link: {
          valid: true,
          error: null,
          constraints: [],
        },
        author1Description: {
          valid: true,
          error: null,
          constraints: [],
        },

        author2Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        author2Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        author2Link: {
          valid: true,
          error: null,
          constraints: [],
        },
        author2Description: {
          valid: true,
          error: null,
          constraints: [],
        },

        author3Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        author3Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        author3Link: {
          valid: true,
          error: null,
          constraints: [],
        },
        author3Description: {
          valid: true,
          error: null,
          constraints: [],
        },

        author4Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        author4Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        author4Link: {
          valid: true,
          error: null,
          constraints: [],
        },
        author4Description: {
          valid: true,
          error: null,
          constraints: [],
        },

        author5Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        author5Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        author5Link: {
          valid: true,
          error: null,
          constraints: [],
        },
        author5Description: {
          valid: true,
          error: null,
          constraints: [],
        },

        author6Text: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        author6Image: {
          valid: false,
          error: null,
          constraints: [required],
        },
        author6Link: {
          valid: true,
          error: null,
          constraints: [],
        },
        author6Description: {
          valid: true,
          error: null,
          constraints: [],
        },
      },
      set,
      validate,
    };
  },
  created() {
    this.prepopulateForm();
  },
  props: {
    content: {
      type: Object,
      required: false,
    },
  },
  methods: {
    prepopulateForm() {
      if (this.content) {
        set("landingSection1Text", this.content.landingSection1Text, this.form);
        set("landingSection1Image", this.content.landingSection1Image, this.form);
        this.defaults.landingSection1Image = this.content.landingSection1Image;
        set("landingSection1Link", this.content.landingSection1Link, this.form);

        set("landingSection2Text", this.content.landingSection2Text, this.form);
        set("landingSection2Image", this.content.landingSection2Image, this.form);
        this.defaults.landingSection2Image = this.content.landingSection2Image;
        set("landingSection2Link", this.content.landingSection2Link, this.form);

        set("landingSection3Text", this.content.landingSection3Text, this.form);
        set("landingSection3Image", this.content.landingSection3Image, this.form);
        this.defaults.landingSection3Image = this.content.landingSection3Image;
        set("landingSection3Link", this.content.landingSection3Link, this.form);

        set("author1Text", this.content.author1Text, this.form);
        set("author1Image", this.content.author1Image, this.form);
        this.defaults.author1Image = this.content.author1Image;
        set("author1Link", this.content.author1Link, this.form);
        set("author1Description", this.content.author1Description, this.form);

        set("author2Text", this.content.author2Text, this.form);
        set("author2Image", this.content.author2Image, this.form);
        this.defaults.author2Image = this.content.author2Image;
        set("author2Link", this.content.author2Link, this.form);
        set("author2Description", this.content.author2Description, this.form);

        set("author3Text", this.content.author3Text, this.form);
        set("author3Image", this.content.author3Image, this.form);
        this.defaults.author3Image = this.content.author3Image;
        set("author3Link", this.content.author3Link, this.form);
        set("author3Description", this.content.author3Description, this.form);

        set("author4Text", this.content.author4Text, this.form);
        set("author4Image", this.content.author4Image, this.form);
        this.defaults.author4Image = this.content.author4Image;
        set("author4Link", this.content.author4Link, this.form);
        set("author4Description", this.content.author4Description, this.form);

        set("author5Text", this.content.author5Text, this.form);
        set("author5Image", this.content.author5Image, this.form);
        this.defaults.author5Image = this.content.author5Image;
        set("author5Link", this.content.author5Link, this.form);
        set("author5Description", this.content.author5Description, this.form);

        set("author6Text", this.content.author6Text, this.form);
        set("author6Image", this.content.author6Image, this.form);
        this.defaults.author6Image = this.content.author6Image;
        set("author6Link", this.content.author6Link, this.form);
        set("author6Description", this.content.author6Description, this.form);
      }
    },
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      if (this.content) resultObject.id = this.content.id || 1;// TODO: Retrieve id from API.

      this.$emit("submit", resultObject);
    },
  },
};
</script>