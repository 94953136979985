import BaseApiClient from "./BaseApiClient";

class NotificationClient extends BaseApiClient {
    getNotifications() {
        return this.get("/api/notifications");
    }

    markAllNotificationsForUserAsRead() {
        return this.put("/api/notifications/status", {
            read: true
        });
    }
}

export default new NotificationClient();
