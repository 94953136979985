import BaseApiClient from "./BaseApiClient";

class PaymentClient extends BaseApiClient {

    getCards() {
        return this.get(`/api/cards`);
    }

    create(token) {
        return this.post(`/api/cards`, {
            token
        });
    }

    remove(cardId) {
        return this.delete(`/api/cards/${cardId}`);
    }
}

export default new PaymentClient();
