<template>
  <div class="author-carousel mobile-content">
    <div v-if="landingSection && landingSection.author1Text" class="carousel-container">
      <VueSlickCarousel
        ref="carousel"
        :draggable="true"
        :slidesToShow="9"
        :touchMove="true"
        :swipe="true"
        :autoplay="true"
        :responsive="[
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 560,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 460,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 300,
            settings: {
              slidesToShow: 1,
            },
          },
        ]"
      >
        <AuthorBadgeMobile
          :name="landingSection.author1Text"
          :image="landingSection.author1Image"
          :link="landingSection.author1Link"
        />
        <AuthorBadgeMobile
          :name="landingSection.author2Text"
          :image="landingSection.author2Image"
          :link="landingSection.author2Link"
        />
        <AuthorBadgeMobile
          :name="landingSection.author3Text"
          :image="landingSection.author3Image"
          :link="landingSection.author3Link"
        />
        <AuthorBadgeMobile
          :name="landingSection.author4Text"
          :image="landingSection.author4Image"
          :link="landingSection.author4Link"
        />
        <AuthorBadgeMobile
          :name="landingSection.author5Text"
          :image="landingSection.author5Image"
          :link="landingSection.author5Link"
        />
        <AuthorBadgeMobile
          :name="landingSection.author6Text"
          :image="landingSection.author6Image"
          :link="landingSection.author6Link"
        />
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// Optional style for arrows & dots.
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import AuthorBadgeMobile from "@/components/AuthorBadgeMobile.vue";

export default {
  name: "AuthorCarouselMobile",
  components: {
    VueSlickCarousel,
    AuthorBadgeMobile,
  },
  computed: {
    landingSection() {
      return this.$store.getters['content/landingSection']();
    }
  }
};
</script>