<template>
  <div class="checkbox-container">
    <label class="checkbox">
      <input
        type="checkbox"
        class="input--hidden"
        v-model="checked"
        @change="(e) => $emit('change', checked)"
      />
      <span class="inner-label"></span>
      <span class="checkbox-text">{{ text }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  data() {
    return {
      checked: false,
    };
  },
  mounted() {
    this.checked = this.defaultValue;
  },
  props: {
    text: String,
    defaultValue: {
      type: Boolean,
      required: false,
      default: false
    }
  },
};
</script>