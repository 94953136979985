import NewsletterClient from "../clients/NewsletterClient";

class NewsletterService {
    async subscribe(email) {
        NewsletterClient.subscribeToNewsletter(email);
    }

    async unSubscribeFromNewsletter(email) {
        NewsletterClient.subscribeToNewsletter(email);
    }
}

export default new NewsletterService();