<template>
  <div
    class="image-picker"
    :style="imagePath ? `background-image: url(${imagePath});` : ''"
  >
    <label tabindex="0">
      <input type="file" @change="loadImage" />

      <img v-if="!image && !imagePath" class="plus" src="@/assets/svg/plus.svg" />
    </label>

    <img
      v-if="image || imagePath"
      class="close"
      src="@/assets/svg/close.svg"
      @click="clearImage"
    />
  </div>
</template>

<script>
import constants from "@/util/constants";

export default {
  name: "ImagePicker",
  data() {
    return {
      constants,
      imagePath: null,
      image: null,
    };
  },
  props: {
    defaultImagePath: {
      type: String,
      required: false,
    }
  },
  mounted() {
    this.imagePath = this.defaultImagePath ? `${constants.apiUrl}/static/${this.defaultImagePath}.jpg` : "";
  },
  methods: {
    loadImage(event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();

        this.image = input.files[0];

        reader.onload = (e) => {
          this.imagePath = e.target.result;
          
          this.$emit("load", this.imagePath);
        };
        reader.readAsDataURL(input.files[0]);


        input.value = "";
      }
    },
    clearImage() {
      this.image = null;
      this.imagePath = null;

      this.$emit("load", null);
      this.$emit("clear");
    },
  },
};
</script>