<template>
  <div
    :class="{
      'floating-menu': true,
      'mobile-content': true,
      'shown': isFloatingMenuVisible,
    }"
  >
    <router-link to="/">
      <img src="@/assets/svg/huffbooks-icon.svg" />
    </router-link>
    <router-link to="/watchlist">
      <img src="@/assets/svg/heart.svg" />
    </router-link>
    <router-link to="/create-ad">
      <img src="@/assets/svg/plus.svg" />
    </router-link>
    <router-link to="/notifications">
      <img src="@/assets/svg/bell.svg" />
    </router-link>
    <router-link to="/profile">
      <img src="@/assets/svg/user.svg" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FloatingMenu",
  data() {
    return {
      isFloatingMenuVisible: true,
    };
  },
  created() {
    let lastScrollTop = 0;

    document.addEventListener(
      "scroll",
      () => {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop) {
          this.isFloatingMenuVisible = false;
        } else {
          this.isFloatingMenuVisible = true;
        }

        lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
  },
};
</script>