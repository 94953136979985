import HeroSectionClient from "../clients/HeroSectionClient";

class ContentService {
    constructor() {
        this.setLandingSection();
    }

    async setLandingSection() {
        this.landingSection = await HeroSectionClient.getHeroSection();
    }

    async getLandingSection() {
        if (this.landingSection) return this.landingSection;

        const result = await HeroSectionClient.getHeroSection();
        
        return result;
    }

    async getHelpSection() {
        return [
            {
                id: 1,
                title: "FAQs",
                items: [
                    {
                        id: 2,
                        title: "How much is delivery",
                        content: "The best way to contact us is by filling in the online contact form. The form is designed to help you specify the problem or the query and then direct it to the member of staff best suited to deal with it. We are always keen to hear from you and help with any queries or problems. We endeavour to answer all queries within 24 hours. We are also very happy to receive your feedback and suggestions. All emails are personally read and replied to by our Customer Service staff. Please send your emails to <a href='mailto:help@support.huffbooks.com' >help@support.huffbooks.com</a>"
                    },
                    {
                        id: 3,
                        title: "When will my order arrive",
                        content: "The best way to contact us is by filling in the online contact form. The form is designed to help you specify the problem or the query and then direct it to the member of staff best suited to deal with it. We are always keen to hear from you and help with any queries or problems. We endeavour to answer all queries within 24 hours. We are also very happy to receive your feedback and suggestions. All emails are personally read and replied to by our Customer Service staff. Please send your emails to <a href='mailto:help@support.huffbooks.com' >help@support.huffbooks.com</a>"
                    }
                ]
            },
            {
                id: 4,
                title: "Other topics",
                items: [
                    {
                        id: 5,
                        title: "How much is delivery",
                        content: "The best way to contact us is by filling in the online contact form. The form is designed to help you specify the problem or the query and then direct it to the member of staff best suited to deal with it. We are always keen to hear from you and help with any queries or problems. We endeavour to answer all queries within 24 hours. We are also very happy to receive your feedback and suggestions. All emails are personally read and replied to by our Customer Service staff. Please send your emails to <a href='mailto:help@support.huffbooks.com' >help@support.huffbooks.com</a>"
                    },
                    {
                        id: 6,
                        title: "When will my order arrive",
                        content: "The best way to contact us is by filling in the online contact form. The form is designed to help you specify the problem or the query and then direct it to the member of staff best suited to deal with it. We are always keen to hear from you and help with any queries or problems. We endeavour to answer all queries within 24 hours. We are also very happy to receive your feedback and suggestions. All emails are personally read and replied to by our Customer Service staff. Please send your emails to <a href='mailto:help@support.huffbooks.com' >help@support.huffbooks.com</a>"
                    }
                ]
            }
        ];
    }
}

export default new ContentService();