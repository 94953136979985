import BookClient from "../clients/BookClient";

class BookService {
    async getBookData() {

    }

    async search(query) {
    }

    async filterByQuery(query, page = 1, pageSize = 50) {
        return (await BookClient.searchBooks(
            page,
            pageSize,
            query
        )).content;
    }

    async filterByQueries(queries, page = 1, pageSize = 50) {
        const promises = queries
            .filter(e => typeof e === 'string' && e.trim().length)
            .map(query => BookClient.searchBooks(
                page,
                pageSize,
                query
            ));
            
        const result = await Promise.all(promises);       

        return result.flatMap(item => item.content);
    }
}

export default new BookService();