<template>
  <ContentLimiter v-if="currentPost">
    <div class="breadcrumbs m-b-s p-l-xs">
      <strong>
        <router-link to="/forum">Forum home</router-link>
        <span class="primary-color-text"> / </span>
        <router-link :to="`/forum/${currentPost.forumTopicId}`">{{
          ForumTopicMapper[currentPost.forumTopicId]
        }}</router-link>
      </strong>
    </div>

    <div class="forum-item forum-post card m-b-s col-8 col-m-12 m-b-m">
      <div class="upvotes col-1 col-t-2 desktop-and-tablet-content">
        <span>{{ currentPost.userLikesCount }}</span>
        <img
          v-if="currentPost.likedByCurrentUser"
          src="@/assets/svg/heart-primary.svg"
          @click="dislikePost"
        />
        <img v-else src="@/assets/svg/heart.svg" @click="likePost" />
      </div>

      <div class="col-11 col-t-10 col-m-12">
        <div class="m-b-s">
          <span class="p-r-xs">
            <strong>{{ ForumTopicMapper[currentPost.forumTopicId] }}</strong> ·
            <router-link :to="`/forum?authorId=${currentPost.authorId}`"
              >Posted by {{ currentPost.authorFirstName }}
              {{ currentPost.authorLastName }}</router-link
            >
            · {{ dateFormatter.format(new Date(currentPost.createdDate)) }}
          </span>
          
          <span v-if="currentUser && currentPost.authorId == currentUser.id">
            <a href="javascript:;" @click="() => (editModeOn = true)">Edit</a>
            <span class="p-h-xxs">|</span>
            <a href="javascript:;" @click="removePost">Remove</a>
          </span>
        </div>

        <h3 class="m-b-s">{{ currentPost.title }}</h3>

        <div v-if="editModeOn" class="m-b-s">
          <div class="m-b-xs">
            <wysiwyg v-model="currentPost.content" />
          </div>
          <MainCTA @click="editPost">Save</MainCTA>
        </div>
        <p v-else class="m-b-s" v-html="currentPost.content"></p>

        <div class="item-statistics">
          <img class="m-r-xxs" src="@/assets/svg/comment-cloud.svg" />
          <span class="m-r-s"
            >{{ currentPost.comments ? currentPost.comments.length : 0 }}
            Comments
          </span>

          <span class="m-r-xxs">Share:</span>
          <SocialShare :shareUrl="currentUrl" />
        </div>

        <ForumCommentForm v-if="currentUser" @submit="createPostComment" />
      </div>

      <div v-if="!currentUser" class="login-block m-t-s">
        <strong>Sign in to leave a comment</strong>
        <div class="btn-wrapper">
          <MainCTA negative href="/account">Log in</MainCTA>
        </div>
      </div>

      <div v-else class="m-t-s" style="border-bottom: 1px solid #ddd"></div>

      <div class="dropdown-wrapper m-t-s">
        <DropdownField label="Sort items by">
          <option value="">Any</option>
          <option
            v-for="sortType in ForumCommentSortTypes"
            :key="sortType"
            :value="sortType"
          >
            {{ ForumCommentSortTypeMapper[sortType] }}
          </option>
        </DropdownField>
      </div>
      <div
        v-if="currentPost.comments && currentPost.comments.length"
        :class="`forum-comments ${commentsExpanded ? 'expanded' : ''} m-t-m`"
      >
        <ForumComment
          v-for="comment in currentPost.comments"
          :key="comment.id"
          :comment="comment"
          :post="currentPost"
        />

        <div
          class="expand-comments"
          v-if="!commentsExpanded && currentPost.comments.length > 2"
        >
          <MainCTA @click="commentsExpanded = true"
            >View Entire Discussion ({{
              currentPost.comments ? currentPost.comments.length : 0
            }}
            Comments)</MainCTA
          >
        </div>
      </div>
      <p v-else class="m-v-m">Be the first to comment on this post!</p>
    </div>

    <div class="col-4 col-m-12">
      <ForumTopics />

      <div v-if="currentUser">
        <NewForumPostCTA />

        <div class="m-b-s">
          <UserInfoCard :user="currentUser" />
        </div>
      </div>
      <div v-else class="register-form-container card">
        <RegisterForm />
      </div>
    </div>
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import SocialShare from "@/components/SocialShare.vue";
import RegisterForm from "@/views/forms/RegisterForm.vue";
import UserInfoCard from "@/components/UserInfoCard.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import DropdownField from "@/components/form/DropdownField.vue";
import NewForumPostCTA from "@/components/NewForumPostCTA.vue";
import ForumComment from "@/components/forum/ForumComment.vue";
import ForumTopics from "@/components/ForumTopics.vue";

import ForumCommentForm from "@/views/forms/ForumCommentForm.vue";

import {
  ForumTopicMapper,
  ForumCommentSortTypes,
  ForumCommentSortTypeMapper,
} from "@/util/constants/enums";

export default {
  name: "ForumPost",
  data() {
    return {
      ForumTopicMapper,
      ForumCommentSortTypes,
      ForumCommentSortTypeMapper,
      dateFormatter: window.dateFormatter,

      commentsExpanded: false,
      editModeOn: false,
    };
  },
  components: {
    ContentLimiter,
    SocialShare,
    RegisterForm,
    UserInfoCard,
    MainCTA,
    DropdownField,
    NewForumPostCTA,
    ForumComment,
    ForumTopics,
    ForumCommentForm,
  },
  created() {
    this.setCurrentPost();
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    currentPost() {
      return this.$store.getters["forum/currentPost"]();
    },
    currentUrl() {
      return encodeURIComponent(window.location.href);
    },
  },
  methods: {
    setCurrentPost() {
      this.$store.dispatch("forum/setCurrentPost", this.$route.params.id);
    },
    async createPostComment(data) {
      await this.$store.dispatch("forum/createPostComment", {
        postId: this.currentPost.id,
        content: data.content,
      });

      this.setCurrentPost();
    },
    async likePost() {
      await this.$store.dispatch("forum/likePost", this.currentPost.id);

      this.setCurrentPost();
    },
    async dislikePost() {
      await this.$store.dispatch("forum/dislikePost", this.currentPost.id);

      this.setCurrentPost();
    },

    async editPost() {
      this.editModeOn = false;

      await this.$store.dispatch("forum/editPost", {
        postId: this.currentPost.id,
        content: this.currentPost.content,
      });

      Vue.$toast.open({
        message: `Post saved!`,
        type: "success",
        position: "bottom",
      });
    },
    async removePost() {
      window.$prompt(
        "Remove post",
        `Are you sure that you want to remove this post?`,
        async () => {
          await this.$store.dispatch("forum/removePost", this.currentPost.id);

          this.$router.push("/forum");

          Vue.$toast.open({
            message: `Post deleted!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
  },
  watch: {
    $route() {
      this.setCurrentPost();
    },
  },
};
</script>