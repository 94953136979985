import BaseApiClient from "./BaseApiClient";


class AdClient extends BaseApiClient {

    filterAds(
        sortBy,
        sortOrder,
        page,
        pageSize,
        keywords,
        priceFrom,
        priceTo,
        format,
        bookState,
        condition,
        sellerState,
        sellerCity,
        language,
        bookTitle,
        authorName,
        active,
        subject,
        subSubject,
        subSubSubject,
        bookIds
    ) {
        return this.get("/api/ads", {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
            keywords,
            priceFrom,
            priceTo,
            format,
            bookState,
            condition,
            sellerState,
            sellerCity,
            language,
            bookTitle,
            authorName,
            active,
            subject,
            subSubject,
            subSubSubject,
            bookIds
        })
    }

    getAd(adId) {
        return this.get(`/api/ads/${adId}`);
    }

    deleteAd(adId) {
        return this.delete(`/api/ads/${adId}`)
    }

    createAd(
        price,
        language,
        bookState,
        coverState,
        edgesState,
        spineState,
        pageStructureState,
        bookId,
        images, // List of base64 images
        keywords, // List of strings
        sellerState,
        sellerCity,
        authorName,
        bookTitle,
        description,
        edition,
        format,
        isbn10,
        isbn13,
        publicationDate,
        publisher,
    ) {
        return this.post("/api/ads", {
            price,
            language,
            bookState,
            coverState,
            edgesState,
            spineState,
            pageStructureState,
            bookId,
            images,
            keywords,
            sellerState,
            sellerCity,
            authorName,
            bookTitle,
            description,
            edition,
            format,
            isbn10,
            isbn13,
            publicationDate,
            publisher,
        });
    }

    editAd(
        id,
        price,
        language,
        bookState,
        coverState,
        edgesState,
        spineState,
        pageStructureState,
        bookId,
        images, // List of base64 images
        keywords, // List of strings
        sellerState,
        sellerCity,
        authorName,
        bookTitle,
        description,
        edition,
        format,
        isbn10,
        isbn13,
        publicationDate,
        publisher,
    ) {
        return this.put(`/api/ads/${id}`, {
            price,
            language,
            bookState,
            coverState,
            edgesState,
            spineState,
            pageStructureState,
            bookId,
            images,
            keywords,
            sellerState,
            sellerCity,
            authorName,
            bookTitle,
            description,
            edition,
            format,
            isbn10,
            isbn13,
            publicationDate,
            publisher,
        });
    }

    postAdComment(adId, comment) {
        return this.post(`/api/ads/${adId}/comments`, {
            comment
        });
    }

    removeAdComment(adId, commentId) {
        return this.delete(`/api/ads/${adId}/comments/${commentId}`);
    }

    postAdReply(adId, commentId, comment) {
        return this.post(`/api/ads/${adId}/comments/${commentId}/replies`, { comment });
    }

    getRelated() {
        return this.get("/api/ads/related");
    }
}

export default new AdClient();
