<template>
  <div v-if="ad" class="order-item">
    <div class="book-information-container col-7 col-t-12 col-m-12">
      <div
        class="image"
        :style="`
          background-image: url(${constants.apiUrl}/static/${ad.images[0]}.jpg);
        `"
      ></div>

      <div class="book-information">
        <h3><router-link :to="`/ad/${ad.id}`" >{{ ad.bookTitle }}</router-link></h3>

        <span class="m-t-a">{{ FormatMapper[ad.format] }} | {{ ad.language.toUpperCase() }} | {{ ad.authorName }}</span>
        <span>Book state: <strong>{{ BookStateMapper[ad.bookState] }}</strong></span>
        <span>{{ ad.sellerCity }}, {{ ad.sellerState.toUpperCase() }}</span>

        <span class="price m-t-a">A$ {{ ad.price.toFixed(2) }}</span>

        <router-link :to="`/profile/${ad.user.id}`" class="m-t-a m-b-xxs">{{
          `${ad.user.firstName} ${ad.user.lastName}`
        }}</router-link>

        <VueStarRating :showRating="false" :starSize="16" :rating="(ad.user.itemAsDescribed + ad.user.communication + ad.user.responseTime) / 3" />
      </div>
    </div>

    <div class="order-information-container col-5 col-t-12 col-m-12">
      <p>
        Date posted:
        <strong>{{
          new Date(ad.createdDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        }}</strong>
      </p>
    </div>
  </div>
</template>

<script>
import VueStarRating from "vue-star-rating";

import constants from "@/util/constants";
import { FormatMapper, BookStateMapper } from "@/util/constants/enums";

export default {
  name: "PlacedOrderItem",
  components: {
    VueStarRating,
  },
  data() {
    return {
      constants,
      FormatMapper,
      BookStateMapper
    };
  },
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
};
</script>