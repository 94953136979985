<template>
  <div class="desktop-search-bar">
    <button class="magnifier" @click="searchBooks">
      <img src="@/assets/svg/magnifier.svg" />
    </button>

    <input
      v-model="searchQuery"
      type="text"
      autocomplete="off"
      placeholder="Search by keyword / title / author / ISBN"
      v-on:keyup.enter="searchBooks"
    />

    <MainCTA @click="searchBooks">Search</MainCTA>
  </div>
</template>

<script>
import MainCTA from "@/components/form/MainCTA.vue";

import urlHelper from "@/util/helpers/urlHelper";

export default {
  name: "DesktopSearchBar",
  components: {
    MainCTA,
  },
  computed: {
    searchQuery: {
      get() {
        return this.$store.getters["search/searchQuery"]();
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value);
      },
    },
  },
  methods: {
    searchBooks() {
      if (!this.searchQuery.length) return;

      this.$store.dispatch("search/setBookPickerBooks", this.searchQuery);      
    },
  },
};
</script>