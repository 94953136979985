<template>
  <div class="orange-body">
    <ContentLimiter>
      <div class="checkout-order-container">
        <div
          v-if="cartAds && cartAds.length"
          class="checkout-container card col-8 col-t-7 col-m-12 p-s"
        >
          <CheckoutForm @submit="submit" />
        </div>
        <p class="card p-v-l" v-else>
          Your basket is empty. You can go back to the
          <router-link to="/">Home</router-link> page to add items to your
          basket and then proceed to checkout.
        </p>

        <div class="col-4 col-t-5 col-m-12">
          <div class="m-b-s">
            <OrderStatusCard />
          </div>
          <div v-if="!currentUser" class="register-form-container card">
            <RegisterForm />
          </div>
        </div>
      </div>
    </ContentLimiter>
  </div>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import OrderStatusCard from "@/components/checkout/OrderStatusCard.vue";

import CheckoutForm from "@/views/forms/CheckoutForm.vue";
import RegisterForm from "@/views/forms/RegisterForm.vue";

export default {
  name: "Checkout",
  components: {
    ContentLimiter,
    OrderStatusCard,
    CheckoutForm,
    RegisterForm,
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    cartAds() {
      return this.$store.getters["cart/cartAds"]();
    },
  },
  created() {
    if (!this.currentUser) this.$router.push("/account");
  },
  methods: {
    async submit(data) {
      const result = await this.$store.dispatch("payment/create", data.card.id);
      
      const newOrderRequest = {
        shippingType: data.shippingType,
        email: data.email,
        cardId: result.cardId,
        card: data.card.card,
        fullName: data.deliveryAddressFullName,
        shippingAddressLine1: data.deliveryAddressLine1,
        shippingAddressLine2: data.deliveryAddressLine2,
        shippingCity: data.deliveryAddressCity,
        shippingState: data.deliveryAddressState,
        shippingPostcode: data.deliveryAddressPostCode,
        billingAddressLine1: data.billingAddressLine1,
        billingAddressLine2: data.billingAddressLine2,
        billingCity: data.billingAddressCity,
        billingState: data.billingAddressState,
        billingPostcode: data.billingAddressPostCode,
        ads: this.cartAds.map((e) => e.id),
      };

      await this.$store.dispatch("checkout/createOrder", newOrderRequest);

      Vue.$toast.open({
        message: `Order successfully created!`,
        type: "success",
        position: "bottom",
      });

      await this.$store.dispatch("payment/remove", result.cardId);

      this.$router.push("/order-placed");
    },
  },
};
</script>