import BaseApiClient from "./BaseApiClient";


class BookClient extends BaseApiClient {

    searchBooks(
        page,
        pageSize,
        query
    ) {
        return this.get("/api/books", {}, {
            page,
            pageSize,
            query
        })
    }

    filterBooks(
        sortBy,
        sortOrder,
        page,
        pageSize,
        title,
        author,
        publisher,
        isbn10,
        isbn13,
        subject, // integer id
        subSubject, // integer id
        subSubSubject //integer id
    ) {
        return this.get("/api/books/filter", {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
            title,
            author,
            publisher,
            isbn10,
            isbn13,
            subject,
            subSubject,
            subSubSubject
        })
    }
}

export default new BookClient();
