<template>
  <router-link to="/basket" class="cart-indicator">
    <span class="price">{{ totalPrice.toFixed(2) }} $</span>
    <span class="count">{{ numberOfItems }}</span>
    <img src="@/assets/svg/basket.svg" />
  </router-link>
</template>

<script>
export default {
  name: "CartIndicator",
  computed: {
    numberOfItems() {
      return this.$store.getters["cart/cartAds"]().length;
    },
    totalPrice() {
      if (this.$store.getters["cart/cartAds"]().length)
        return this.$store.getters["cart/cartAds"]()
          .map(e => e.price)
          .reduce((a, b) => a + b);
      else
        return 0;
    }
  },
};
</script>