import router from "@/router/index";

import bookService from "@/services/bookService";
import adService from "@/services/adService";

import taskHelper from "@/util/helpers/taskHelper";
import urlHelper from "@/util/helpers/urlHelper";

export default {
    namespaced: true,
    state: {
        searchQuery: "",

        filters: {
            books: [],
            keywords: "",
            format: null,
            bookState: null,
            language: null,
            priceFrom: 0,
            priceTo: 0,
            state: null,
            city: "",
            authorName: "",
            subject: null,
            subSubject: null,
            subSubSubject: null
        },

        isBookPickerVisible: false,
        bookPickerBooks: [],
        searchResults: [],

        booksChosenCallback: () => null,
    },
    mutations: {
        setSearchQuery: (state, payload) => state.searchQuery = payload,

        setFilters: (state, payload) => state.filters = payload,
        setFilterBooks: (state, payload) => state.filters.books = payload,

        setFilterSubject: (state, payload) => {
            state.filters = {
                ...state.filters,
                subject: payload
            };
        },
        setFilterSubSubject: (state, payload) => {
            state.filters = {
                ...state.filters,
                subSubject: payload
            };
        },
        setFilterSubSubSubject: (state, payload) => {
            state.filters = {
                ...state.filters,
                subSubSubject: payload
            };
        },

        showBookPicker: (state) => state.isBookPickerVisible = true,
        setBookPickerBooks: (state, payload) => state.bookPickerBooks = payload,
        hideBookPicker: (state) => state.isBookPickerVisible = false,

        setSearchResults: (state, payload) => state.searchResults = payload,

        setBooksChosenCallback: (state, payload) => state.booksChosenCallback = payload,
    },
    actions: {
        async setBookPickerBooks(context, searchQuery) {
            context.commit('setBookPickerBooks', []);
            context.commit('showBookPicker');

            context.commit('setBooksChosenCallback', (books) => {
                context.commit("setFilterBooks", books);
                
                if (context.state.filters.books) {
                    context.state.filters.bookIds = context.state.filters.books.map((book) => book.externalId);
            
                    delete context.state.filters.books;
                  }
            
                  router.push(`/results?${urlHelper.toQueryString(context.state.filters)}`);
            });

            const books = await taskHelper.executeSafely(() => bookService.filterByQuery(searchQuery));

            context.commit('setBookPickerBooks', books);
        },
        
        async setBookPickerBooksForMultipleQueries(context, searchQueries) {
            context.commit('setBookPickerBooks', []);
            context.commit('showBookPicker');

            context.commit('setBooksChosenCallback', (books) => {
                context.commit("setFilterBooks", books);

                context.dispatch("ad/setCreateAdsFromBooks", books, {root:true});
            });

            const books = await taskHelper.executeSafely(() => bookService.filterByQueries(searchQueries));

            context.commit('setBookPickerBooks', books);
        },
        
        onBookPickerBooksChosen(context, books) {
            context.state.booksChosenCallback(books);
        },

        chooseBooks(context, books) {
            context.commit('setFilterBooks', books);
            context.commit('hideBookPicker');
        },

        hideBookPicker(context) {
            context.commit('hideBookPicker');
        },

        async search(context, filters) {
            const ads = await taskHelper.executeSafely(() => adService.filter(filters))

            context.commit("setSearchResults", ads);
        },

        setFilterSubject: (context, subjectId) => context.commit("setFilterSubject", subjectId),
        setFilterSubSubject: (context, subSubjectId) => context.commit("setFilterSubSubject", subSubjectId),
        setFilterSubSubSubject: (context, subSubSubjectId) => context.commit("setFilterSubSubSubject", subSubSubjectId),
    },
    getters: {
        searchQuery: state => () => state.searchQuery,
        filters: state => () => state.filters,
        isBookPickerVisible: state => () => state.isBookPickerVisible,
        bookPickerBooks: state => () => state.bookPickerBooks,
        searchResults: state => () => state.searchResults,
    }
};