import NotificationClient from "../clients/NotificationClient";

class NotificationService {
    async getNotifications() {
        return await NotificationClient.getNotifications();
    }

    async markAllNotificationsForUserAsRead() {
        return await NotificationClient.markAllNotificationsForUserAsRead();
    }
}

export default new NotificationService();