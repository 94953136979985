import AdminClient from "../clients/AdminClient";

class AdminService {
    async getContent() {
        const result = await AdminClient.getContent();
        
        // TODO: This isn't really clean.
        return result._embedded["hero-section"][0];
    }

    async updateContent({
        id,
        landingSection1Text,
        landingSection1Image,
        landingSection1Link,
        landingSection2Text,
        landingSection2Image,
        landingSection2Link,
        landingSection3Text,
        landingSection3Image,
        landingSection3Link,
        author1Text,
        author1Image,
        author1Link,
        author1Description,
        author2Text,
        author2Image,
        author2Link,
        author2Description,
        author3Text,
        author3Image,
        author3Link,
        author3Description,
        author4Text,
        author4Image,
        author4Link,
        author4Description,
        author5Text,
        author5Image,
        author5Link,
        author5Description,
        author6Text,
        author6Image,
        author6Link,
        author6Description
    }) {
        return await AdminClient.updateContent(
            id,
            landingSection1Text,
            landingSection1Image,
            landingSection1Link,
            landingSection2Text,
            landingSection2Image,
            landingSection2Link,
            landingSection3Text,
            landingSection3Image,
            landingSection3Link,
            author1Text,
            author1Image,
            author1Link,
            author1Description,
            author2Text,
            author2Image,
            author2Link,
            author2Description,
            author3Text,
            author3Image,
            author3Link,
            author3Description,
            author4Text,
            author4Image,
            author4Link,
            author4Description,
            author5Text,
            author5Image,
            author5Link,
            author5Description,
            author6Text,
            author6Image,
            author6Link,
            author6Description
        );
    }

    async filterUsers({ query }) {
        return await AdminClient.filterUsers(query);
    }

    async filterOrders({ query }) {
        return await AdminClient.filterOrders(query);
    }

    async banUser(userId) {
        return await AdminClient.banUser(userId);
    }

    async unbanUser(userId) {
        return await AdminClient.unbanUser(userId);
    }

    async sendNewsletter({ content }) {
        return await AdminClient.sendNewsletter(content);
    }
}

export default new AdminService();