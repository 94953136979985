<template>
  <ContentLimiter>
    <div class="account-container content-limiter small">
      <div class="desktop-and-tablet-content desktop-account-wrapper" >
        <div class="col-6 card">
          <LoginForm />
        </div>

        <div class="col-6 card">
          <RegisterForm />
        </div>
      </div>

      <div class="mobile-content" >
        <div v-if="activeForm == 'login'" class="col-m-12 card">
          <LoginForm />
        </div>

        <div v-else class="col-m-12 card">
          <RegisterForm />
        </div>
      </div>
    </div>

    <div class="flex-center mobile-content p-v-s" >
      <p v-if="activeForm == 'login'" >Don't have an account? <u @click="() => switchForm('register')" >Sign up</u>.</p>
      <p v-else >Already have an account? <u @click="() => switchForm('login')" >Sign in</u>.</p>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";

import RegisterForm from "@/views/forms/RegisterForm.vue";
import LoginForm from "@/views/forms/LoginForm.vue";

export default {
  name: "Account",
  data() {
    return {
      activeForm: "login"
    };
  },
  components: {
    ContentLimiter,
    NewsletterBlock,
    RegisterForm,
    LoginForm,
  },
  methods: {
    switchForm(type) {
      this.activeForm = type;
    }
  }
};
</script>