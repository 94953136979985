 <template>
  <div>
    <h1 class="m-b-s">Sign in</h1>

    <FormRow>
      <TextField
        label="Email"
        :defaultValue="form.email.value"
        placeholder="e.g. name@example.com"
        :errorMessage="form.email.error"
        @change="(value) => set('email', value, form)"
      />
    </FormRow>

    <FormRow v-if="passwordShown">
      <TextField
        label="Password"
        :defaultValue="form.password.value"
        placeholder="Must contain letters, numbers and symbols"
        :errorMessage="form.password.error"
        @change="(value) => set('password', value, form)"
      />
    </FormRow>

    <FormRow v-else>
      <PasswordField
        label="Password"
        :defaultValue="form.password.value"
        placeholder="Must contain letters, numbers and symbols"
        :errorMessage="form.password.error"
        @change="(value) => set('password', value, form)"
      />
    </FormRow>

    <FormRow>
      <CheckboxField text="Show password" @change="togglePasswordVisibility" />
    </FormRow>

    <div class="form-row flex-distributed-row full-width-button">
      <MainCTA @click="logIn" :disabled="!validate(form)">Sign in</MainCTA>
    </div>

    <div class="form-row flex-distributed-row">
      <div class="google-login-btn social-login-btn col-6 full-width-button">
        <GoogleLogin
          :onSuccess="googleSignIn"
          :params="{ client_id: constants.googleAppId }"
        >
          <span>Google</span>
        </GoogleLogin>
      </div>

      <div class="facebook-login-btn social-login-btn col-6 full-width-button">
        <FacebookLogin
          class="btn"
          loginLabel="Facebook"
          :appId="constants.facebookAppId"
          @login="facebookSignIn"
        >
        </FacebookLogin>
      </div>
    </div>

    <div class="flex-center p-v-xs">
      <p>
        Forgot your password? Click
        <router-link to="/forgot-password">here</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
import GoogleLogin from "vue-google-login";
import FacebookLogin from "facebook-login-vuejs";
import {
  set,
  validate,
  required,
  isEmail,
  minLength,
  maxLength,
} from "vue-val";

import FormRow from "@/components/form/FormRow.vue";
import PasswordField from "@/components/form/PasswordField.vue";
import TextField from "@/components/form/TextField.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";

import constants from "@/util/constants";

export default {
  name: "LoginForm",
  data() {
    return {
      passwordShown: false,
      constants,
      form: {
        email: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320), isEmail],
        },
        password: {
          valid: true,
          error: null,
          constraints: [required],
        },
      },
      set,
      validate,
    };
  },
  components: {
    GoogleLogin,
    FacebookLogin,
    FormRow,
    PasswordField,
    TextField,
    MainCTA,
    CheckboxField,
  },
  methods: {
    togglePasswordVisibility(checked) {
      this.passwordShown = checked;
    },
    async logIn() {
      await this.$store.dispatch("authentication/logIn", {
        email: this.form.email.value,
        password: this.form.password.value,
      });

      this.removeCurrentUserAdsFromBasket();
      this.$store.dispatch("watchlist/setWatchlist");

      this.$router.push("/");
    },
    removeCurrentUserAdsFromBasket() {
      const currentUser = this.$store.getters["authentication/currentUser"]();
      const cartAds = this.$store.getters["cart/cartAds"]();

      cartAds.forEach((ad) => {
        if (ad.user.id == currentUser.id)
          this.$store.dispatch("cart/removeFromCart", ad.id);
      });
    },
    async googleSignIn(googleUser) {
      await this.$store.dispatch("authentication/googleSignIn", googleUser);

      this.removeCurrentUserAdsFromBasket();
      this.$store.dispatch("watchlist/setWatchlist");

      this.$router.push("/");
    },
    async facebookSignIn(facebookUser) {
      await this.$store.dispatch("authentication/facebookSignIn", facebookUser);

      this.removeCurrentUserAdsFromBasket();
      this.$store.dispatch("watchlist/setWatchlist");

      this.$router.push("/");
    },
  },
};
</script>