<template>
    <DropdownField v-if="currentUserAddresses && currentUserAddresses.length" label="Choose from address book" @change="chooseAddress">
        <option v-for="address in currentUserAddresses" :key="address.id" :value="address.id">{{ address.fullName }} ( {{address.addressLine1}} )</option>
    </DropdownField>
</template>

<script>
import DropdownField from '@/components/form/DropdownField.vue'
export default {
    name: "AddressPicker",
    components: {
        DropdownField
    },
    created() {
        this.$store.dispatch("address/setCurrentUserAddresses");
    },
    computed: {
        currentUserAddresses() {
            return this.$store.getters["address/currentUserAddresses"]();
        }
    },
    methods: {
        chooseAddress(addressId) {
            const address = this.currentUserAddresses.find(e => e.id = addressId);

            this.$emit("select", address);
        }
    }
}
</script>