<template>
  <div v-if="comment" class="forum-comment m-b-m">
    <div v-if="comment.authorFirstName" class="name-content">
      <div class="avatar-image">
        {{ comment.authorFirstName[0] }}{{ comment.authorLastName[0] }}
      </div>
      <router-link :to="`/profile/${comment.authorId}`" class="m-r-s"
        >{{ comment.authorFirstName }}
        {{ comment.authorLastName }}</router-link
      >
      <span v-if="comment.createdDate" >{{ dateFormatter.format(new Date(comment.createdDate)) }}</span>

      <span v-if="currentUser && comment.authorId == currentUser.id" class="p-l-xs">
        <a href="javascript:;" @click="() => (editModeOn = true)">Edit</a>
        <span class="p-h-xxs">|</span>
        <a href="javascript:;" @click="removeComment">Remove</a>
      </span>
    </div>
    <div class="comment-content">
      <div v-if="editModeOn" class="m-b-s">
        <div class="m-b-xs">
          <wysiwyg v-model="comment.content" />
        </div>
        <MainCTA @click="editComment">Save</MainCTA>
      </div>
      <p v-else v-html="comment.content"></p>

      <div class="upvotes m-t-s">
        <img
          v-if="comment.isLikedByCurrentUser"
          src="@/assets/svg/heart-primary.svg"
          @click="dislikeComment"
        />
        <img v-else src="@/assets/svg/heart.svg" @click="likeComment" />

        <span>{{ comment.likes || 0 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import MainCTA from "../form/MainCTA.vue";

export default {
  components: { MainCTA },
  name: "ForumComment",
  data() {
    return {
      dateFormatter: window.dateFormatter,
      editModeOn: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async editComment() {
      this.editModeOn = false;

      await this.$store.dispatch("forum/editComment", {
        commentId: this.comment.id,
        content: this.comment.content,
      });

      Vue.$toast.open({
        message: `Comment saved!`,
        type: "success",
        position: "bottom",
      });
    },
    async removeComment() {
      window.$prompt(
        "Remove comment",
        `Are you sure that you want to remove this comment?`,
        async () => {
          await this.$store.dispatch("forum/removeComment", this.comment.id);
          await this.$store.dispatch("forum/setCurrentPost", this.post.id);

          Vue.$toast.open({
            message: `Comment deleted!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
    async likeComment() {
      await this.$store.dispatch("forum/likeComment", {
        postId: this.post.id,
        commentId: this.comment.id
      });

      await this.$store.dispatch("forum/setCurrentPost", this.post.id);
    },
    async dislikeComment() {
      await this.$store.dispatch("forum/dislikeComment", {
        postId: this.post.id,
        commentId: this.comment.id
      });

      await this.$store.dispatch("forum/setCurrentPost", this.post.id);
    },
  },
};
</script>