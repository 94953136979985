// Book format
export const Formats = Object.freeze({
    Hardback: 0,
    Paperback: 1,
    EBook: 2
});

export const FormatMapper = {
    [Formats.Hardback]: "Hardback",
    [Formats.Paperback]: "Paperback",
    [Formats.EBook]: "EBook"
};

// Book state
export const BookStates = Object.freeze({
    LikeNew: 0,
    NearFine: 1,
    Good: 2,
    Fair: 3,
    Poor: 4
});

export const BookStateMapper = {
    [BookStates.LikeNew]: "Like new",
    [BookStates.NearFine]: "Near fine",
    [BookStates.Good]: "Good",
    [BookStates.Fair]: "Fair",
    [BookStates.Poor]: "Poor"
};

// Sorting types
export const SortTypes = Object.freeze({
    OldestFirst: 0,
    NewestFirst: 1,
    PriceAscending: 2,
    PriceDescending: 3
});

export const SortTypeMapper = {
    [SortTypes.LikeNew]: "Oldest",
    [SortTypes.NearFine]: "Newest",
    [SortTypes.Good]: "Price ascending",
    [SortTypes.Fair]: "Price descending"
};


// Shipping types
export const ShippingTypes = Object.freeze({
    Shipping: "STANDARD",
    InPerson: "IN_PERSON"
});

export const ShippingTypeMapper = {
    [ShippingTypes.Shipping]: "Standard shipping",
    [ShippingTypes.InPerson]: "In Person"
};

// Notifications
export const NotificationTypes = Object.freeze({
    Sale: 0,
    Comment: 1,
    Review: 2,
    NoLongerAvailable: 3,
    NoLongerInWatchlist: 4,
    HuffBooks: 5
});

export const NotificationTypeMapper = {
    [NotificationTypes.Sale]: "Sale",
    [NotificationTypes.Comment]: "Comment",
    [NotificationTypes.Review]: "Review",
    [NotificationTypes.NoLongerAvailable]: "No longer available",
    [NotificationTypes.NoLongerInWatchlist]: "No longer in watchlist",
    [NotificationTypes.HuffBooks]: "HuffBooks"
};

// Forum topics
export const ForumTopics = Object.freeze([
    { name: 'Shipping', slug: 1 },
    { name: 'Book reviews', slug: 2 },
    { name: 'Book condition', slug: 3 },
    { name: 'Discussions', slug: 4 },
    { name: 'Fiction', slug: 5 },
    { name: 'Meetings', slug: 6 },
    { name: 'Other', slug: 7 },
]);

export const ForumTopicMapper = {
    1: "Shipping",
    2: "Book reviews",
    3: "Book condition",
    4: "Discussions",
    5: "Fiction",
    6: "Meetings",
    7: "Other",
};

// Reviews
export const ReviewSortTypes = Object.freeze({
    Positive: "positive",
    Negative: "negative",
});

export const ReviewSortTypeMapper = {
    [ReviewSortTypes.Positive]: "Positive",
    [ReviewSortTypes.Negative]: "Negative",
};

// Forum comments
export const ForumCommentSortTypes = Object.freeze({
    Good: "good",
    Ok: "ok",
    Bad: "bad",
});

export const ForumCommentSortTypeMapper = {
    [ForumCommentSortTypes.Good]: "Good",
    [ForumCommentSortTypes.Ok]: "Ok",
    [ForumCommentSortTypes.Bad]: "Bad",
};

// Order history
export const OrderHistorySortTypes = Object.freeze({
    Good: "good",
    Ok: "ok",
    Bad: "bad",
});

export const OrderHistorySortTypeMapper = {
    [OrderHistorySortTypes.Good]: "Good",
    [OrderHistorySortTypes.Ok]: "Ok",
    [OrderHistorySortTypes.Bad]: "Bad",
};

// Search results
export const SearchResultsSortTypes = Object.freeze({
    Latest: "newest",
    Oldest: "oldest",
    PriceAscending: "price-ascending",
    PriceDescending: "price-descending",
});

export const SearchResultsSortTypeMapper = {
    [SearchResultsSortTypes.Latest]: "Newest first",
    [SearchResultsSortTypes.Oldest]: "Oldest first",
    [SearchResultsSortTypes.PriceAscending]: "Price, low to high",
    [SearchResultsSortTypes.PriceDescending]: "Price, high to low",
};

// Watchlist
export const WatchlistSortTypes = Object.freeze({
    Good: "good",
    Ok: "ok",
    Bad: "bad",
});

export const WatchlistSortTypeMapper = {
    [WatchlistSortTypes.Good]: "Good",
    [WatchlistSortTypes.Ok]: "Ok",
    [WatchlistSortTypes.Bad]: "Bad",
};

// Login types
export const LoginTypes = Object.freeze({
    Internal: "INTERNAL",
    Facebook: "FACEBOOK",
    Google: "GOOGLE",
});

// Review types
export const ReviewTypes = Object.freeze({
    Positive: "POSITIVE",
    Negative: "NEGATIVE",
});