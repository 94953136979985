<template>
  <div class="aside-block card basket-aside basket-card-block">
    <h2 class="m-b-s">Your basket</h2>

    <p>
      You have {{ numberOfItems }} item{{ numberOfItems > 1 ? "s" : ""}} for a total of <strong>A$ {{ totalPrice.toFixed(2) }}</strong> in your basket.
    </p>
    <br />
    <br />
    <p class="italic">
      In placing your order, you are confirming that you have read and agree to
      our <router-link :to="`/help`">Terms and Conditions</router-link>. Please
      also see our <router-link :to="`/help`">Privacy Policy</router-link> and
      our <router-link :to="`/help`">Cookies Policy</router-link>.
    </p>

    <div class="icon-wrapper">
      <img src="@/assets/svg/payment-visa.svg" />
      <img src="@/assets/svg/payment-mastercard.svg" />
      <img src="@/assets/svg/payment-americanexpress.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "UserBasketCard",
  computed: {
    numberOfItems() {
      return this.$store.getters["cart/cartAds"]().length;
    },
    totalPrice() {
      if (this.$store.getters["cart/cartAds"]().length)
        return this.$store.getters["cart/cartAds"]()
          .map((e) => e.price)
          .reduce((a, b) => a + b);
      else return 0;
    },
  },
};
</script>