<template>
  <ContentLimiter>
    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserLinksCard v-if="currentUser" />
      <div v-else class="register-form-container card">
        <RegisterForm />
      </div>
    </div>
    <div class="card col-9 col-t-8 col-m-12">
      <ContactForm @submit="submitContactForm" />
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";

import ContactForm from "@/views/forms/ContactForm.vue";
import RegisterForm from "@/views/forms/RegisterForm.vue";

export default {
  name: "Contact",
  components: {
    ContentLimiter,
    NewsletterBlock,
    UserLinksCard,
    ContactForm,
    RegisterForm,
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
  },
  methods: {
    async submitContactForm(data) {
      await this.$store.dispatch("contact/contactUs", data);

      Vue.$toast.open({
        message: `Message sent! We will try to reply as soon as possible!`,
        type: "success",
        position: "bottom",
      });

      this.$router.push("/");
    }
  }
};
</script>
