import OrderClient from "../clients/OrderClient";

class OrderService {
    async createOrder({
        shippingType,
        email,
        fullName,
        shippingAddressLine1,
        shippingAddressLine2,
        shippingCity,
        shippingState,
        shippingPostcode,
        billingAddressLine1,
        billingAddressLine2,
        billingCity,
        billingState,
        billingPostcode,
        ads,
        cardId
    }) {
        return await OrderClient.createOrder(
            shippingType,
            email,
            fullName,
            shippingAddressLine1,
            shippingAddressLine2,
            shippingCity,
            shippingState,
            shippingPostcode,
            billingAddressLine1,
            billingAddressLine2,
            billingCity,
            billingState,
            billingPostcode,
            ads,
            cardId
        );
    }

    async reviewOrder({
        orderId,
        itemAsDescribed,
        communication,
        responseTime,
        reviewType,
        comment
    }) {
        return await OrderClient.reviewOrder(
            orderId,
            itemAsDescribed,
            communication,
            responseTime,
            reviewType,
            comment
        );
    }

    async getMyOrders() {
        return await OrderClient.getMyOrders("/api/orders");
    }
}

export default new OrderService();