import BaseApiClient from "./BaseApiClient";

class AuthClient extends BaseApiClient {

    register(firstName, lastName, password, email) {
        return this.post(`/api/auth/register`, { firstName, lastName, password, email });
    }

    async authenticate(email, password) {
        const response = await this.post("/api/auth/authenticate", { email, password });

        this.setAuthenticationToken(response.idToken);

        return response;
    }

    async facebookSignIn(firstName, lastName, email, token) {
        console.log(firstName, lastName, email, token);
        const response = await this.post(`/api/auth/social/facebook`, { firstName, lastName, email, token });

        this.setAuthenticationToken(response.idToken);

        return response;
    }

    async googleSignIn(idToken) {
        const response = await this.post(`/api/auth/social/google`, { idToken });

        this.setAuthenticationToken(response.idToken);

        return response;
    }

    sendResetEmail(email) {
        return this.post("/api/auth/send-reset-email", { email });
    }

    resetPassword(key, newPassword) {
        return this.post("/api/auth/reset-password", { key, newPassword })
    }

    changePassword(oldPassword, newPassword) {
        return this.post("/api/auth/change-password", { oldPassword, newPassword })
    }


    deactivateAccount() {
        return this.post("/api/auth/deactivate-account");
    }

    deleteAccount() {
        return this.post("/api/auth/delete-account");
    }
}

export default new AuthClient();
