<template>
  <ContentLimiter>
    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserLinksCard />
    </div>

    <div class="card col-9 col-t-8 col-m-12">
      <div class="watchlist-heading">
        <h2>Watchlist</h2>
        <div class="dropdown-wrapper">
          <DropdownField label="Sort items by">
            <option value="">Any</option>
            <option
              v-for="sortType in WatchlistSortTypes"
              :key="sortType"
              :value="sortType"
            >
              {{ WatchlistSortTypeMapper[sortType] }}
            </option>
          </DropdownField>
        </div>
      </div>

      <div class="watchlist-wrapper">
        <p class="m-b-s" v-if="watchlistedAds < 1">
          No items in your watchlist.
        </p>
        <WatchlistItem v-for="ad in watchlistedAds" :key="ad.id" :ad="ad" />
      </div>
    </div>
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";
import DropdownField from "@/components/form/DropdownField.vue";
import WatchlistItem from "@/components/WatchlistItem.vue";

import {
  WatchlistSortTypes,
  WatchlistSortTypeMapper,
} from "@/util/constants/enums";

export default {
  name: "Watchlist",
  created() {
    this.$store.dispatch("watchlist/setWatchlist");
  },
  data() {
    return {
      WatchlistSortTypes,
      WatchlistSortTypeMapper,
    };
  },
  computed: {
    watchlistedAds() {
      return this.$store.getters["watchlist/watchlistItems"]();
    },
  },
  components: {
    ContentLimiter,
    UserLinksCard,
    DropdownField,
    WatchlistItem,
  },
};
</script>