var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"author-carousel mobile-content"},[(_vm.landingSection && _vm.landingSection.author1Text)?_c('div',{staticClass:"carousel-container"},[_c('VueSlickCarousel',{ref:"carousel",attrs:{"draggable":true,"slidesToShow":9,"touchMove":true,"swipe":true,"autoplay":true,"responsive":[
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 460,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
          },
        } ]}},[_c('AuthorBadgeMobile',{attrs:{"name":_vm.landingSection.author1Text,"image":_vm.landingSection.author1Image,"link":_vm.landingSection.author1Link}}),_c('AuthorBadgeMobile',{attrs:{"name":_vm.landingSection.author2Text,"image":_vm.landingSection.author2Image,"link":_vm.landingSection.author2Link}}),_c('AuthorBadgeMobile',{attrs:{"name":_vm.landingSection.author3Text,"image":_vm.landingSection.author3Image,"link":_vm.landingSection.author3Link}}),_c('AuthorBadgeMobile',{attrs:{"name":_vm.landingSection.author4Text,"image":_vm.landingSection.author4Image,"link":_vm.landingSection.author4Link}}),_c('AuthorBadgeMobile',{attrs:{"name":_vm.landingSection.author5Text,"image":_vm.landingSection.author5Image,"link":_vm.landingSection.author5Link}}),_c('AuthorBadgeMobile',{attrs:{"name":_vm.landingSection.author6Text,"image":_vm.landingSection.author6Image,"link":_vm.landingSection.author6Link}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }