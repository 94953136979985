import constants from "../util/constants";

class CartService {
    async saveCartToStorage(cart) {
        localStorage.setItem(constants.cartLocalStorageKey, JSON.stringify(cart));
    }

    async getCartFromStorage() {
        const cartString = localStorage.getItem(constants.cartLocalStorageKey);

        return cartString && cartString.length && cartString != 'null' && cartString != 'undefined' ? JSON.parse(cartString) : [];
    }
}

export default new CartService();