<template>
  <UserInfoCard v-if="currentUser" :user="currentUser" />
</template>

<script>
import UserInfoCard from './UserInfoCard.vue';

export default {
  name: "ProfileInfoCard",
  components: {
    UserInfoCard,
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    }
  }
};
</script>