<template>
  <div
    class="image-picker"
    :style="image ? `background-image: url(${imagePath});` : ''"
  >
    <label tabindex="0">
      <input type="file" @change="loadImage" />

      <img v-if="!image" class="plus" src="@/assets/svg/camera.svg" />
    </label>
  </div>
</template>

<script>
export default {
  name: "CameraPicker",
  data() {
    return {
      imagePath: null,
      image: null,
    };
  },
  methods: {
    loadImage(event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();

        this.image = input.files[0];

        reader.onload = (e) => (this.imagePath = e.target.result);
        reader.readAsDataURL(input.files[0]);

        this.$emit("load", this.image);
      }
    },
  },
};
</script>