<template>
  <div v-if="landingSection && landingSection.landingSection1Text" class="home-hero-container">
    <!-- First section -->
    <router-link
      v-if="landingSection.landingSection1Link && landingSection.landingSection1Link.length"
      :to="`${landingSection.landingSection3link}`"
      class="first-landing-container"
      :style="getSafeBackgroundImage(landingSection.landingSection1Image)"
    >
      <h2 v-html="landingSection.landingSection1Text" ></h2>
    </router-link>

    <div
      v-else
      class="first-landing-container"
      :style="getSafeBackgroundImage(landingSection.landingSection1Image)"
    >
      <h2 v-html="landingSection.landingSection1Text" ></h2>
    </div>

    <!-- Second section -->
    <router-link
      v-if="landingSection.landingSection2link && landingSection.landingSection2Link.length"
      :to="`${landingSection.landingSection2link}`"
      class="second-landing-container"
      :style="getSafeBackgroundImage(landingSection.landingSection2Image)"
    >
      <h1 v-html="landingSection.landingSection2Text" ></h1>
    </router-link>

    <div
      v-else
      class="second-landing-container"
      :style="getSafeBackgroundImage(landingSection.landingSection2Image)"
    >
      <h1 v-html="landingSection.landingSection2Text" ></h1>
    </div>

    <!-- Third section -->
    <router-link
      v-if="landingSection.landingSection3Link && landingSection.landingSection3Link.length"
      :to="`${landingSection.landingSection3link}`"
      class="third-landing-container"
      :style="getSafeBackgroundImage(landingSection.landingSection3Image)"
    >
      <h2 v-html="landingSection.landingSection3Text" ></h2>
    </router-link>

    <div
      v-else
      class="third-landing-container"
      :style="getSafeBackgroundImage(landingSection.landingSection3Image)"
    >
      <h2 v-html="landingSection.landingSection3Text" ></h2>
    </div>
  </div>
</template>

<script>
import constants from "@/util/constants";

export default {
  name: "HomeHeroDesktop",
  computed: {
    landingSection() {
      return this.$store.getters["content/landingSection"]();
    },
  },
  methods: {
    getSafeBackgroundImage(image) {
      return image ? `background-image: url(${constants.apiUrl}/static/${image})` : "";
    }
  }
};
</script>