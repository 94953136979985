<template>
  <ContentLimiter>
    <AuthorInfo
        image="/assets/images/authors/j-r-r-tolkien.png"
        name="J.R.R. Tolkien" />

    <RowCarousel title="Books by J.R.R. Tolkien" />

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from '@/components/NewsletterBlock.vue';
import AuthorInfo from '@/components/AuthorInfo.vue';
import RowCarousel from '@/components/RowCarousel.vue';

export default {
  name: "Account",
  components: {
    ContentLimiter,
    NewsletterBlock,
    AuthorInfo,
    RowCarousel
  }
};
</script>