<template>
  <div>
    <FormRow>
      <h1>Contact information</h1>
    </FormRow>

    <FormRow>
      <TextField
        label="Email displayed on ads"
        :defaultValue="form.displayedEmail.value"
        :errorMessage="form.displayedEmail.error"
        readonly
        @change="(value) => set('displayedEmail', value, form)"
      />
    </FormRow>
    <FormRow>
      <TextField
        label="Phone number"
        :defaultValue="form.mobile.value"
        :errorMessage="form.mobile.error"
        @change="(value) => set('mobile', value, form)"
      />
    </FormRow>

    <FormRow>
      <DropdownField
        label="State"
        mandatory
        :defaultValue="form.state.value"
        :errorMessage="form.state.error"
        @change="(value) => set('state', value, form)"
      >
        <option
          v-for="state in states"
          :key="state.abbreviation"
          :value="state.abbreviation"
        >
          {{ state.name }}
        </option>
      </DropdownField>
    </FormRow>

    <FormRow>
      <TextField
        :defaultValue="form.city.value"
        mandatory
        label="City"
        :errorMessage="form.city.error"
        @change="(value) => set('city', value, form)"
      />
    </FormRow>

    <FormRow>
      <MainCTA @click="submit" :disabled="!validate(form)">Save</MainCTA>
    </FormRow>
  </div>
</template>

<script>
import { set, validate, required, isEmail, maxLength } from "vue-val";

import TextField from "@/components/form/TextField.vue";
import DropdownField from "@/components/form/DropdownField.vue";
import FormRow from "@/components/form/FormRow.vue";
import MainCTA from "@/components/form/MainCTA.vue";

import states from "@/util/constants/australianStates";

export default {
  name: "ContactInformationForm",
  components: {
    TextField,
    FormRow,
    MainCTA,
    DropdownField,
  },
  data() {
    return {
      states,

      form: {
        displayedEmail: {
          valid: false,
          error: null,
          constraints: [required, isEmail, maxLength(320)],
        },
        mobile: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        state: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        city: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
      },
      set,
      validate,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.prePopulateFields();
  },
  methods: {
    prePopulateFields() {
      if (!this.user) return;

      set('displayedEmail', this.user.email, this.form);
      set('mobile', this.user.mobile, this.form);
      set('state', this.user.state, this.form);
      set('city', this.user.city, this.form);
    },
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      this.$emit("submit", resultObject);
    },
  },
  watch: {
    user() {
      this.prePopulateFields();
    }
  }
};
</script>