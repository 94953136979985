<template>
  <div>
    <FormRow>
      <h1 class="m-b-m">Contact us</h1>
    </FormRow>

    <FormRow>
      <p>
        Not able to find the answer in our
        <router-link to="/help">Help section</router-link>? <br /><br />
        Please fill in this form:
        <br /><br /><br />
        Fields marked <span class="red-color">*</span> are required.
        <br />
        <br />
      </p>
    </FormRow>

    <FormRow>
      <TextField
        label="Full name"
        mandatory
        :defaultValue="form.fullName.value"
        :errorMessage="form.fullName.error"
        @change="(value) => set('fullName', value, form)"
      />
    </FormRow>
    <FormRow>
      <TextField
        label="E-mail"
        mandatory
        :defaultValue="form.email.value"
        :errorMessage="form.email.error"
        @change="(value) => set('email', value, form)"
      />
    </FormRow>
    <FormRow>
      <TextField
        label="Postcode"
        :defaultValue="form.postCode.value"
        :errorMessage="form.postCode.error"
        @change="(value) => set('postCode', value, form)"
      />
    </FormRow>
    <FormRow>
      <TextField
        label="Order number"
        :defaultValue="form.orderNumber.value"
        :errorMessage="form.orderNumber.error"
        @change="(value) => set('orderNumber', value, form)"
      />
    </FormRow>
    <FormRow>
      <TextField
        label="Reason"
        mandatory
        :defaultValue="form.reason.value"
        :errorMessage="form.reason.error"
        @change="(value) => set('reason', value, form)"
      />
    </FormRow>
    <FormRow>
      <TextareaField
        label="Message"
        mandatory
        :defaultValue="form.message.value"
        :errorMessage="form.message.error"
        @change="(value) => set('message', value, form)"
      />
    </FormRow>

    <FormRow>
      <MainCTA @click="submit" :disabled="!validate(form)">Send</MainCTA>
    </FormRow>

    <FormRow>
      <p>
        Don't want to use the form? Email us at
        <a :href="`mailto:${constants.contactEmail}`">{{
          constants.contactEmail
        }}</a>
      </p>
    </FormRow>
  </div>
</template>

<script>
import { set, validate, required, isEmail, maxLength } from "vue-val";

import TextField from "@/components/form/TextField.vue";
import FormRow from "@/components/form/FormRow.vue";
import TextareaField from "@/components/form/TextareaField.vue";
import MainCTA from "@/components/form/MainCTA.vue";

import constants from "@/util/constants";

export default {
  name: "ContactForm",
  components: {
    TextField,
    FormRow,
    TextareaField,
    MainCTA,
  },
  data() {
    return {
      constants,

      form: {
        fullName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        email: {
          valid: false,
          error: null,
          constraints: [required, isEmail, maxLength(320)],
        },
        postCode: {
          valid: false,
          error: null,
          constraints: [maxLength(320)],
        },
        orderNumber: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        reason: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        message: {
          valid: false,
          error: null,
          constraints: [required, maxLength(500)],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      this.$emit("submit", resultObject);
    },
  },
};
</script>