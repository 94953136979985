<template>
  <div v-if="currentUser" >
    <p class="m-t-m m-b-xs">Comment as <span class="primary-color-text" >{{ currentUser.firstName  }} {{ currentUser.lastName }}</span></p>

    <div class="m-b-s">
      <wysiwyg v-model="form.content.value" />
    </div>

    <div class="card-section">
      <MainCTA @click="submit" :disabled="form.content.value && form.content.value.trim().length <= 0">Comment</MainCTA>
    </div>
  </div>
</template>

<script>
import {
  set,
  validate,
  required,
  maxLength,
} from "vue-val";

import MainCTA from "@/components/form/MainCTA.vue";

export default {
  name: "ForumCommentForm",
  components: {
    MainCTA,
  },
  data() {
    return {
      form: {
        content: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
      },
      set,
      validate,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
  },
  methods: {
    submit() {
      const resultObject = {};

      Object.keys(this.form)
        .forEach(key => resultObject[key] = this.form[key].value);

      set("content", null, this.form);

      this.$emit("submit", resultObject);
    },
  },
};
</script>