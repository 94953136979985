<template>
  <button class="notifications-button" @click="markNotificationsAsSeen" >
    <img src="@/assets/svg/bell-hollow.svg" />
    <span v-if="notifications && notifications.length && notifications.filter(e => !e.read).length" class="counter" >{{ notifications.filter(e => !e.read).length }}</span>
    <div v-if="notifications " class="notification-container card" >
      
      <NotificationItem v-for="notification in notifications" :key="notification.id" :notification="notification" />

    </div>
  </button>
</template>

<script>
import NotificationItem from '@/components/notifications/NotificationItem.vue';

import constants from "@/util/constants";

export default {
  name: "NotificationsButton",
  components: { NotificationItem },
  created() {
    this.getLatestNotifications();

    setInterval(this.getLatestNotifications, constants.notificationsPollingInterval);
  },
  computed: {
    notifications() {
      return this.$store.getters["notification/notifications"]();
    }
  },
  methods: {
    async getLatestNotifications() {
      await this.$store.dispatch("notification/setNotifications");
    },
    async markNotificationsAsSeen() {
      setTimeout(async () => {
        await this.$store.dispatch("notification/markAllNotificationsForUserAsRead");
        await this.$store.dispatch("notification/setNotifications");
      }, 1000)
    }
  }
};
</script>