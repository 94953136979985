import BaseApiClient from "./BaseApiClient";

class AdminClient extends BaseApiClient {
    getContent() {
        return this.get(`/admin/hero-section`);
    }

    updateContent(
        id,
        landingSection1Text,
        landingSection1Image,
        landingSection1Link,
        landingSection2Text,
        landingSection2Image,
        landingSection2Link,
        landingSection3Text,
        landingSection3Image,
        landingSection3Link,
        author1Text,
        author1Image,
        author1Link,
        author1Description,
        author2Text,
        author2Image,
        author2Link,
        author2Description,
        author3Text,
        author3Image,
        author3Link,
        author3Description,
        author4Text,
        author4Image,
        author4Link,
        author4Description,
        author5Text,
        author5Image,
        author5Link,
        author5Description,
        author6Text,
        author6Image,
        author6Link,
        author6Description
    ) {
        console.log(id,
            landingSection1Text,
            landingSection1Image,
            landingSection1Link,
            landingSection2Text,
            landingSection2Image,
            landingSection2Link,
            landingSection3Text,
            landingSection3Image,
            landingSection3Link,
            author1Text,
            author1Image,
            author1Link,
            author1Description,
            author2Text,
            author2Image,
            author2Link,
            author2Description,
            author3Text,
            author3Image,
            author3Link,
            author3Description,
            author4Text,
            author4Image,
            author4Link,
            author4Description,
            author5Text,
            author5Image,
            author5Link,
            author5Description,
            author6Text,
            author6Image,
            author6Link,
            author6Description);
        return this.post(`/admin/hero-section/${id}`, {
            landingSection1Text,
            landingSection1Image,
            landingSection1Link,
            landingSection2Text,
            landingSection2Image,
            landingSection2Link,
            landingSection3Text,
            landingSection3Image,
            landingSection3Link,
            author1Text,
            author1Image,
            author1Link,
            author1Description,
            author2Text,
            author2Image,
            author2Link,
            author2Description,
            author3Text,
            author3Image,
            author3Link,
            author3Description,
            author4Text,
            author4Image,
            author4Link,
            author4Description,
            author5Text,
            author5Image,
            author5Link,
            author5Description,
            author6Text,
            author6Image,
            author6Link,
            author6Description
        });
    }

    filterUsers(query) {
        return this.get(`/admin/users/search/findByFirstNameContainsOrLastNameContainsOrEmailContainsAllIgnoreCase`, {}, {
            page: 0,

            email: query,
            firstName: query,
            lastName: query,
        });
    }

    filterOrders(query) {
        return this.get(`/admin/orders`, {}, {
            page: 0,

            orderNumber: query
        });
    }

    banUser(userId) {
        return this.patch(`/admin/users/${userId}`, { activated: false });
    }

    unbanUser(userId) {
        return this.patch(`/admin/users/${userId}`, { activated: true });
    }

    sendNewsletter(content) {
        return this.post(`/admin/newsletter`, { content });
    }
}

export default new AdminClient();
