import BaseApiClient from "./BaseApiClient";


class SubjectsClient extends BaseApiClient {

    getAllSubjects() {
        return this.get(`/api/subjects`)
    }
}

export default new SubjectsClient();
