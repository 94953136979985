import AdClient from "../clients/AdClient";

class AdService {
    async filter(filters) {
        const result = await AdClient.filterAds(
            'id',
            'asc',
            0,
            50,
            filters.keywords,
            filters.priceFrom,
            filters.priceTo,
            filters.format,
            filters.bookState,
            filters.condition,
            filters.state,
            filters.city,
            filters.language,
            filters.bookTitle,
            filters.authorName,
            true,
            filters.subject,
            filters.subSubject,
            filters.subSubSubject,
            filters.bookIds
        );

        return result.content;
    }

    async getById(id) {
        return AdClient.getAd(id);
    }


    async getRecommendedItems() {
        return await AdClient.getRelated();
    }

    async getBestsellers() {
        return this.filter({
            subject: 33,
            subSubSubject: 404,
        });
    }

    async getChildrensBooks() {
        return (await Promise.all([
            this.filter({ subject: 10 }), // Age 0 to 2
            this.filter({ subject: 11 }), // Age 3 to 5
            this.filter({ subject: 12 }), // Age 6 to 8
            this.filter({ subject: 13 }), // Age 9 to 12
        ])).flatMap(e => e);
    }

    async getEducationalBooks() {
        return this.filter({
            subject: 31,
        });
    }

    async getLatest() {
        // TODO: Remove lazy load.
        if (this.latest) return this.latest;

        const result = await AdClient.filterAds(
            'id',
            'asc',
            0,
            50,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
            null,
            null,
            null,
            null,
        );

        this.latest = result.content;

        return result.content;
    }

    async getByAuthorName(authorName) {
        const result = await AdClient.filterAds(
            'id',
            'asc',
            0,
            50,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            authorName,
            true,
            null,
            null,
            null,
            null,
        );

        return result;
    }


    async createAd(ad) {
        return await AdClient.createAd(
            ad.price,
            ad.language,
            ad.bookState,
            ad.coverState,
            ad.edgesState,
            ad.spineState,
            ad.pageStructureState,
            ad.bookId,
            [ad.image1, ad.image2, ad.image3, ad.image4]
                .filter(e => !!e)
                .map(e => e.split(',')[1]),
            ad.keywords.filter(e => !!e),
            ad.sellerState,
            ad.sellerCity,

            ad.authorName,
            ad.bookTitle,
            ad.description,
            ad.edition,
            ad.format,
            ad.isbn10,
            ad.isbn13,
            ad.publicationDate,
            ad.publisher,
        );
    }

    async editAd(ad) {
        return await AdClient.editAd(
            ad.id,
            ad.price,
            ad.language,
            ad.bookState,
            ad.coverState,
            ad.edgesState,
            ad.spineState,
            ad.pageStructureState,
            ad.bookId,
            [ad.image1, ad.image2, ad.image3, ad.image4]
                .filter(e => !!e)
                .map(e => {
                    if (e.startsWith("data:image"))
                        return e.split(',')[1];
                    
                    return e;
                }),
            ad.keywords.filter(e => !!e),
            ad.sellerState,
            ad.sellerCity,
            
            ad.authorName,
            ad.bookTitle,
            ad.description,
            ad.edition,
            ad.format,
            ad.isbn10,
            ad.isbn13,
            ad.publicationDate,
            ad.publisher,
        );
    }

    async removeAd(adId) {
        return await AdClient.deleteAd(
            adId
        );
    }


    async postAdComment(adId, comment) {
        return await AdClient.postAdComment(adId, comment);
    }

    async removeAdComment({adId, commentId}) {
        return await AdClient.removeAdComment(adId, commentId);
    }
}

export default new AdService();