import AddressBookClient from "../clients/AddressBookClient";

class AddressService {
    async getUserAddresses() {
        const result = await AddressBookClient.getAddressBook();

        return result;
    }

    async createAddress(address) {
        const result = await AddressBookClient.createAddress(
            address.referenceName,
            address.fullName,
            address.addressLine1,
            address.addressLine2,
            address.city,
            address.state,
            address.postCode
        );

        return result;
    }

    async editAddress(address) {
        const result = await AddressBookClient.editAddress(
            address.id,
            address.referenceName,
            address.fullName,
            address.addressLine1,
            address.addressLine2,
            address.city,
            address.state,
            address.postCode
        );

        return result;
    }

    async deleteAddress(address) {
        const result = await AddressBookClient.deleteAddress(address.id);

        return result;
    }
}

export default new AddressService();