<template>
  <ContentLimiter>
    <div class="order-history">
      <div class="col-3 col-t-4 col-m-12 m-b-s">
        <UserLinksCard />
      </div>

      <div class="card col-9 col-t-8 col-m-12 m-b-m">
        <h2 class="m-b-m">Order history</h2>
        <div class="dropdown-wrapper">
          <DropdownField label="Sort items by">
            <option value="">Any</option>
            <option
              v-for="sortType in OrderHistorySortTypes"
              :key="sortType"
              :value="sortType"
            >
              {{ OrderHistorySortTypeMapper[sortType] }}
            </option>
          </DropdownField>
        </div>
        <span class="total"
          >Total spent: A$
          {{
            orderHistory && orderHistory.length
              ? totalSpent.toFixed(2)
              : (0).toFixed(2)
          }}</span
        >
        <div v-if="orderHistory" class="orders">
          <OrderWrapper
            v-for="order in orderHistory"
            :key="order.id"
            :order="order"
          />
        </div>
      </div>
    </div>
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";
import DropdownField from "@/components/form/DropdownField.vue";
import OrderWrapper from "@/components/OrderWrapper.vue";

import {
  OrderHistorySortTypes,
  OrderHistorySortTypeMapper,
} from "@/util/constants/enums";

export default {
  name: "OrderHistory",
  components: {
    ContentLimiter,
    UserLinksCard,
    DropdownField,
    OrderWrapper,
  },
  data() {
    return {
      OrderHistorySortTypes,
      OrderHistorySortTypeMapper,
    };
  },
  created() {
    this.$store.dispatch("order/setHistory");
  },
  computed: {
    orderHistory() {
      return this.$store.getters["order/history"]();
    },
    totalSpent() {
      if (!this.orderHistory) return 0;

      return this.orderHistory
        .flatMap((e) => e.ads)
        .map((e) => e.price)
        .reduce((a, b) => a + b);
    },
  },
};
</script>