<template>
  <div id="app">
    <Prompt />
    <BookPicker />

    <MainHeader />

    <MobileMenu />

    <main>
      <router-view />
    </main>

    <MainFooter />

    <FloatingMenu />
  </div>
</template>

<script>
import MainHeader from "@/components/header/MainHeader";
import MobileMenu from "@/components/MobileMenu";
import MainFooter from "@/components/footer/MainFooter";
import FloatingMenu from "@/components/FloatingMenu.vue";
import Prompt from "@/components/modal/Prompt.vue";
import BookPicker from "@/components/modal/BookPicker.vue";

export default {
  name: "App",
  components: {
    MainHeader,
    MobileMenu,
    MainFooter,
    FloatingMenu,
    Prompt,
    BookPicker,
  },
  created() {
    this.$store.dispatch("cart/setCart");
    this.$store.dispatch("ad/setLatest");
  },
};
</script>

<style lang="scss">
@import "./scss/main.scss";
</style>
