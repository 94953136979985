<template>
  <ContentLimiter>
    <div class="edit-ad-page">
      <div class="col-4 col-m-12 m-b-s">
        <ProfileInfoCard />
      </div>

      <div class="card col-8 col-m-12">
        <h1 class="m-b-l">Edit your ad:</h1>

        <div class="card-section">
          <ManageAdForm v-if="editedAd" :ad="editedAd" @submit="submitAd" />
        </div>
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import ProfileInfoCard from "@/components/ProfileInfoCard.vue";

import ManageAdForm from "@/views/forms/ManageAdForm.vue";

export default {
  name: "EditAd",
  components: {
    ContentLimiter,
    NewsletterBlock,
    ProfileInfoCard,
    ManageAdForm,
  },
  computed: {
    editedAd() {
      return this.$store.getters["ad/editAd"]();
    }
  },
  mounted() {
    this.setEditedAd();
  },
  methods: {
    setEditedAd() {
      this.$store.dispatch("ad/setEditAd", this.$route.params.id);
    },
    async submitAd(form) {
      form.id = this.$route.params.id;
      
      await this.$store.dispatch("ad/edit", form);
      
      this.$store.dispatch("authentication/getUserData");

      this.$router.push("/profile");
    },
  },
  watch: {
    $route() {
      this.setEditedAd();
    },
  },
};
</script>