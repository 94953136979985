var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length)?_c('div',{staticClass:"row-container"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"carousel-container"},[_c('button',{staticClass:"carousel-arrow left",on:{"click":_vm.prev}},[_c('img',{attrs:{"src":require("@/assets/svg/angle-right.svg")}})]),_c('VueSlickCarousel',{ref:"carousel",attrs:{"draggable":true,"slidesToShow":9,"touchMove":true,"swipe":true,"responsive":[
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 7,
          },
        },
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 840,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        } ]}},_vm._l((_vm.items),function(item){return _c('AdBadge',{key:item.id,attrs:{"ad":item}})}),1),_c('button',{staticClass:"carousel-arrow right",on:{"click":_vm.next}},[_c('img',{attrs:{"src":require("@/assets/svg/angle-right.svg")}})])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }