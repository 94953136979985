<template>
    <div class="payment-block">
        <p class="text-mobile">In placing your order, you are confirming that you have read and agree to our <router-link :to="`/help`" >Terms and Conditions</router-link>.
        Please also see our <router-link :to="`/help`" >Privacy Policy</router-link> and our <router-link :to="`/help`" >Cookies Policy</router-link>.</p>
        <div class="col-6 col-t-12 col-m-12">
          <div class="payment-cards">
            <span>Huff Books accepts:</span>
            <div class="icon-wrapper">
              <img src="@/assets/svg/payment-visa.svg" />
              <img src="@/assets/svg/payment-mastercard.svg" />
              <img src="@/assets/svg/payment-americanexpress.svg" />
            </div>
          </div>
        </div>
        <div class="col-6 col-t-12 col-m-12">
          <div class="payment-info">
            <div v-if="true" class="shipping-cost">
              <strong>Fees: A$ {{ transactionFee.toFixed(2) }}</strong>
            </div>
            <div class="total-cost">
              <strong>Total: A$ {{ (totalPrice + transactionFee).toFixed(2) }}</strong>
            </div>

            <MainCTA v-if="currentUser" color="gray" href="/checkout" >Checkout</MainCTA>
            <MainCTA v-else color="gray" href="/account" >Checkout</MainCTA>
          </div>
        </div>
    </div>
</template>

<script>
import MainCTA from "@/components/form/MainCTA.vue";
import constants from "@/util/constants";

export default {
  name: "PaymentBlock",
  components: {
    MainCTA
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    totalPrice() {
      if (this.$store.getters["cart/cartAds"]().length)
        return this.$store.getters["cart/cartAds"]()
          .map(e => e.price)
          .reduce((a, b) => a + b);
      else
        return 0;
    },
    transactionFee() {
      const multiplicationFactor = (constants.huffbooksFeeInPercents + constants.stripeFeeInPercents) / 100;

      return this.totalPrice * multiplicationFactor;
    }
  }
};
</script>