import forumService from "@/services/forumService";
import profileService from "@/services/profileService";

export default {
    namespaced: true,
    state: {
        currentPost: null,

        newPosts: [],
        topPosts: [],
        currentUserPosts: [],
        currentUserComments: [],
        displayedProfile: null,
    },
    mutations: {
        setCurrentPost: (state, payload) => state.currentPost = payload,

        setNewPosts: (state, payload) => state.newPosts = payload,
        setTopPosts: (state, payload) => state.topPosts = payload,
        setCurrentUserPosts: (state, payload) => state.currentUserPosts = payload,
        setCurrentUserComments: (state, payload) => state.currentUserComments = payload,
        setDisplayedProfile: (state, payload) => state.displayedProfile = payload,
    },
    actions: {
        createPost: async (context, newPost) => {
            await forumService.createPost(newPost);
        },

        setCurrentPost: async (context, postId) => {
            const post = await forumService.getPostById(postId);

            context.commit("setCurrentPost", post);
        },

        setNewPosts: async (context, parameters) => {
            context.commit("setNewPosts", await forumService.filter({
                topicId: parameters.topicId,
                authorId: parameters.authorId,
                sortBy: 'id',
                sortOrder: 'desc',
                page: parameters.page
            }));
        },
        setTopPosts: async (context, parameters) => {
            context.commit("setTopPosts", await forumService.filter({
                topicId: parameters.topicId,
                authorId: parameters.authorId,
                sortBy: 'likesCount',
                sortOrder: 'desc',
                page: parameters.page
            }));
        },
        setCurrentUserPosts: async (context, parameters) => {
            context.commit("setCurrentUserPosts", await forumService.filter({
                topicId: parameters.topicId,
                authorId: parameters.authorId,
                sortBy: 'id',
                sortOrder: 'desc',
                page: parameters.page
            }));
        },
        setCurrentUserComments: async (context) => {
            context.commit("setCurrentUserComments", await forumService.getCurrentUserComments());
        },
        setDisplayedProfile: async (context, authorId) => {
            context.commit("setDisplayedProfile", await profileService.getSellerById(authorId));
        },

        createPostComment: async (context, commentData) => {
            await forumService.createPostComment(commentData);
        },
        editPost: async (context, postIdAndContent) => {
            return await forumService.editPost(postIdAndContent);
        },
        removePost: async (context, postId) => {
            return await forumService.removePost(postId);
        },
        likePost: async (context, postId) => {
            await forumService.likePost(postId);
        },
        dislikePost: async (context, postId) => {
            await forumService.dislikePost(postId);
        },

        editComment: async (context, commentIdAndContent) => {
            return await forumService.editComment(commentIdAndContent);
        },
        removeComment: async (context, commentId) => {
            return await forumService.removeComment(commentId);
        },
        likeComment: async (context, commentAndPostId) => {
            await forumService.likeComment(commentAndPostId);
        },
        dislikeComment: async (context, commentAndPostId) => {
            await forumService.dislikeComment(commentAndPostId);
        },
    },
    getters: {
        currentPost: state => () => state.currentPost,
        newPosts: state => () => state.newPosts,
        topPosts: state => () => state.topPosts,
        currentUserPosts: state => () => state.currentUserPosts,
        currentUserComments: state => () => state.currentUserComments,
        displayedProfile: state => () => state.displayedProfile,
    }
};