import BaseApiClient from "./BaseApiClient";


class NewsletterClient extends BaseApiClient {

    subscribeToNewsletter(email) {
        return this.post(`/api/newsletter`, {email})
    }

    unSubscribeFromNewsletter(email) {
        return this.post(`/api/newsletter/unsubscribe`, {email})
    }
}

export default new NewsletterClient();
