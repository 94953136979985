<template>
    <div class="form-row" >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'FormRow'
}
</script>