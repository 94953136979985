<template>
  <div v-if="items && items.length" class="row-container">
    <h3>{{ title }}</h3>

    <div class="carousel-container">
      <button class="carousel-arrow left" @click="prev">
        <img src="@/assets/svg/angle-right.svg" />
      </button>

      <VueSlickCarousel
        ref="carousel"
        :draggable="true"
        :slidesToShow="9"
        :touchMove="true"
        :swipe="true"
        :responsive="[
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 7,
            },
          },
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 840,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 560,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
            },
          },
        ]"
      >
        <AdBadge v-for="item in items" :key="item.id" :ad="item" />
      </VueSlickCarousel>

      <button class="carousel-arrow right" @click="next">
        <img src="@/assets/svg/angle-right.svg" />
      </button>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// Optional style for arrows & dots.
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import AdBadge from "@/components/AdBadge.vue";

export default {
  name: "RowCarousel",
  components: {
    VueSlickCarousel,
    AdBadge,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    next() {
      this.$refs.carousel.next();
    },
    prev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>