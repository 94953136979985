<template>
  <div class="line-item basket-item">
    <span class="title title-mobile">{{ ad.bookTitle }}</span>

    <div
      class="image-wrapper col-2 col-m-4"
      :style="`background-image: url(${
        ad.images && ad.images.length
          ? `${constants.apiUrl}/static/${ad.images[0]}.jpg`
          : '/assets/images/ad-default.png'
      });`"
    ></div>

    <div class="description-wrapper col-7 col-t-7 col-m-8 m-r-s">
      <router-link :to="`/ad/${ad.id}`" class="title">{{ ad.bookTitle }}</router-link>

      <span>
        {{ ad.format ? FormatMapper[ad.format] : "Format not specified" }}
        
        |
        
        {{ ad.language ? ad.language.toUpperCase() : "Language not specified" }}
        
        |
        
        {{ ad.authorName ? ad.authorName : "Author not specified" }}
      </span>

      <span v-if="ad.bookState" class="book-state">
        Book state:
        <span>{{ BookStateMapper[ad.bookState] }}</span>
      </span>

      <div class="seller m-t-s">
        <span>
          Posted by:
          <router-link :to="`/profile/${ad.user.id}`">{{
            `${ad.user.firstName} ${ad.user.lastName}`
          }}</router-link>
          from <i>{{ ad.sellerCity }}, {{ ad.sellerState.toUpperCase() }}</i>
        </span>
      </div>
    </div>

    <div class="info-wrapper col-3 col-m-12">
      <div class="info">
        <h3 class="primary-color-text">A$ {{ ad.price.toFixed(2) }}</h3>

        <MainCTA color="light-gray" @click="() => removeFromBasket(ad.id)"
          >Remove</MainCTA
        >
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import MainCTA from "@/components/form/MainCTA.vue";

import constants from "@/util/constants";
import { FormatMapper, BookStateMapper } from "@/util/constants/enums";

export default {
  name: "BasketItem",
  components: {
    MainCTA,
  },
  data() {
    return {
      constants,
      FormatMapper,
      BookStateMapper,
    };
  },
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeFromBasket(id) {
      window.$prompt(
        "Remove from basket",
        `Are you sure that you want to remove "${this.ad.bookTitle}" from the basket?`,
        () => {
          this.$store.dispatch("cart/removeFromCart", id);

          Vue.$toast.open({
            message: `Item "${this.ad.bookTitle}" removed from basket!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
  },
};
</script>