<template>
  <div class="seller-ad-item">
    <div class="title-mobile">
      <router-link class="title" :to="`/ad/${ad.id}`">{{
        ad.bookTitle
      }}</router-link>
    </div>

    <div
      v-if="ad.images.length && ad.images[0]"
      class="image-wrapper col-2 col-t-2 col-m-12"
      :style="`background-image: url(${getAssetUrl(ad.images[0])})`"
    ></div>
    <div
      v-else
      class="image-wrapper col-2 col-m-5"
      style="background-image: url(/assets/images/ad-default.png)"
    ></div>

    <div class="description-wrapper col-8 col-t-4 col-m-12">
      <router-link class="title" :to="`/ad/${ad.id}`">{{
        ad.bookTitle
      }}</router-link>
      <span v-if="ad.bookState" class="book-state">
        Book state:
        <span>{{ BookStateMapper[ad.bookState] }}</span>
      </span>
      <p class="description">
        {{ ad.description || "No description set for this item." }}
      </p>
    </div>

    <div class="info-wrapper col-2 col-t-2 col-m-12">
      <div class="info">
        <span class="price">A$ {{ ad.price.toFixed(2) }}</span>

        <span class="time">{{
          new Date(ad.createdDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        }}</span>

        <span class="place"
          >{{ ad.sellerState.toUpperCase() }}, {{ ad.sellerCity }}</span
        >
      </div>

      <div v-if="ad.user.id != currentUser.id">
        <HeartIcon
          v-if="watchlistedAds.find((e) => e.id == ad.id)"
          checked
          @click="() => removeFromWatchlist(ad.id)"
        />
        <HeartIcon v-else @click="() => addToWatchlist(ad.id)" />
      </div>
    </div>

    <div class="info-wrapper info-wrapper-mobile col-m-6">
      <div class="info">
        <span class="book-state">
          Book state:
          <span>{{ BookStateMapper[ad.bookState] }}</span>
        </span>

        <div v-if="ad.user.id != currentUser.id">
          <HeartIcon
            v-if="watchlistedAds.find((e) => e.id == ad.id)"
            checked
            @click="() => removeFromWatchlist(ad.id)"
          />
          <HeartIcon v-else @click="() => addToWatchlist(ad.id)" />
        </div>

        <span class="price">A$ {{ ad.price.toFixed(2) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import HeartIcon from "@/components/HeartIcon.vue";

import constants from "@/util/constants";
import { BookStateMapper } from "@/util/constants/enums";

export default {
  name: "SellerAdItem",
  components: {
    HeartIcon,
  },
  data() {
    return {
      BookStateMapper
    };
  },
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    watchlistedAds() {
      return this.$store.getters["watchlist/watchlistItems"]();
    },
  },
  methods: {
    getAssetUrl(assetName) {
      return `${constants.apiUrl}/static/${assetName}.jpg`;
    },
    async addToWatchlist(adId) {
      await this.$store.dispatch("watchlist/addToWatchlist", adId);

      Vue.$toast.open({
        message: `Item added to watchlist!`,
        type: "success",
        position: "bottom",
      });
    },
    async removeFromWatchlist(adId) {
      await this.$store.dispatch("watchlist/removeFromWatchlist", adId);

      Vue.$toast.open({
        message: `Item removed from watchlist!`,
        type: "info",
        position: "bottom",
      });
    },
  },
};
</script>