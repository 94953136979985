<template>
  <div class="forum-item card m-b-s">
    <div class="upvotes col-1 col-t-2 desktop-and-tablet-content">
      <span>{{ post.userLikesCount || 0 }}</span>
      <img v-if="post.likedByCurrentUser" src="@/assets/svg/heart-primary.svg" @click="dislikePost" />
      <img v-else src="@/assets/svg/heart.svg" @click="likePost" />
    </div>

    <div class="col-11 col-t-10 col-m-12">
      <div class="m-b-s">
        <span>
          <strong>{{ ForumTopicMapper[post.forumTopicId] }}</strong> ·
          <router-link :to="`/forum?authorId=${post.authorId}`"
            >Posted by {{ post.authorFirstName }}
            {{ post.authorLastName }}</router-link
          >
          ·
          {{ dateFormatter.format(new Date(post.createdDate)) }}
        </span>
      </div>

      <h3 class="m-b-s">
        <router-link :to="`/forum/posts/${post.id}`">{{
          post.title
        }}</router-link>
      </h3>

      <div class="item-statistics">
        <img class="m-r-xxs" src="@/assets/svg/comment-cloud.svg" />
        <span class="m-r-s">{{ post.commentCount || 0 }} Comments </span>

        <span class="m-r-xxs">Share:</span>
        <SocialShare :shareUrl="getShareUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import SocialShare from "@/components/SocialShare.vue";

import { ForumTopicMapper } from "@/util/constants/enums";

export default {
  name: "ForumItem",
  components: {
    SocialShare,
  },
  data() {
    return {
      ForumTopicMapper,
      dateFormatter: window.dateFormatter,
    };
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getShareUrl() {
      return encodeURIComponent(
        `${window.location.origin}/#/forum/posts/${this.post.id}`
      );
    },
  },
  methods: {
    setForumData() {
      this.$store.dispatch("forum/setNewPosts", {
        page: 0,
      });

      this.$store.dispatch("forum/setTopPosts", {
        page: 0,
      });
    },
    async likePost(postId) {
      await this.$store.dispatch("forum/likePost", this.post.id);

      this.setForumData();
    },
    async dislikePost(postId) {
      await this.$store.dispatch("forum/dislikePost", this.post.id);

      this.setForumData();
    },
  },
};
</script>