import contactService from "@/services/contactService";

export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        contactUs: async (context, data) => {
            await contactService.contactUs(data);
        }
    },
    getters: {
    }
};