export default [
    {
        "name": "Australian Capital Territory",
        "abbreviation": "act",
    },
    {
        "name": "New South Wales",
        "abbreviation": "nsw",
    },
    {
        "name": "Northern Territory",
        "abbreviation": "nt",
    },
    {
        "name": "Queensland",
        "abbreviation": "qld",
    },
    {
        "name": "South Australia",
        "abbreviation": "sa",
    },
    {
        "name": "Tasmania",
        "abbreviation": "tas",
    },
    {
        "name": "Victoria",
        "abbreviation": "vic",
    },
    {
        "name": "Western Australia",
        "abbreviation": "wa",
    }
];