<template>
  <ContentLimiter>
    <div class="forum-content">
      <div v-if="topicId" class="breadcrumbs m-b-s p-l-xs">
        <strong>
          <router-link to="/forum">Forum home</router-link>
          <span> / {{ ForumTopicMapper[topicId] }}</span>
        </strong>
      </div>
      <div v-else class="breadcrumbs m-b-s p-l-xs">
        <strong>Forum home</strong>
      </div>

      <div class="forum-items col-8 col-m-12 m-b-m">
        <div class="tabs-wrapper">
          <ul class="tabs">
            <li>
              <a
                href="javascript:void(0);"
                class="tab"
                :class="activeTab === 0 ? 'is-active' : ''"
                @click="changeTab(0)"
                >Latest</a
              >
            </li>

            <li>
              <a
                href="javascript:void(0);"
                class="tab"
                :class="activeTab === 1 ? 'is-active' : ''"
                @click="changeTab(1)"
                >Top rated</a
              >
            </li>

            <li>
              <a
                href="javascript:void(0);"
                class="tab"
                :class="activeTab === 2 ? 'is-active' : ''"
                @click="changeTab(2)"
                >My posts</a
              >
            </li>

            <li>
              <a
                href="javascript:void(0);"
                class="tab"
                :class="activeTab === 3 ? 'is-active' : ''"
                @click="changeTab(3)"
                >My comments</a
              >
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane p-v-s" v-show="activeTab === 0">
              <ForumItem
                v-for="post in newPosts.content"
                :key="post.id"
                :post="post"
              />

              <div class="flex-distributed-row">
                <span>&nbsp;</span>
                <Pagination :currentPage="1" :totalPages="26" />
              </div>
            </div>

            <div class="tab-pane p-v-s" v-show="activeTab === 1">
              <ForumItem
                v-for="post in topPosts.content"
                :key="post.id"
                :post="post"
              />

              <div class="flex-distributed-row">
                <span>&nbsp;</span>
                <Pagination :currentPage="1" :totalPages="26" />
              </div>
            </div>

            <div class="tab-pane p-v-s" v-show="activeTab === 2">
              <ForumItem
                v-for="post in currentUserPosts.content"
                :key="post.id"
                :post="post"
              />

              <div class="flex-distributed-row">
                <span>&nbsp;</span>
                <Pagination :currentPage="1" :totalPages="26" />
              </div>
            </div>

            <div class="tab-pane p-v-s" v-show="activeTab === 3">
              <!-- <ForumComment /> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 col-m-12 m-t-l">
        <ForumTopics />
        <NewForumPostCTA />

        <div v-if="!currentUser" class="register-form-container card">
          <RegisterForm />
        </div>

        <UserInfoCard v-if="displayedProfile" :user="displayedProfile" />
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";

import RegisterForm from "@/views/forms/RegisterForm.vue";
import ForumItem from "@/components/forum/ForumItem.vue";
// import ForumComment from "@/components/forum/ForumComment.vue";
import Pagination from "@/components/Pagination.vue";
import NewForumPostCTA from "@/components/NewForumPostCTA.vue";
import ForumTopics from "@/components/ForumTopics.vue";
import UserInfoCard from "@/components/UserInfoCard.vue";

import { ForumTopicMapper } from "@/util/constants/enums";

export default {
  name: "Forum",
  components: {
    ContentLimiter,
    NewsletterBlock,
    RegisterForm,
    ForumItem,
    Pagination,
    NewForumPostCTA,
    // ForumComment,
    ForumTopics,
    UserInfoCard,
  },
  data() {
    return {
      ForumTopicMapper,

      activeTab: 0,
      newPostModalVisible: false,
      topicId: null,
      authorId: null,
    };
  },
  created() {
    this.setTopicAndAuthor();
    this.populatePosts();
    this.populateUserData();
    this.populateDisplayedProfile();
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    newPosts() {
      return this.$store.getters["forum/newPosts"]();
    },
    topPosts() {
      return this.$store.getters["forum/topPosts"]();
    },
    currentUserPosts() {
      return this.$store.getters["forum/currentUserPosts"]();
    },
    currentUserComments() {
      return this.$store.getters["forum/currentUserComments"]();
    },
    displayedProfile() {
      return this.$store.getters["forum/displayedProfile"]();
    },
  },
  methods: {
    setTopicAndAuthor() {
      this.topicId = this.$route.params.topicId;
      this.authorId = this.$route.query.authorId;
    },
    populatePosts() {
      this.$store.dispatch("forum/setNewPosts", {
        topicId: this.topicId,
        authorId: this.authorId,
        page: 0,
      });

      this.$store.dispatch("forum/setTopPosts", {
        topicId: this.topicId,
        authorId: this.authorId,
        page: 0,
      });
    },
    populateUserData() {
      if (!this.currentUser) return;

      this.$store.dispatch("forum/setCurrentUserPosts", {
        topicId: this.topicId,
        authorId: this.currentUser.id,
        page: 0,
      });

      this.$store.dispatch("forum/setCurrentUserComments");
    },
    populateDisplayedProfile() {
      if (this.authorId)
        this.$store.dispatch("forum/setDisplayedProfile", this.authorId);
      else
        this.$store.commit("forum/setDisplayedProfile", null);
    },
    changeTab(tabIndexValue) {
      this.activeTab = tabIndexValue;
    },
  },
  watch: {
    $route() {
      this.setTopicAndAuthor();
      this.populatePosts();
      this.populateDisplayedProfile();
    },
  },
};
</script>