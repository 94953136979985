<template>
  <div class="newsletter-block-container">
    <h2>Learn about new books and great deals by joining our newsletter</h2>

    <div class="newsletter-input">
      <input v-model="email" class="text-field" type="text" :placeholder="placeholder" :readonly="isSubscribed" />

      <!-- Desktop buttons -->
      <button v-if="isSubscribed" @click="unsubscribe" class="desktop-subscribe-button btn red desktop-and-tablet-content">
        Unsubscribe
      </button>

      <button v-else @click="subscribe" class="desktop-subscribe-button btn desktop-and-tablet-content">
        Subscribe
      </button>
      
      <!-- Mobile buttons -->
      <button v-if="isSubscribed" @click="unsubscribe" class="mobile-subscribe-button btn red mobile-content">
        <img src="@/assets/svg/close-white.svg" />
      </button>

      <button v-else @click="subscribe" class="mobile-subscribe-button btn mobile-content">
        <img src="@/assets/svg/angle-right-white.svg" />
      </button>

    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'NewsletterBlock',
  data() {
    return {
      email: '',
      isReadOnly: false,
      placeholder: 'E-mail address'
    }
  },
  computed: {
    isSubscribed() {
      return this.$store.getters['newsletter/isSubscribed']();
    }
  },
  methods: {
    subscribe() {
      this.isReadOnly = true;
      this.placeholder = "Already subscribed";
  
      this.$store.dispatch("newsletter/subscribe", this.email);

      this.email = '';

      Vue.$toast.open({
        message: "You've subscribed to our newsletter!",
        type: "success",
        position: "bottom",
      });
    },
    unsubscribe() {
      this.email = '';
      this.isReadOnly = false;
      this.placeholder = "E-mail address";

      this.$store.dispatch("newsletter/unsubscribe");

      Vue.$toast.open({
        message: "You've unsubscribed from our newsletter!",
        type: "info",
        position: "bottom",
      });
    }
  }
}
</script>