<template>
  <div v-if="review && review.ad" :class="`review-item ${review.reviewType == ReviewTypes.Positive ? 'positive' : 'negative' }`">
    <div class="book-info col-9 col-m-12">
      <p :class="`description`">
        {{ review.comment }}
      </p>
      <span>
        Book:
        <span>{{ review.ad.bookTitle }}</span>
      </span>
    </div>
    <div class="date-info buyer-info col-3 col-m-12">
      <span>Ad posted {{ dateFormatter.format(new Date(review.ad.createdDate)) }}</span>
      <span class="price">A$ {{ review.ad.price.toFixed(2) }}</span>
    </div>
  </div>
</template>

<script>
import { ReviewTypes } from "@/util/constants/enums";

export default {
  name: "ReviewItem",
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ReviewTypes,
      dateFormatter: window.dateFormatter
    };
  },
};
</script>