<template>
  <div class="radio-container">
    <label
      v-for="(option, index) in options"
      :key="generatedName + index"
      class="radio"
    >
      <input
        type="radio"
        class="input--hidden"
        :value="option.value"
        :name="generatedName"
        :checked="option.value == defaultValue"
        :v-model="currentValue"
        @change="(e) => changeValue(e)"
      />
      <span class="inner-label"></span>
      <span class="option-text">{{ option.text }}</span>
    </label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "RadioField",
  data() {
    return {
      generatedName: "",
      currentValue: null
    };
  },
  created() {
    this.generatedName = uuidv4();
    this.currentValue = this.defaultValue;
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      required: false,
    },
  },
  methods: {
    changeValue(e) {
      this.$emit('change', e.target.value)
    },
  }
};
</script>