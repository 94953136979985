<template>
  <div>
    <h1 class="m-b-s">Reset your password</h1>

    <FormRow>
      <PasswordField
        label="New password"
        :defaultValue="form.newPassword.value"
        :errorMessage="form.newPassword.error"
        @change="value => set('newPassword', value, form)"
      />
    </FormRow>

    <FormRow>
      <PasswordField
        label="Repeat new password"
        :defaultValue="form.repeatNewPassword.value"
        :errorMessage="form.repeatNewPassword.error"
        @change="value => set('repeatNewPassword', value, form)"
      />
    </FormRow>

    <div class="password-tips">
      <div class="m-b-xs">
        <strong>Secure password tips:</strong>
      </div>

      <ul>
        <li>
          Use at least 8 characters, a combination of numbers and letters is
          best.
        </li>
        <li>Do not use the same password you have used with us previously.</li>
        <li>
          Do not use dictionary words, your name, email address, or other
          personal information that can be easily obtained.
        </li>
        <li>Do not use the same password for multiple online accounts.</li>
      </ul>
    </div>

    <div class="form-row flex-distributed-row full-width-button m-t-m">
      <MainCTA @click="submit" :disabled="!validate(form)"
        >Save changes</MainCTA
      >
    </div>
  </div>
</template>

<script>
import { set, validate, required, minLength, maxLength } from "vue-val";

import FormRow from "@/components/form/FormRow.vue";
import PasswordField from "@/components/form/PasswordField.vue";
import MainCTA from "@/components/form/MainCTA.vue";

export default {
  name: "ResetPasswordForm",
  components: {
    FormRow,
    PasswordField,
    MainCTA,
  },
  data() {
    return {
      form: {
        newPassword: {
          valid: false,
          error: null,
          constraints: [required, minLength(6), maxLength(320)],
        },
        repeatNewPassword: {
          valid: false,
          error: null,
          constraints: [required, minLength(6), maxLength(320)],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      this.$emit("submit", resultObject);
    },
  },
};
</script>