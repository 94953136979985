<template>
  <div v-if="user" class="tabs-wrapper">
    <ul class="tabs">
      <li>
        <a
          href="javascript:void(0);"
          class="tab"
          :class="active_tab === 0 ? 'is-active' : ''"
          @click="changeTab(0)"
          >Live Ads</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0);"
          class="tab"
          :class="active_tab === 1 ? 'is-active' : ''"
          @click="changeTab(1)"
        >
          Old Ads</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0);"
          class="tab"
          :class="active_tab === 2 ? 'is-active' : ''"
          @click="changeTab(2)"
          >Reviews</a
        >
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane" v-show="active_tab === 0">
        <div class="total-ads">
          <span>
            Number of live ads:
            <span>{{ user.activeAds.length }}</span>
          </span>
        </div>

        <SellerAdItem v-for="ad in user.activeAds" :key="ad.id" :ad="ad" />
      </div>

      <div class="tab-pane" v-show="active_tab === 1">
        <div class="total-ads">
          <span>
            Number of old ads:
            <span>{{ user.inactiveAds.length }}</span>
          </span>
        </div>

        <SellerAdItem v-for="ad in user.inactiveAds" :key="ad.id" :ad="ad" />
      </div>

      <div class="tab-pane" v-show="active_tab === 2">
        <div class="dropdown-wrapper">
          <DropdownField label="Sort items by">
            <option value="">Any</option>
            <option v-for="sortType in ReviewSortTypes" :key="sortType" :value="sortType" >{{ ReviewSortTypeMapper[sortType] }}</option>
          </DropdownField>
        </div>

        <div class="total-ads">
          <span>
            Number of reviews:
            <span>{{ user.reviews.length }}</span>
          </span>
        </div>

        <ReviewItem v-for="review in user.reviews" :key="review.id" :review="review" />
      </div>
    </div>
  </div>
</template>

<script>
import DropdownField from "@/components/form/DropdownField.vue";
import SellerAdItem from "@/components/SellerAdItem.vue";
import ReviewItem from "@/components/ReviewItem.vue";

import { ReviewSortTypes, ReviewSortTypeMapper } from "@/util/constants/enums";

export default {
  name: "Tabs",
  components: {
    DropdownField,
    SellerAdItem,
    ReviewItem,
  },
  data() {
    return {
      active_tab: 0,
      ReviewSortTypes,
      ReviewSortTypeMapper
    };
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    changeTab(tabIndexValue) {
      this.active_tab = tabIndexValue;
    },
  },
};
</script>