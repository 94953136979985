<template>
  <header class="main-header">
    <Modal v-if="isAdvancedSearchVisible" @close="hideAdvancedSearch" >
      <AdvancedSearchForm />
    </Modal>

    <div class="mobile-content">
      <MobileMainHeader />
    </div>

    <ContentLimiter>
      <div class="desktop-and-tablet-content">
        <DesktopMainHeader />
      </div>
    </ContentLimiter>
  </header>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter";
import DesktopMainHeader from "@/components/header/desktop/DesktopMainHeader";
import MobileMainHeader from "@/components/header/mobile/MobileMainHeader";
import Modal from "@/components/modal/Modal.vue";

import AdvancedSearchForm from '@/views/forms/AdvancedSearchForm.vue';

export default {
  name: "MainHeader",
  computed: {
    isAdvancedSearchVisible() {
      return this.$store.getters.isAdvancedSearchVisible();
    }
  },
  components: {
    DesktopMainHeader,
    MobileMainHeader,
    Modal,
    ContentLimiter,
    AdvancedSearchForm,
  },
  methods: {
    hideAdvancedSearch() {
      this.$store.dispatch('hideAdvancedSearch');
    }
  }
};
</script>