<template>
  <div>
    <FormRow>
      <TextField
        label="Title"
        mandatory
        :defaultValue="form.title.value"
        :errorMessage="form.title.error"
        @change="value => set('title', value, form)"
      />
    </FormRow>

    <FormRow>
      <DropdownField
        label="Forum topic"
        mandatory
        :defaultValue="form.topic.value"
        :errorMessage="form.topic.error"
        @change="(value) => set('topic', value, form)"
      >
        <option
          v-for="topic in topics"
          :key="topic.slug"
          :value="topic.slug"
        >
          {{ topic.name }}
        </option>
      </DropdownField>
    </FormRow>

    <FormRow>
      <wysiwyg v-model="form.content.value" />
    </FormRow>

    <FormRow>
      <MainCTA @click="submit" :disabled="!validate(form) || !form.content.value.length">Post</MainCTA>
    </FormRow>
  </div>
</template>

<script>
import { set, validate, required, maxLength } from "vue-val";

import MainCTA from "@/components/form/MainCTA.vue";
import FormRow from "@/components/form/FormRow.vue";
import TextField from "@/components/form/TextField.vue";
import DropdownField from "@/components/form/DropdownField.vue";

import { ForumTopics } from "@/util/constants/enums";

export default {
  name: "ForumPostForm",
  components: {
    MainCTA,
    TextField,
    DropdownField,
    FormRow,
  },
  data() {
    return {
      topics: ForumTopics,

      form: {
        title: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        topic: {
          valid: false,
          error: null,
          constraints: [required],
        },
        content: {
          value: "",
          valid: true,
          error: null,
          constraints: [],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      this.$emit("submit", resultObject);
    },
  },
};
</script>