<template>
  <div class="advanced-search-modal">
    <h2>Advanced Search</h2>

    <div class="col-container">
      <div class="col-6 col-t-12 col-m-12">
        <FormRow>
          <label class="field-label">Keywords</label>
          <input
            v-model="keywords"
            class="text-field"
            type="text"
            autocomplete="off"
            placeholder="Split by a single comma"
          />
        </FormRow>

        <FormRow>
          <label class="field-label">Format</label>

          <select v-model="format" class="dropdown-field">
            <option v-for="format in Formats" :key="format" :value="format">
              {{ FormatMapper[format] }}
            </option>
          </select>
        </FormRow>

        <FormRow>
          <label class="field-label">Book state</label>

          <select v-model="bookState" class="dropdown-field">
            <option
              v-for="bookState in BookStates"
              :key="bookState"
              :value="bookState"
            >
              {{ BookStateMapper[bookState] }}
            </option>
          </select>
        </FormRow>

        <FormRow>
          <label class="field-label">Language</label>

          <select v-model="language" class="dropdown-field">
            <option
              v-for="language in Object.keys(languages)"
              :key="language"
              :value="language"
            >
              {{ languages[language].name }}
            </option>
          </select>
        </FormRow>
      </div>
      <div class="col-6 col-t-12 col-m-12">
        <FormRow>
          <div class="col-6">
            <label class="field-label">Price from (in A$)</label>
            <input
              v-model="priceFrom"
              class="text-field"
              type="number"
              step=".01"
              min="0"
              autocomplete="off"
              placeholder="0"
            />
          </div>
          <div class="col-6">
            <label class="field-label">Price to (in A$)</label>
            <input
              v-model="priceTo"
              class="text-field"
              type="number"
              step=".01"
              min="0"
              autocomplete="off"
              placeholder="0"
            />
          </div>
        </FormRow>

        <FormRow>
          <label class="field-label">Seller state</label>

          <select v-model="state" class="dropdown-field">
            <option
              v-for="state in states"
              :key="state.abbreviation"
              :value="state.abbreviation"
            >
              {{ state.name }}
            </option>
          </select>
        </FormRow>

        <FormRow>
          <label class="field-label">Seller city</label>

          <input
            v-model="city"
            class="text-field"
            type="text"
            autocomplete="off"
          />
        </FormRow>

        <FormRow>
          <label class="field-label">Author name</label>

          <input
            v-model="authorName"
            class="text-field"
            type="text"
            autocomplete="off"
          />
        </FormRow>

        <FormRow>
          <MainCTA @click="submit">Search</MainCTA>
        </FormRow>
      </div>
    </div>
  </div>
</template>

<script>
import FormRow from "@/components/form/FormRow.vue";
import MainCTA from "@/components/form/MainCTA.vue";

import {
  Formats,
  FormatMapper,
  BookStates,
  BookStateMapper,
} from "@/util/constants/enums";
import languages from "@/util/constants/languages";
import states from "@/util/constants/australianStates";
import urlHelper from "@/util/helpers/urlHelper";

export default {
  name: "AdvancedSearchForm",
  components: {
    FormRow,
    MainCTA,
  },
  data() {
    return {
      Formats,
      FormatMapper,
      BookStates,
      BookStateMapper,
      languages,
      states,
    };
  },
  computed: {
    keywords: {
      get() {
        return this.$store.getters["search/filters"]().keywords;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.keywords = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    format: {
      get() {
        return this.$store.getters["search/filters"]().format;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.format = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    bookState: {
      get() {
        return this.$store.getters["search/filters"]().bookState;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.bookState = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    language: {
      get() {
        return this.$store.getters["search/filters"]().language;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.language = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    priceFrom: {
      get() {
        return this.$store.getters["search/filters"]().priceFrom;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.priceFrom = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    priceTo: {
      get() {
        return this.$store.getters["search/filters"]().priceTo;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.priceTo = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    state: {
      get() {
        return this.$store.getters["search/filters"]().state;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.state = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    city: {
      get() {
        return this.$store.getters["search/filters"]().city;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.city = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
    authorName: {
      get() {
        return this.$store.getters["search/filters"]().authorName;
      },
      set(value) {
        const filters = this.$store.getters["search/filters"]();

        filters.authorName = value;

        this.$store.commit("search/setFilters", filters);
      },
    },
  },
  methods: {
    submit() {
      const filters =  this.$store.getters["search/filters"]();
      
      this.$router.push(`/results?${urlHelper.toQueryString(filters)}`);

      this.$store.dispatch("hideAdvancedSearch");
    },
  },
};
</script>