<template>
  <div>
    <p class="m-b-m">
      Fields marked with <span class="mandatory">*</span> are mandatory
    </p>

    <FormRow>
      <TextField
        :defaultValue="form.referenceName.value"
        mandatory
        label="Alias"
        :errorMessage="form.referenceName.error"
        @change="(value) => set('referenceName', value, form)"
      />
    </FormRow>

    <FormRow>
      <TextField
        :defaultValue="form.fullName.value"
        mandatory
        label="Full name"
        :errorMessage="form.fullName.error"
        @change="(value) => set('fullName', value, form)"
      />
    </FormRow>

    <FormRow>
      <TextField
        :defaultValue="form.addressLine1.value"
        mandatory
        label="Address line 1"
        :errorMessage="form.addressLine1.error"
        @change="(value) => set('addressLine1', value, form)"
      />
    </FormRow>

    <FormRow>
      <TextField
        :defaultValue="form.addressLine2.value"
        label="Address line 2"
        :errorMessage="form.addressLine2.error"
        @change="(value) => set('addressLine2', value, form)"
      />
    </FormRow>

    <FormRow>
      <DropdownField
        label="State"
        mandatory
        :defaultValue="form.state.value"
        :errorMessage="form.state.error"
        @change="(value) => set('state', value, form)"
      >
        <option
          v-for="state in states"
          :key="state.abbreviation"
          :value="state.abbreviation"
        >
          {{ state.name }}
        </option>
      </DropdownField>
    </FormRow>

    <FormRow>
      <TextField
        :defaultValue="form.city.value"
        mandatory
        label="City"
        :errorMessage="form.city.error"
        @change="(value) => set('city', value, form)"
      />
    </FormRow>

    <FormRow>
      <div class="col-6 m-b-s">
        <TextField
          :defaultValue="form.postCode.value"
          mandatory
          label="Post code"
          :errorMessage="form.postCode.error"
          @change="(value) => set('postCode', value, form)"
        />
      </div>
    </FormRow>

    <FormRow>
      <span class="m-r-xxs">
        <MainCTA @click="submit" :disabled="!validate(form)">Save</MainCTA>
      </span>
      <MainCTA color="light-gray" @click="$emit('cancel')" >Cancel</MainCTA>
    </FormRow>
  </div>
</template>

<script>
import { set, validate, required, maxLength } from "vue-val";

import MainCTA from "@/components/form/MainCTA.vue";
import FormRow from "@/components/form/FormRow.vue";
import TextField from "@/components/form/TextField.vue";
import DropdownField from "@/components/form/DropdownField.vue";

import states from "@/util/constants/australianStates";

export default {
  name: "ManageAddressForm",
  components: {
    MainCTA,
    FormRow,
    TextField,
    DropdownField,
  },
  data() {
    return {
      states,

      form: {
        referenceName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        fullName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        addressLine1: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        addressLine2: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        state: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        city: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        postCode: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
      },
      set,
      validate,
    };
  },
  props: {
    address: {
      type: Object,
      required: false,
      default: null,
    }
  },
  created() {
    this.prePopulateFields();
  },
  methods: {
    prePopulateFields() {
      if (!this.address) return;

      set('referenceName', this.address.referenceName, this.form);
      set('fullName', this.address.fullName, this.form);
      set('addressLine1', this.address.addressLine1, this.form);
      set('addressLine2', this.address.addressLine2, this.form);
      set('state', this.address.country, this.form);
      set('city', this.address.city, this.form);
      set('postCode', this.address.postcode, this.form);
    },
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      this.$emit("submit", resultObject);
    },
  },
};
</script>