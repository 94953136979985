<template>
  <footer>
    <ContentLimiter>
      <div class="footer-content">
        <div>
          <router-link to="/">
            <img src="@/assets/svg/logo-white.svg" />
          </router-link>
        </div>

        <div class="mobile-footer-social-icons mobile-content">
          <a :href="socialLinks.facebook" target="_blank">
            <img src="@/assets/svg/social/facebook.svg" />
          </a>
          <a :href="socialLinks.linkedin" target="_blank">
            <img src="@/assets/svg/social/linkedin.svg" />
          </a>
          <a :href="socialLinks.twitter" target="_blank">
            <img src="@/assets/svg/social/twitter.svg" />
          </a>
          <a :href="socialLinks.pinterest" target="_blank">
            <img src="@/assets/svg/social/pinterest.svg" />
          </a>
        </div>

        <div>
          <a href="javascript:;" v-if="currentUser" @click="logOut">Sign out</a>
          <router-link v-else to="/account">Sign in / Join</router-link>

          <router-link to="/help">About Us</router-link>
          <router-link to="/forum">Forum</router-link>
        </div>
        <div>
          <router-link to="/help">Help</router-link>
          <router-link to="/contact">Contact us</router-link>
          <router-link to="/help">Where do you deliver?</router-link>
        </div>
        <div>
          <router-link to="/help">Cookies</router-link>
          <router-link to="/help">Privacy policy</router-link>
          <router-link to="/help">Terms & Conditions</router-link>
        </div>
      </div>
    </ContentLimiter>

    <div class="footer-bottom-track">
      <ContentLimiter>
        <span class="footer-copyright"
          >© 2021 Huff Books. All rights reserved.</span
        >

        <div class="desktop-footer-social-icons desktop-and-tablet-content">
          <a :href="socialLinks.facebook" target="_blank">
            <img src="@/assets/svg/social/facebook.svg" />
          </a>
          <a :href="socialLinks.linkedin" target="_blank">
            <img src="@/assets/svg/social/linkedin.svg" />
          </a>
          <a :href="socialLinks.twitter" target="_blank">
            <img src="@/assets/svg/social/twitter.svg" />
          </a>
          <a :href="socialLinks.pinterest" target="_blank">
            <img src="@/assets/svg/social/pinterest.svg" />
          </a>
        </div>
      </ContentLimiter>
    </div>
  </footer>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";

import constants from "@/util/constants";

export default {
  components: { ContentLimiter },
  name: "MainFooter",
  computed: {
    socialLinks() {
      return constants.socialLinks;
    },
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    }
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("authentication/logOut");

      window.location.reload();
    },
  },
};
</script>