<template>
  <div class="smart-upload m-b-s">
    <h2 class="primary-color-text m-t-s m-b-m">
      Create your ad with our Smart upload
    </h2>

    <p class="m-b-l">
      Huff Books Smart upload feature lets you upload books easier!<br />
      Search for books in our database and potentially pre-populate some of the
      fields for your ads.
      <br /><br />
      You can type in the name of each book you want to search for, or just take
      a picture and we'll do our best to recognize the text! Make sure that the
      text on the picture is <strong>properly rotated</strong>.
      <br /><br /><br />
      Feel free to correct the recognized text for the best possible search
      experience.
    </p>

    <div class="card-section">
      <div class="col-3 col-t-5 col-m-12 m-b-m">
        <CameraPicker @load="processImage" />
      </div>

      <div class="col-9 col-t-7 col-m-12 m-b-s">
        <div v-if="searchComplete">
          <h3 class="m-b-s">Search for these books:</h3>

          <FormRow
            v-for="(bookQuery, index) in bookQueries"
            :key="bookQuery + index"
          >
            <div class="col-9 col-t-8 col-m-8">
              <TextField
                :placeholder="`Name of the book ${index + 1}`"
                :defaultValue="bookQuery"
                @change="(value) => (bookQueries[index] = value)"
              />
            </div>
            <div class="col-3 col-t-4 col-m-4 badge-button">
              <MainCTA color="light-gray" @click="() => removeBookQuery(index)">
                <img src="@/assets/svg/close.svg" />
              </MainCTA>
            </div>
          </FormRow>

          <MainCTA
            v-if="bookQueries.length < constants.maxBooksAllowedForSearch"
            @click="addEmptyBookQuery"
            negative
            >Add a book</MainCTA
          >
        </div>

        <div
          class="flex-center"
          style="position: relative; height: 200px"
          v-else
        >
          <Spinner />
        </div>
      </div>
    </div>

    <div class="card-section">
      <MainCTA @click="searchBooks" :disabled="getNumberOfActualQueries() == 0"
        >Search books</MainCTA
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Tesseract from "tesseract.js";

import FormRow from "@/components/form/FormRow.vue";
import CameraPicker from "@/components/form/CameraPicker.vue";
import TextField from "@/components/form/TextField.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import Spinner from "@/components/Spinner.vue";

import constants from "@/util/constants";

export default {
  name: "SmartUpload",
  components: {
    CameraPicker,
    TextField,
    MainCTA,
    FormRow,
    Spinner,
  },
  data() {
    return {
      bookQueries: [""],
      constants,
      searchComplete: true,
      foundBooks: [],
    };
  },
  methods: {
    getNumberOfActualQueries() {
      return this.bookQueries.filter((e) => e.length > 0).length;
    },
    async searchBooks() {
      this.$emit("queriesSelected", this.bookQueries);
    },
    downscaleFile(imageSrc) {
      return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = function (imageEvent) {
          const canvas = document.createElement("canvas");
          const max_size = constants.cameraMaxFileSize; // TODO : pull max size from a site config.
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }

          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = imageSrc;
      });
    },
    async processImage(imageFile) {
      this.searchComplete = false;

      const downscaledBase64 = await this.downscaleFile(URL.createObjectURL(imageFile));

      try {
        const worker = Tesseract.createWorker({});

        await worker.load();
        await worker.loadLanguage("eng");
        await worker.initialize("eng");

        // alert(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        let imageBuffer = Buffer.from(downscaledBase64.split(',')[1], "base64");

        let result = await worker.recognize(imageBuffer);

        this.bookQueries = result.data.lines
          .filter((line) => line.text.length)
          .map((line) => line.text.replace(/[^\w\s!?]/g, "").trim());

        await worker.terminate();
      } catch (error) {
        console.error(error);

        Vue.$toast.open({
          message: `There was an error during image processing!`,
          type: "error",
          position: "bottom",
        });
      }

      this.searchComplete = true;
    },
    addEmptyBookQuery() {
      if (this.bookQueries.length < constants.maxBooksAllowedForSearch)
        this.bookQueries.push("");
    },
    removeBookQuery(index) {
      if (this.bookQueries.length > 1) this.bookQueries.splice(index, 1);
    },
  },
};
</script>