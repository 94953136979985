<template>
  <div class="home-hero-mobile-slider mobile-content">
    <div class="carousel-container" v-if="landingSection && landingSection.landingSection1Text">
      <VueSlickCarousel
        :draggable="true"
        :touchMove="true"
        :swipe="true"
        :variableWidth="true"
        :autoplay="true"
      >
        <div class="hero-carousel-item">
          <router-link v-if="landingSection.landingSection1Link && landingSection.landingSection1Link.length" :to="`${landingSection.landingSection1link}`" :style="getSafeBackgroundImage(landingSection.landingSection1Image)">
            <h2 v-html="landingSection.landingSection1Text" ></h2>
          </router-link>
          <div v-else :style="getSafeBackgroundImage(landingSection.landingSection1Image)">
            <h2 v-html="landingSection.landingSection1Text" ></h2>
          </div>
        </div>

        <div class="hero-carousel-item">
          <router-link v-if="landingSection.landingSection2Link && landingSection.landingSection2Link.length" :to="`${landingSection.landingSection2link}`" :style="getSafeBackgroundImage(landingSection.landingSection2Image)">
            <h2 v-html="landingSection.landingSection2Text" ></h2>
          </router-link>
          <div v-else :style="getSafeBackgroundImage(landingSection.landingSection2Image)">
            <h2 v-html="landingSection.landingSection2Text" ></h2>
          </div>
        </div>

        <div class="hero-carousel-item">
          <router-link v-if="landingSection.landingSection3Link && landingSection.landingSection3Link.length" :to="`${landingSection.landingSection3link}`" :style="getSafeBackgroundImage(landingSection.landingSection3Image)">
            <h2 v-html="landingSection.landingSection3Text" ></h2>
          </router-link>
          <div v-else :style="getSafeBackgroundImage(landingSection.landingSection3Image)">
            <h2 v-html="landingSection.landingSection3Text" ></h2>
          </div>
        </div>

        
      </VueSlickCarousel>
    </div>
  </div>
</template>


<script>
import constants from "@/util/constants";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "HomeHeroMobileSlider",
  components: {
    VueSlickCarousel,
  },
  computed: {
    landingSection() {
      return this.$store.getters['content/landingSection']();
    }
  },
  methods: {
    getSafeBackgroundImage(image) {
      return image ? `background-image: url(${constants.apiUrl}/static/${image})` : "";
    }
  }
};
</script>