import orderService from "@/services/orderService";

export default {
    namespaced: true,
    state: {
        currentOrders: null,
        currentlyUsedCardToken: null
    },
    mutations: {
        setCurrentOrders: (state, payload) => state.currentOrders = payload,
        setCurrentlyUsedCardToken: (state, payload) => state.currentlyUsedCardToken = payload,
    },
    actions: {
        createOrder: async (context, newOrder) => {
            context.commit("setCurrentlyUsedCardToken", newOrder.card);

            delete newOrder.card;

            const createdOrders = await orderService.createOrder(newOrder);

            context.commit("setCurrentOrders", createdOrders);
        },
    },
    getters: {
        currentOrders: state => () => state.currentOrders,
        currentlyUsedCardToken: state => () => state.currentlyUsedCardToken,
    }
};