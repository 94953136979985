import BaseApiClient from "./BaseApiClient";


class ForumClient extends BaseApiClient {

    filter(topicId, authorId, sortBy, sortOrder, page, postId) {
        return this.get(`/api/forum`, {}, {
            postId,
            topicId,
            authorId,

            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize: 10,
        });
    }

    getCurrentUserComments() {
        return this.get(`/api/forum/comments/my`);
    }

    createPost(topicId, title, content) {
        return this.post(`/api/forum/${topicId}`, {
            title,
            content
        });
    }

    getNewPosts(
        sortBy,
        sortOrder,
        page,
        pageSize,
    ) {
        return this.get(`/api/forum/new`, {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
        });
    }

    getTopPosts(
        sortBy,
        sortOrder,
        page,
        pageSize,
    ) {
        return this.get(`/api/forum/top`, {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
        });
    }

    getNewPostsForTopic(
        topicId,
        sortBy,
        sortOrder,
        page,
        pageSize) {
        return this.get(`/api/forum/${topicId}/new`, {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
        });
    }
    

    editPost(postId, content) {
        return this.put(`/api/forum/${postId}`, { content });
    }

    removePost(postId) {
        return this.delete(`/api/forum/${postId}`);
    }

    likePost(postId) {
        return this.post(`/api/forum/${postId}/likes`);
    }

    dislikePost(postId) {
        return this.post(`/api/forum/${postId}/unlike`);
    }


    getPostComments(postId) {
        return this.get(`/api/forum/${postId}/comments`);
    }

    createPostComment(postId, content) {
        return this.post(`/api/forum/${postId}/comments`, {
            content
        });
    }

    editComment(commentId, content) {
        return this.put(`/api/forum/comments/${commentId}`, { content });
    }

    removeComment(commentId) {
        return this.delete(`/api/forum/comments/${commentId}`);
    }

    likeComment(postId, commentId) {
        return this.post(`/api/forum/${postId}/comments/${commentId}/likes`);
    }

    dislikeComment(postId, commentId) {
        return this.post(`/api/forum/${postId}/comments/${commentId}/unlike`);
    }
}

export default new ForumClient();
