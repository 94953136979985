import profileService from "@/services/profileService";

export default {
    namespaced: true,
    state: {
        statistics: null,
        displayedSellerProfile: null,
    },
    mutations: {
        setStatistics: (state, payload) => state.statistics = payload,
        setDisplayedSellerProfile: (state, payload) => state.displayedSellerProfile = payload,
    },
    actions: {
        setStatistics: async (context) => context.commit("setStatistics", await profileService.getStatistics()),
        setDisplayedSellerProfile: async (context, sellerId) => {
            context.commit("setDisplayedSellerProfile", await profileService.getSellerById(sellerId));
        },

        updateProfileInformation: async (context, profileInformation) => {
            await profileService.updateProfileInformation(profileInformation);
        },
    },
    getters: {
        statistics: state => () => state.statistics,
        displayedSellerProfile: state => () => state.displayedSellerProfile,
    }
};