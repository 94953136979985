<template>
  <div :class="computedItemClass">
    <div class="circle"></div>

    <p class="notification-content m-b-xxs">
      <strong>{{ notification.type }}:</strong> {{ notification.content }}
    </p>

    <p class="time-posted">
      {{ dateFormatter.format(new Date(notification.createdDate)) }}
    </p>
  </div>
</template>

<script>
export default {
  name: "NotificationItem",
  data() {
    return {
      dateFormatter: window.dateFormatter,
    };
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    computedItemClass() {
      return `notification-item${this.notification.read ? "" : " unread"}`;
    },
  },
};
</script>