import watchlistService from "@/services/watchlistService";

export default {
    namespaced: true,
    state: {
        watchlistItems: []
    },
    mutations: {
        setWatchlistItems: (state, payload) => state.watchlistItems = payload,
    },
    actions: {
        setWatchlist: async (context) => context.commit("setWatchlistItems", await watchlistService.getItems()),        
        addToWatchlist: async (context, adId) => {
            await watchlistService.addToWatchlist(adId);
            context.commit("setWatchlistItems", await watchlistService.getItems())
        },
        removeFromWatchlist: async (context, adId) => {
            await watchlistService.removeFromWatchlist(adId);
            context.commit("setWatchlistItems", await watchlistService.getItems())
        },
    },
    getters: {
        watchlistItems: state => () => state.watchlistItems,
    }
};