<template>
  <ContentLimiter>
    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserInfoCard v-if="$route.params.id && displayedSeller" :user="displayedSeller" />
      <UserInfoCard v-else-if="currentUser" :user="currentUser" />

      <UserLinksCard v-if="currentUser" />
    </div>

    <div class="card col-9 col-t-8 col-m-12">
      <UserTabs v-if="$route.params.id && displayedSeller" :user="displayedSeller" />
      <UserTabs v-else-if="currentUser" :user="currentUser" />
    </div>
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import UserInfoCard from "@/components/UserInfoCard.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";
import UserTabs from "@/components/UserTabs.vue";

export default {
  name: "SellerProfile",
  components: {
    ContentLimiter,
    UserInfoCard,
    UserLinksCard,
    UserTabs
  },
  created() {
    if(this.$route.params.id) {
      this.setSellerProfile(this.$route.params.id);
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    displayedSeller() {
      return this.$store.getters["profile/displayedSellerProfile"]();
    }
  },
  methods: {
    setSellerProfile(id) {
      this.$store.dispatch("profile/setDisplayedSellerProfile", id);
    },
  }
};
</script>