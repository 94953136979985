<template>
  <div class="orange-body">
    <ContentLimiter>
      <div class="flex-center">
        <div class="card narrow">
          <ChangePasswordForm @submit="submitChangePassword" />
        </div>
      </div>
    </ContentLimiter>
  </div>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import ChangePasswordForm from "@/views/forms/ChangePasswordForm.vue";

export default {
  name: "ChangePassword",
  components: {
    ContentLimiter,
    ChangePasswordForm,
  },
  methods: {
    async submitChangePassword(data) {
      await this.$store.dispatch("authentication/changePassword", {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      });

      Vue.$toast.open({
        message: "Password changed!",
        type: "success",
        position: "bottom",
      });

      this.$router.push("/account-info");
    },
  },
};
</script>