<template>
  <ContentLimiter>
    <div class="admin-page tabs-wrapper">
      <ul class="tabs">
        <li>
          <a
            href="javascript:void(0);"
            class="tab"
            :class="active_tab === 0 ? 'is-active' : ''"
            @click="changeTab(0)"
            >Content</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            class="tab"
            :class="active_tab === 1 ? 'is-active' : ''"
            @click="changeTab(1)"
            >Orders</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            class="tab"
            :class="active_tab === 2 ? 'is-active' : ''"
            @click="changeTab(2)"
          >
            Users</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            class="tab"
            :class="active_tab === 3 ? 'is-active' : ''"
            @click="changeTab(3)"
            >Newsletter</a
          >
        </li>
      </ul>

      <div class="tab-content m-t-s">
        <div class="tab-pane card" v-show="active_tab === 0">
          <AdminContentForm
            v-if="heroSectionContent"
            :content="heroSectionContent"
            @submit="submitContentForm"
          />
        </div>

        <div class="tab-pane" v-show="active_tab === 1">
          <div class="card m-b-s">
            <TextField
              label="Search orders"
              placeholder="Search for orders by their order number"
            />

            <span class="additional-field-text">(Press enter to submit)</span>
          </div>

          <div v-if="orders && orders.length" class="card">
            <OrderWrapper
              v-for="order in orders"
              :key="order.id"
              :order="order"
            />
          </div>

          <div class="m-t-s">
            <Pagination :currentPage="1" :totalPages="26" />
          </div>
        </div>

        <div class="tab-pane" v-show="active_tab === 2">
          <div class="card m-b-s">
            <TextField
              label="Search users"
              placeholder="Search for users by their name or email"
            />

            <span class="additional-field-text">(Press enter to submit)</span>
          </div>

          <div v-if="users && users.length" class="user-list-container">
            <div
              v-for="user in users"
              :key="user.id"
              class="user-item-container col-6 col-m-12 m-b-s"
            >
              <MainCTA
                v-if="user.activated"
                color="red"
                @click="() => banUser(user.id)"
                >Ban</MainCTA
              >
              <MainCTA
                v-else
                color="red"
                negative
                @click="() => unbanUser(user.id)"
                >Unban</MainCTA
              >

              <UserInfoCard :user="user" />
            </div>
          </div>

          <div class="m-t-s">
            <Pagination :currentPage="1" :totalPages="26" />
          </div>
        </div>

        <div class="tab-pane card" v-show="active_tab === 3">
          <AdminNewsletterForm @submit="submitNewsletterForm" />
        </div>
      </div>
    </div>
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import Pagination from "@/components/Pagination.vue";
import TextField from "@/components/form/TextField.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import UserInfoCard from "@/components/UserInfoCard.vue";
import OrderWrapper from "@/components/OrderWrapper.vue";

import AdminContentForm from "@/views/forms/AdminContentForm.vue";
import AdminNewsletterForm from "@/views/forms/AdminNewsletterForm.vue";

export default {
  name: "Admin",
  components: {
    ContentLimiter,
    Pagination,
    TextField,
    MainCTA,
    UserInfoCard,
    AdminContentForm,
    AdminNewsletterForm,
    OrderWrapper,
  },
  async created() {
    await this.$store.dispatch("admin/setContent");

    this.filterUsers("");
    this.filterOrders("");
  },
  data() {
    return {
      active_tab: 0,
    };
  },
  computed: {
    heroSectionContent() {
      return this.$store.getters["admin/content"]();
    },
    users() {
      return this.$store.getters["admin/users"]();
    },
    orders() {
      return this.$store.getters["admin/orders"]();
    },
  },
  methods: {
    changeTab(tabIndexValue) {
      this.active_tab = tabIndexValue;
    },
    async submitContentForm(heroSectionContent) {
      await this.$store.dispatch("admin/updateContent", heroSectionContent);

      Vue.$toast.open({
        message: `Content saved!`,
        type: "success",
        position: "bottom",
      });
    },

    filterUsers(query) {
      this.$store.dispatch("admin/filterUsers", { query });
    },
    async banUser(userId) {
      window.$prompt(
        "Ban user",
        `Are you sure that you want to ban this user?`,
        async () => {
          await this.$store.dispatch("admin/banUser", userId);

          Vue.$toast.open({
            message: `User banned!`,
            type: "success",
            position: "bottom",
          });
        }
      );
    },
    async unbanUser(userId) {
      window.$prompt(
        "Unban user",
        `Are you sure that you want to unban this user?`,
        async () => {
          await this.$store.dispatch("admin/unbanUser", userId);

          Vue.$toast.open({
            message: `User unbanned!`,
            type: "success",
            position: "bottom",
          });
        }
      );
    },

    filterOrders(query) {
      this.$store.dispatch("admin/filterOrders", { query });
    },

    submitNewsletterForm(newsletter) {
      window.$prompt(
        "Send newsletter",
        `Are you sure that you want to send this newsletter?`,
        async () => {
          await this.$store.dispatch("admin/sendNewsletter", newsletter);

          Vue.$toast.open({
            message: `Newsletter sent!`,
            type: "success",
            position: "bottom",
          });
        }
      );
    },
  },
};
</script>