<template>
  <div class="line-item">
    <div
      class="image-wrapper col-2 col-t-2 col-m-12"
      :style="
        `background-image: url(${constants.apiUrl}/static/${ad.images[0]}.jpg);`
      "
    ></div>

    <div class="description-wrapper col-7 col-t-7 col-m-12 m-r-s">
      <router-link :to="`/ad/${ad.id}`" class="title">{{ ad.bookTitle }}</router-link>
      <span>{{ FormatMapper[ad.format] }} | {{ ad.language.toUpperCase() }} | {{ ad.authorName }}</span>
      <span v-if="ad.bookState" class="book-state">
        Book state:
        <span>{{ BookStateMapper[ad.bookState] }}</span>
      </span>
      <span class="place"
        >{{ ad.sellerCity }}, {{ ad.sellerState.toUpperCase() }}</span
      >
    </div>
    <div class="info-wrapper col-3 col-t-3 col-m-12">
      <div class="info">
        <span class="price m-b-s">A$ {{ ad.price.toFixed(2) }}</span>

        <div class="m-b-xs">
          <MainCTA
            v-if="cartAds.find((e) => e.id == ad.id)"
            @click="removeFromBasket"
            color="red"
            >Remove from basket</MainCTA
          >
          <MainCTA v-else @click="addToBasket" color="orange"
            >Add to basket</MainCTA
          >
        </div>

        <MainCTA color="light-gray" @click="removeFromWatchlist"
          >Remove</MainCTA
        >
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MainCTA from "@/components/form/MainCTA.vue";

import constants from "@/util/constants";
import { FormatMapper, BookStateMapper } from "@/util/constants/enums";

export default {
  name: "WatchlistItem",
  components: {
    MainCTA,
  },
  data() {
      return {
          constants,
          FormatMapper,
          BookStateMapper,      };
  },
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cartAds() {
      return this.$store.getters["cart/cartAds"]();
    },
  },
  methods: {
    addToBasket() {
      this.$store.dispatch("cart/addToCart", this.ad);

      Vue.$toast.open({
        message: `Item added to basket!`,
        type: "success",
        position: "bottom",
      });
    },
    removeFromBasket() {
      window.$prompt(
        "Remove from basket",
        `Are you sure that you want to remove this ad from the basket?`,
        () => {
          this.$store.dispatch("cart/removeFromCart", this.ad.id);

          Vue.$toast.open({
            message: `Item removed from basket!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
    async removeFromWatchlist() {
      window.$prompt(
        "Remove from watchlist",
        `Are you sure that you want to remove "${this.ad.bookTitle}" from the watchlist?`,
        () => {
          this.$store
            .dispatch("watchlist/removeFromWatchlist", this.ad.id)
            .then(() => {
              Vue.$toast.open({
                message: `Item removed from watchlist!`,
                type: "info",
                position: "bottom",
              });

              this.$store.dispatch("watchlist/setWatchlist");
            });
        }
      );
    },
  },
};
</script>