<template>
  <div v-if="currentUser" class="aside-block card">
    <h2 class="m-b-s" >My Profile</h2>

    <div class="link-wrapper">
      <router-link to="/profile">My Ads & Reviews</router-link>
      <router-link to="/account-info">My account</router-link>
      <router-link to="/contact-info">Contact information</router-link>
      <router-link to="/address-book">Address book</router-link>
      <router-link to="/payment-cards">Linked cards</router-link>
    </div>

    <div class="link-wrapper">
      <router-link to="/watchlist">Watchlist</router-link>
      <router-link to="/order-history">Order History</router-link>
      <router-link to="/statistics">Sales Statistics</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "UserLinksCard",
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    }
  },
};
</script>