<template>
  <div v-if="user" class="aside-block card">
    <h2 class="m-b-xs">{{ user.firstName }} {{ user.lastName }}</h2>

    <div class="profile-rating">
      <span v-if="averageRating > constants.highlyRatedSellerLimit">
        <img src="@/assets/svg/badge.svg" />
        Highly rated
      </span>
      <span>{{ user.activeAds.length + user.inactiveAds.length }} ads</span>
    </div>

    <div class="star-rating">
      <VueStarRating
        :read-only="true"
        :showRating="false"
        :starSize="16"
        :rating="averageRating"
      />
      <span>Average ({{ user.reviews.length }} reviews)</span>
    </div>

    <span
      >Posting since
      {{
        new Date(user.createdDate).toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }}</span
    >
    <span v-if="user.state">
      {{ user.state.toUpperCase() }}
      <span v-if="user.city">, {{ user.city }}</span>
    </span>
    <span v-if="user.mobile">{{ user.mobile }}</span>
    <span>{{ user.email }}</span>

    <div class="review-info">
      <div class="box positive">
        <span class="number">{{ numberOfPositiveReviews }}</span>
        <span>Positive</span>
      </div>
      <div class="box negative">
        <span class="number">{{ numberOfNegativeReviews }}</span>
        <span>Negative</span>
      </div>
      <div class="box total">
        <span class="number">{{ numberOfTotalReviews }}</span>
        <span>Total</span>
      </div>
    </div>

    <div class="star-rating">
      <VueStarRating
        :read-only="true"
        :showRating="false"
        :starSize="16"
        :rating="averageItemAsDescribedRating"
      />
      <span>Item as described</span>
    </div>
    <div class="star-rating">
      <VueStarRating
        :read-only="true"
        :showRating="false"
        :starSize="16"
        :rating="averageCommunicationRating"
      />
      <span>Communication</span>
    </div>
    <div class="star-rating">
      <VueStarRating
        :read-only="true"
        :showRating="false"
        :starSize="16"
        :rating="averageResponseTimeRating"
      />
      <span>Response time</span>
    </div>
  </div>
</template>

<script>
import VueStarRating from "vue-star-rating";

import constants from "@/util/constants";

export default {
  name: "UserInfoCard",
  components: {
    VueStarRating,
  },
  data() {
    return {
      constants
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    numberOfTotalReviews() {
      if (!this.user.reviews || !this.user.reviews.length) return 0;

      return this.user.reviews.length;
    },
    numberOfPositiveReviews() {
      if (!this.user.reviews || !this.user.reviews.length) return 0;

      return this.user.reviews
        .filter(e => e.reviewType == "POSITIVE").length;
    },
    numberOfNegativeReviews() {
      if (!this.user.reviews || !this.user.reviews.length) return 0;

      return this.user.reviews
        .filter(e => e.reviewType == "NEGATIVE").length;
    },
    averageRating() {
      if (!this.user.reviews || !this.user.reviews.length) return 0;

      return (this.averageItemAsDescribedRating + this.averageCommunicationRating + this.averageResponseTimeRating) / 3;
    },
    averageItemAsDescribedRating() {
      if (!this.user.reviews || !this.user.reviews.length) return 0;

      return this.user.reviews
        .map(e => e.itemAsDescribed)
        .reduce((a, b) => a + b) / this.user.reviews.length;
    },
    averageCommunicationRating() {
      if (!this.user.reviews || !this.user.reviews.length) return 0;

      return this.user.reviews
        .map(e => e.communication)
        .reduce((a, b) => a + b) / this.user.reviews.length;
    },
    averageResponseTimeRating() {
      if (!this.user.reviews || !this.user.reviews.length) return 0;

      return this.user.reviews
        .map(e => e.responseTime)
        .reduce((a, b) => a + b) / this.user.reviews.length;
    },
  },
};
</script>