<template>
  <ContentLimiter>
    <div class="create-ad-page">
      <div class="col-4 col-m-12 m-b-s">
        <ProfileInfoCard />
      </div>

      <div class="card col-8 col-m-12">
        <div v-if="chosenBooksAds.length">
          <div
            v-for="(ad, index) in chosenBooksAds"
            :key="index"
            class="card-section"
          >
            <h2 class="m-b-s primary-color-text">
              {{ index + 1 + "." }} {{ ad.bookTitle }}
            </h2>

            <p class="m-b-m">
              Fields marked with <span class="mandatory">*</span> are mandatory.
            </p>

            <div class="card-section">
              <ManageAdForm
                :ad="ad"
                @submit="(newAd) => createAd(newAd, index)"
                @cancel="() => removeAd(index)"
              />
            </div>
          </div>
        </div>

        <SmartUpload @queriesSelected="onQueriesSelected" v-else />
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import ProfileInfoCard from "@/components/ProfileInfoCard.vue";

import ManageAdForm from "@/views/forms/ManageAdForm.vue";
import SmartUpload from "@/components/SmartUpload.vue";

export default {
  name: "CreateAd",
  components: {
    ContentLimiter,
    NewsletterBlock,
    ProfileInfoCard,
    ManageAdForm,
    SmartUpload,
  },
  computed: {
    chosenBooksAds() {
      return this.$store.getters["ad/createAds"]();
    },
  },
  methods: {
    onQueriesSelected(books) {
      this.$store.dispatch(
        "search/setBookPickerBooksForMultipleQueries",
        books
      );
    },
    async createAd(ad, index) {
      // TODO: Check if user has the bank account linked.

      await this.$store.dispatch("ad/create", ad);

      Vue.$toast.open({
        message: `Congratulations! Your Ad is live!`,
        type: "success",
        position: "bottom",
      });

      await this.$store.dispatch(
        "ad/setCreateAds",
        this.chosenBooksAds.filter((e, i) => i != index)
      );

      this.$store.dispatch("authentication/getUserData");

      if (!this.chosenBooksAds.length) this.$router.push("/profile");
    },
    removeAd(index) {
      window.$prompt(
        "Cancel ad?",
        `Are you sure that you want to remove the form for this ad?`,
        () => {
          this.$store.dispatch(
            "ad/setCreateAds",
            this.chosenBooksAds.filter((e, i) => i != index)
          );
        }
      );
    },
  },
};
</script>