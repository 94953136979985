<template>
  <div v-if="computedShowMenu" class="mobile-menu">
    <img
      @click="closeMenu"
      class="close-mobile-menu-btn"
      src="@/assets/svg/close.svg"
    />

    <a @click="() => navigate('/')">
      <img class="mobile-menu-logo" src="@/assets/svg/logo-white.svg" />
    </a>

    <nav>
      <a @click="() => navigate('/')">Home</a>
      <a v-if="currentUser" @click="() => navigate('/profile')">Profile</a>
      <a v-else @click="() => navigate('/account')">Sign In/Join</a>
      <a @click="() => navigate('/contact')">Contact Us</a>
      <a @click="() => navigate('/help')">About Us</a>
      <a @click="() => navigate('/forum')">Forum</a>
      <a @click="() => navigate('/help')">Help</a>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  computed: {
    computedShowMenu() {
      return this.$store.getters.isMobileMenuVisible();
    },
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    }
  },
  methods: {
    navigate(route) {
      this.closeMenu();
      this.$router.push(route);
    },
    closeMenu() {
      this.$store.dispatch("hideMobileMenu");
    },
  },
};
</script>
