import jwtDecode from "jwt-decode";

import AuthClient from "../clients/AuthClient";
import SellerClient from "../clients/SellerClient";
import constants from "../util/constants";

class AuthenticationService {

    async logIn(credentials) {
        return await AuthClient.authenticate(credentials.email, credentials.password);
    }

    async register(credentials) {
        return await AuthClient.register(credentials.firstName, credentials.lastName, credentials.password, credentials.email);
    }


    async googleSignIn(googleUser) {
        // TODO: Check wc vs vc.
        if (googleUser.vc)
            return await AuthClient.googleSignIn(googleUser.vc.id_token);
        else
            return await AuthClient.googleSignIn(googleUser.wc.id_token);
    }

    facebookSignIn(facebookUser) {
        return new Promise((resolve, reject) => {
            try {
                window.facebookUser = facebookUser;

                facebookUser.FB.api("/me", { fields: "name, email" }, response => {
                    const firstAndLastNameSeparated = response.name.split(" ");

                    AuthClient.facebookSignIn(
                        firstAndLastNameSeparated[0],
                        firstAndLastNameSeparated[1],
                        response.email,
                        facebookUser.response.authResponse.accessToken
                    ).then(resolve);
                });
                
            } catch (error) {
                reject(error);
            }
        });
    }


    async getCurrentUserData() {
        const token = localStorage.getItem(constants.tokenLocalStorageKey);

        if (!token) return null;

        const tokenClaims = jwtDecode(token);

        const result = await SellerClient.getSellerInformation(tokenClaims.userId);

        result.name = `${result.firstName} ${result.lastName}`;
        result.email = tokenClaims.sub;
        result.role = tokenClaims.auth;
        result.loginType = tokenClaims.loginType;

        const allSellerAds = [...result.activeAds, ...result.inactiveAds];

        result.reviews.forEach((review, index) => {
            result.reviews[index].ad = allSellerAds.find(e => e.id == review.adId);
        });

        return result;
    }

    async sendResetEmail(email) {
        return await AuthClient.sendResetEmail(email);
    }

    async resetPassword({ key, newPassword }) {
        return await AuthClient.resetPassword(key, newPassword);
    }

    async changePassword({ oldPassword, newPassword }) {
        return await AuthClient.changePassword(oldPassword, newPassword);
    }


    async logOut() {
        localStorage.removeItem(constants.tokenLocalStorageKey);
    }


    async deactivateAccount() {
        this.logOut();

        await AuthClient.deactivateAccount();
    }

    async deleteAccount() {
        this.logOut();

        await AuthClient.deleteAccount();
    }
}

export default new AuthenticationService();