import cartService from "@/services/cartService";

export default {
    namespaced: true,
    state: {
        cartAds: []
    },
    mutations: {
        setCart: (state, payload) => state.cartAds = payload,
        addToCart: (state, payload) => state.cartAds = payload,
        removeFromCart: (state, payload) => state.cartAds = payload
    },
    actions: {
        setCart: async (context) => context.commit('setCart', await cartService.getCartFromStorage()),
        emptyCart: async (context) => {
            context.commit('setCart', []);

            cartService.saveCartToStorage([]);
        },
        addToCart: async (context, ad) => {
            const currentAds = await context.state.cartAds;

            context.commit('addToCart', [...currentAds, ad]);

            cartService.saveCartToStorage(context.state.cartAds);
        },
        removeFromCart: async (context, adId) => {
            const currentAds = await context.state.cartAds;
            
            context.commit('removeFromCart', currentAds.filter(e => e.id != adId));

            cartService.saveCartToStorage(context.state.cartAdIds);
        }
    },
    getters: {
        cartAds: state => () => state.cartAds,
    }
};