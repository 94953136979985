<template>
    <div class="content-limiter" >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ContentLimiter'
}
</script>
