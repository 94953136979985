<template>
  <ContentLimiter>
    <div class="col-container">
      <div class="desktop-and-tablet-content card col-3 col-t-4 col-m-12">
        <AdvancedSearchSideForm />
      </div>

      <div class="card col-9 col-t-8 col-m-12">
        <div class="flex-distributed-row m-b-s">
          <h2>Results:</h2>

          <div class="flex-distributed-row" style="width: fit-content">
            <div class="m-r-xs">
              <Pagination :currentPage="1" :totalPages="26" />
            </div>

            <DropdownField>
              <option
                v-for="sortType in SearchResultsSortTypes"
                :key="sortType"
                :value="sortType"
              >
                {{ SearchResultsSortTypeMapper[sortType] }}
              </option>
            </DropdownField>
          </div>
        </div>

        <div class="m-b-s">
          <AdBadge v-for="item in items" :key="item.id" :ad="item" />
        </div>

        <div class="flex-wrapped-row m-b-s">
          <span>&nbsp;</span>
          <Pagination :currentPage="1" :totalPages="26" />
        </div>
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import AdBadge from "@/components/AdBadge.vue";
import Pagination from "@/components/Pagination.vue";
import DropdownField from "@/components/form/DropdownField.vue";

import AdvancedSearchSideForm from "@/views/forms/AdvancedSearchSideForm.vue";

import {
  SearchResultsSortTypes,
  SearchResultsSortTypeMapper,
} from "@/util/constants/enums";

export default {
  name: "Results",
  components: {
    ContentLimiter,
    NewsletterBlock,
    AdBadge,
    Pagination,
    DropdownField,
    AdvancedSearchSideForm,
  },
  data() {
    return {
      SearchResultsSortTypes,
      SearchResultsSortTypeMapper,
      filters: null,
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    items() {
      return this.$store.getters["search/searchResults"]();
    },
  },
  watch: {
    $route() {
      this.search();
    },
  },
  methods: {
    search() {
      this.filters = { ...this.$route.query };

      this.$store.dispatch("search/search", this.filters);
    },
  },
};
</script>