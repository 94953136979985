<template>
  <ContentLimiter v-if="currentAd">
    <div class="top-content-container">
      <div class="col-9 col-t-8 col-m-12">
        <div
          v-if="currentAd.images.length"
          class="images-container col-5 col-t-12 col-m-12 m-b-s p-r-xs"
        >
          <BookImages
            :images="
              currentAd.images.map((e) => `${constants.apiUrl}/static/${e}.jpg`)
            "
          />
        </div>

        <div class="text-container col-7 col-t-12 col-m-12">
          <h1 class="m-b-xs flex-distributed-row">
            <span>{{ currentAd.bookTitle }}</span>
            <span v-if="currentAd.active" class="primary-color-text"
              >A$ {{ currentAd.price.toFixed(2) }}</span
            >
            <span style="opacity: 0.6;" v-else
              >A$ {{ currentAd.price.toFixed(2) }}</span
            >
          </h1>
          <div class="m-b-s">
            <u>By: {{ currentAd.authorName }}</u>
            <span> | </span>
            <u
              >Location: {{ currentAd.sellerCity }},
              {{ currentAd.sellerState.toUpperCase() }}</u
            >
          </div>

          <p v-if="!currentAd.active" class="m-b-s"><strong class="primary-color-text" >This item has been sold.</strong></p>

          <p
            v-if="currentAd.description"
            class="m-b-s"
            v-html="currentAd.description"
          ></p>

          <p v-else class="m-b-s">Description not available.</p>

          <p>Share:</p>
          <SocialShare :shareUrl="currentUrl" />
        </div>
      </div>

      <div class="author-data-container col-3 col-t-4 col-m-12">
        <div v-if="currentAd.active" class="card m-b-s">
          <div
            v-if="currentUser.id != currentAd.user.id"
            class="full-width-button m-b-xs"
          >
            <MainCTA
              v-if="cartAds.find((e) => e.id == currentAd.id)"
              @click="removeFromBasket"
              color="red"
              >Remove from basket</MainCTA
            >
            <MainCTA v-else @click="addToBasket" color="orange"
              >Add to basket</MainCTA
            >
          </div>
          <div
            v-if="currentUser.id != currentAd.user.id"
            class="full-width-button m-b-xs"
          >
            <MainCTA
              v-if="watchlistedAds.find((e) => e.id == currentAd.id)"
              @click="removeFromWatchlist"
              negative
              color="red"
            >
              Remove from watchlist
            </MainCTA>

            <MainCTA v-else @click="addToWatchlist" negative>
              <img class="m-r-xs" src="@/assets/svg/heart-hollow.svg" />
              Add to watchlist
            </MainCTA>
          </div>

          <div
            v-if="currentUser.id == currentAd.user.id && currentAd.active"
            class="full-width-button m-b-xs"
          >
            <MainCTA :href="`/edit-ad/${currentAd.id}`">Edit Ad</MainCTA>
          </div>
          <div
            v-if="currentUser.id == currentAd.user.id && currentAd.active"
            class="full-width-button m-b-xs"
          >
            <MainCTA @click="deleteAd" color="red">Delete Ad</MainCTA>
          </div>
        </div>

        <!-- TODO: Not current user! -->
        <UserInfoCard :user="currentUser" />
      </div>
    </div>

    <div class="card m-b-s">
      <h3 class="m-b-s">Product details</h3>

      <div>
        <div class="col-4 col-m-12">
          <p class="m-b-s">
            <strong>Format: </strong
            >{{
              currentAd.format
                ? FormatMapper[currentAd.format]
                : "No data available."
            }}
          </p>
          <p class="m-b-s">
            <strong>Publication date: </strong
            >{{ getSafePropertyValue(currentAd.publicationDate) }}
          </p>
          <p class="m-b-s">
            <strong>Publisher: </strong
            >{{ getSafePropertyValue(currentAd.publisher) }}
          </p>
          <p class="m-b-s">
            <strong>Language: </strong
            >{{
              currentAd.language
                ? currentAd.language.toUpperCase()
                : "No data available."
            }}
          </p>
        </div>

        <div class="col-4 col-m-12">
          <p class="m-b-s">
            <strong>Edition: </strong
            >{{ getSafePropertyValue(currentAd.edition) }}
          </p>
          <p class="m-b-s">
            <strong>ISBN: </strong>{{ getSafePropertyValue(currentAd.isbn) }}
          </p>
          <p class="m-b-s">
            <strong>ISBN13: </strong
            >{{ getSafePropertyValue(currentAd.isbn13) }}
          </p>
          <p class="m-b-s">
            <strong>Book state: </strong
            >{{
              currentAd.bookState
                ? BookStateMapper[currentAd.bookState]
                : "No data available."
            }}
          </p>
        </div>

        <div class="col-4 col-m-12">
          <p class="m-b-s">
            <strong>Cover: </strong
            >{{ getSafePropertyValue(currentAd.coverState) }}
          </p>
          <p class="m-b-s">
            <strong>Edges: </strong
            >{{ getSafePropertyValue(currentAd.edgesState) }}
          </p>
          <p class="m-b-s">
            <strong>Spine: </strong
            >{{ getSafePropertyValue(currentAd.spineState) }}
          </p>
          <p class="m-b-s">
            <strong>Page structure: </strong
            >{{ getSafePropertyValue(currentAd.pageStructureState) }}
          </p>
        </div>
      </div>
    </div>

    <div class="card m-b-s">
      <FormRow>
        <h3>Comments</h3>
      </FormRow>

      <div v-if="currentAd" class="comment-container">
        <div class="col-6 col-m-12">
          <PostCommentForm @submit="onCommentSubmit" />
        </div>

        <div v-if="currentAd.adComments && currentAd.adComments.length" class="message-container col-6 col-m-12">
          <div v-for="comment in currentAd.adComments" :key="comment.id">
            <p v-if="comment.author.id != currentAd.user.id" class="message">
              <strong
                >{{
                  `${comment.author.firstName} ${comment.author.lastName}`
                }}:</strong
              >
              {{ comment.comment }}
            </p>
            <p v-else class="seller-reply message">
              <strong>Seller:</strong> {{ comment.comment }}
            </p>

            <img
              v-if="currentUser.id == comment.author.id"
              class="close"
              title="Remove comment"
              src="@/assets/svg/close-dark.svg"
              @click="() => removeComment(comment.id)"
            />
          </div>
        </div>
      </div>
    </div>

    <RowCarousel title="Recommended for you" />
    <RowCarousel title="Other books by this seller" />

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import FormRow from "@/components/form/FormRow.vue";
import RowCarousel from "@/components/RowCarousel.vue";
import BookImages from "@/components/ad/BookImages.vue";
import SocialShare from "@/components/SocialShare.vue";
import UserInfoCard from "@/components/UserInfoCard.vue";

import PostCommentForm from "@/views/forms/PostCommentForm.vue";

import constants from "@/util/constants";
import { FormatMapper, BookStateMapper } from "@/util/constants/enums";

export default {
  name: "Ad",
  components: {
    ContentLimiter,
    NewsletterBlock,
    MainCTA,
    FormRow,
    RowCarousel,
    BookImages,
    SocialShare,
    PostCommentForm,
    UserInfoCard,
  },
  data() {
    return {
      constants,
      BookStateMapper,
      FormatMapper,
    };
  },
  computed: {
    currentAd() {
      return this.$store.getters["ad/currentAd"]();
    },
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    cartAds() {
      return this.$store.getters["cart/cartAds"]();
    },
    watchlistedAds() {
      return this.$store.getters["watchlist/watchlistItems"]();
    },
    currentUrl() {
      return encodeURIComponent(window.location.href);
    },
  },
  mounted() {
    this.setCurrentAd();
  },
  methods: {
    setCurrentAd() {
      this.$store.dispatch("ad/setCurrentAd", this.$route.params.id);
    },
    getSafePropertyValue(value) {
      return value ? value : "No data available.";
    },
    async onCommentSubmit(form) {
      await this.$store.dispatch("ad/postAdComment", {
        adId: this.currentAd.id,
        comment: form.content,
      });

      Vue.$toast.open({
        message: "Comment posted!",
        type: "success",
        position: "bottom",
      });

      this.setCurrentAd();
    },
    async removeComment(commentId) {
      window.$prompt(
        "Remove comment?",
        `Are you sure that you want to remove this comment?`,
        async () => {
          await this.$store.dispatch("ad/removeAdComment", {
            commentId,
            adId: this.currentAd.id
          });

          Vue.$toast.open({
            message: `Comment successfully removed!`,
            type: "info",
            position: "bottom",
          });

          this.setCurrentAd();
        }
      );
    },
    deleteAd() {
      window.$prompt(
        "Remove Ad?",
        `Are you sure that you want to remove this Ad?`,
        async () => {
          await this.$store.dispatch("ad/remove", this.currentAd.id);
          this.$store.commit("ad/setCurrentAd", null);

          await this.$store.dispatch("authentication/getUserData");

          this.$router.push("/profile");

          Vue.$toast.open({
            message: `Ad successfully removed!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
    addToBasket() {
      this.$store.dispatch("cart/addToCart", this.currentAd);

      Vue.$toast.open({
        message: `Item added to basket!`,
        type: "success",
        position: "bottom",
      });
    },
    removeFromBasket() {
      window.$prompt(
        "Remove from basket",
        `Are you sure that you want to remove this ad from the basket?`,
        () => {
          this.$store.dispatch("cart/removeFromCart", this.currentAd.id);

          Vue.$toast.open({
            message: `Item removed from basket!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
    async addToWatchlist() {
      await this.$store.dispatch("watchlist/addToWatchlist", this.currentAd.id);

      Vue.$toast.open({
        message: `Item added to watchlist!`,
        type: "success",
        position: "bottom",
      });
    },
    async removeFromWatchlist() {
      window.$prompt(
        "Remove from watchlist",
        `Are you sure that you want to remove "${this.currentAd.bookTitle}" from the watchlist?`,
        () => {
          this.$store
            .dispatch("watchlist/removeFromWatchlist", this.currentAd.id)
            .then(() => {
              Vue.$toast.open({
                message: `Item removed from watchlist!`,
                type: "info",
                position: "bottom",
              });
            });
        }
      );
    },
  },
  watch: {
    $route() {
      this.setCurrentAd();
    },
  },
};
</script>