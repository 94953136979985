<template>
  <div class="full-width-button m-b-s">
    <Modal v-if="newPostModalVisible" @close="newPostModalVisible = false">
      <h2 class="m-b-s m-t-xs">Create a post</h2>

      <ForumPostForm @submit="submit" />
    </Modal>

    <MainCTA @click="newPostModalVisible = true" color="gray" negative>Create a forum post</MainCTA>
  </div>
</template>

<script>
import Vue from "vue";

import MainCTA from "@/components/form/MainCTA.vue";
import Modal from "@/components/modal/Modal.vue";

import ForumPostForm from "@/views/forms/ForumPostForm.vue";

export default {
  name: "NewForumPostCTA",
  data() {
    return {
      newPostModalVisible: false,
    };
  },
  components: {
    MainCTA,
    Modal,
    ForumPostForm,
  },
  methods: {
    async submit(newPost) {
      await this.$store.dispatch("forum/createPost", {
        topicId: newPost.topic,
        title: newPost.title,
        content: newPost.content
      });
      
      Vue.$toast.open({
        message: `Post created!`,
        type: "success",
        position: "bottom",
      });

      this.$router.push("/forum");

      this.newPostModalVisible = false;
    },
  },
};
</script>