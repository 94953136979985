import Vue from "vue";

import VueAnalytics from "vue-analytics";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "vue-simple-accordion/dist/vue-simple-accordion.css";

import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

import constants from "./util/constants";

Vue.use(VueAnalytics, {
  id: constants.googleAnalyticsId,
  disabled: false // TODO: GDPR?
});

TimeAgo.addDefaultLocale(en);

window.dateFormatter = new TimeAgo('en-US');

Vue.config.productionTip = false;

Vue.use(VueToast);
Vue.use(wysiwyg, {});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
