<template>
  <div class="mobile-search-bar">
    <button class="magnifier" @click="searchBooks">
      <img src="@/assets/svg/magnifier.svg" />
    </button>

    <input
      v-model="searchQuery"
      type="text"
      placeholder="Search by keyword / title / author / ISBN"
      v-on:keyup.enter="searchBooks"
    />

    <button class="advanced-search" @click="showAdvancedSearch">
      <img src="@/assets/svg/advanced-search.svg" />
    </button>
  </div>
</template>

<script>
import urlHelper from "@/util/helpers/urlHelper";

export default {
  name: "MobileMainHeader",
  computed: {
    searchQuery: {
      get() {
        return this.$store.getters["search/searchQuery"]();
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value);
      },
    },
  },
  methods: {
    showAdvancedSearch() {
      this.$store.dispatch("showAdvancedSearch");
    },
    async searchBooks() {
      if (!this.searchQuery.length) return;

      await this.$store.dispatch("search/setBookPickerBooks", this.searchQuery);
    },
  },
};
</script>