
class URLHelper {
    toQueryString(data) {
        let parameters = [];

        for (let property in data)
            if (data.hasOwnProperty(property) && data[property])
                parameters.push(encodeURIComponent(property) + "=" + encodeURIComponent(data[property]));

        return parameters.join("&");
    }
}

export default new URLHelper();