import contentService from "@/services/contentService";

export default {
    namespaced: true,
    state: {
        landingSection: null,
        helpSection: null
    },
    mutations: {
        setLandingSection: (state, payload) => state.landingSection = payload,
        setHelpSection: (state, payload) => state.helpSection = payload,
    },
    actions: {
        setLandingSection: async (context) => context.commit('setLandingSection', await contentService.getLandingSection()),
        setHelpSection: async (context) => context.commit('setHelpSection', await contentService.getHelpSection()),
    },
    getters: {
        landingSection: state => () => state.landingSection,
        helpSection: state => () => state.helpSection,
    }
};