import Vue from "vue";
import Vuex from "vuex";

// Store modules
import ad from "@/store/modules/ad";
import address from "@/store/modules/address";
import admin from "@/store/modules/admin";
import authentication from "@/store/modules/authentication";
import author from "@/store/modules/author";
import book from "@/store/modules/book";
import cart from "@/store/modules/cart";
import checkout from "@/store/modules/checkout";
import contact from "@/store/modules/contact";
import content from "@/store/modules/content";
import forum from "@/store/modules/forum";
import newsletter from "@/store/modules/newsletter";
import notification from "@/store/modules/notification";
import ocr from "@/store/modules/ocr";
import order from "@/store/modules/order";
import profile from "@/store/modules/profile";
import review from "@/store/modules/review";
import payment from "@/store/modules/payment";
import watchlist from "@/store/modules/watchlist";
import search from "@/store/modules/search";

// Services
import subjectService from "@/services/subjectService";

// Helpers
import taskHelper from "@/util/helpers/taskHelper";
import constants from "@/util/constants";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isMobileMenuVisible: false,
    isAdvancedSearchVisible: false,
    subjects: [],
  },
  mutations: {
    showMobileMenu: (state) => state.isMobileMenuVisible = true,
    hideMobileMenu: (state) => state.isMobileMenuVisible = false,

    showAdvancedSearch: (state) => state.isAdvancedSearchVisible = true,
    hideAdvancedSearch: (state) => state.isAdvancedSearchVisible = false,

    setSubjects: (state, payload) => state.subjects = payload,
  },
  actions: {
    showMobileMenu(context) {
      context.commit('showMobileMenu');
    },
    hideMobileMenu(context) {
      context.commit('hideMobileMenu');
    },

    showAdvancedSearch(context) {
      context.commit('showAdvancedSearch');
    },
    hideAdvancedSearch(context) {
      context.commit('hideAdvancedSearch');
    },

    async setSubjects(context) {
      context.commit('setSubjects', await taskHelper.executeSafely(subjectService.getAll));
    },
  },
  modules: {
    ad,
    address,
    admin,
    authentication,
    author,
    book,
    cart,
    checkout,
    contact,
    content,
    forum,
    newsletter,
    notification,
    ocr,
    order,
    profile,
    review,
    payment,
    watchlist,
    search,
  },
  getters: {
    isMobileMenuVisible: state => () => state.isMobileMenuVisible,
    isAdvancedSearchVisible: state => () => state.isAdvancedSearchVisible,
    subjects: state => () => state.subjects,
  }
});

// Instant actions.
store.dispatch("authentication/getUserData");

store.dispatch("ad/setLatest");

if (localStorage.getItem(constants.tokenLocalStorageKey))
  store.dispatch("ad/setRecommended");

export default store;