import authenticationService from "@/services/authenticationService";

export default {
    namespaced: true,
    state: {
        currentUser: null
    },
    mutations: {
        setCurrentUser: (state, payload) => state.currentUser = payload,
    },
    actions: {
        async logIn(context, credentials) {
            await authenticationService.logIn(credentials);

            context.commit("setCurrentUser", await authenticationService.getCurrentUserData());
        },
        async register(context, credentials) {
            await authenticationService.register(credentials);
        },

        async facebookSignIn(context, credentials) {
            await authenticationService.facebookSignIn(credentials);

            context.commit("setCurrentUser", await authenticationService.getCurrentUserData());
        },

        async googleSignIn(context, credentials) {
            await authenticationService.googleSignIn(credentials);

            context.commit("setCurrentUser", await authenticationService.getCurrentUserData());
        },


        async sendResetEmail(context, email) {
            await authenticationService.sendResetEmail(email);
        },

        async resetPassword(context, keyAndNewPassword) {
            await authenticationService.resetPassword(keyAndNewPassword);
        },

        async changePassword(context, oldAndNewPassword) {
            await authenticationService.changePassword(oldAndNewPassword);
        },


        async getUserData(context) {
            context.commit("setCurrentUser", await authenticationService.getCurrentUserData());
        },
        
        async logOut(context) {
            await authenticationService.logOut();
            
            context.commit("setCurrentUser", null);
        },

        async deactivateAccount() {
            await authenticationService.deactivateAccount();
        },

        async deleteAccount() {
            await authenticationService.deleteAccount();
        }
    },
    getters: {
        currentUser: state => () => state.currentUser,
    }
};