<template>
  <div v-if="currentOrders && currentOrders.length" class="orange-body">
    <ContentLimiter>
      <div class="card m-b-s col-8 col-t-7 col-m-12">
        <h1 class="m-t-xs m-b-s">Thank you for your order!</h1>

        <p class="m-b-l">
          Please check your order details for each of the ads below. An email has been sent to your
          contact email address.
        </p>

        <div v-for="currentOrder in currentOrders" :key="currentOrder.id" >
          <h3 class="m-v-m">Order number: {{ currentOrder.orderNumber }}</h3>

          <div class="m-b-m">
            <div>
              <div v-if="currentOrder.shippingAddressLine1" class="col-4 col-m-12">
                <p class="m-b-xs"><strong>Delivery address</strong></p>
                <p class="m-b-s">
                  {{ currentOrder.fullName }}
                  <br />
                  {{ currentOrder.shippingCity }}
                  <br />
                  {{ currentOrder.shippingAddressLine1 }} {{ currentOrder.shippingState.toUpperCase() }} {{ currentOrder.shippingPostcode }}
                </p>
              </div>
              <div v-if="currentOrder.billingAddressLine1" class="col-4 col-m-12">
                <p class="m-b-xs"><strong>Billing address</strong></p>
                <p class="m-b-s">
                  {{ currentOrder.fullName }}
                  <br />
                  {{ currentOrder.billingCity }}
                  <br />
                  {{ currentOrder.billingAddressLine1 }} {{ currentOrder.billingState.toUpperCase() }} {{ currentOrder.billingPostcode }}
                </p>
              </div>
              <div v-if="currentlyUsedCardToken" class="col-4 col-m-12">
                <p class="m-b-xs"><strong>Payment</strong></p>
                <p class="m-b-s">
                  Card brand: {{ currentlyUsedCardToken.brand }}<br>Card number: **** **** **** {{ currentlyUsedCardToken.last4 }}<br>Valid to:
                  {{ currentlyUsedCardToken.exp_month }}/{{ currentlyUsedCardToken.exp_year }}
                </p>
              </div>
            </div>
          </div>

          <div class="order-items-container" >
            <PlacedOrderItem v-for="ad in currentOrder.ads" :key="ad.id" :ad="ad" />
          </div>
        </div>
      </div>

      <div class="col-4 col-t-5 col-m-12">
          <div class="m-b-s" >
            <OrderStatusCard :ads="savedBasketData" />
          </div>
          <div v-if="!currentUser" class="register-form-container card">
            <RegisterForm />
          </div>
        </div>
    </ContentLimiter>
  </div>
  <div class="flex-center" v-else>
    <p class="m-xl" >You first need to go through the checkout flow, before accessing this page.
      <br />
      <br />
      If you already have items in your basket, proceed to the <router-link to="/checkout">checkout</router-link> page.
      <br />
      If not, you can go to the <router-link to="/">home</router-link> page and search for ads.</p>
  </div>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import OrderStatusCard from "@/components/checkout/OrderStatusCard.vue";
import PlacedOrderItem from '@/components/checkout/PlacedOrderItem.vue';

import RegisterForm from '@/views/forms/RegisterForm.vue';

export default {
  name: "OrderPlaced",
  components: {
    ContentLimiter,
    OrderStatusCard,
    PlacedOrderItem,
    RegisterForm,
  },
  data() {
    return {
      savedBasketData: null
    };
  },
  created() {
    this.savedBasketData = this.$store.getters["cart/cartAds"]();

    this.$store.dispatch("cart/emptyCart");
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
    currentlyUsedCardToken() {
      return this.$store.getters["checkout/currentlyUsedCardToken"]();
    },
    currentOrders() {
      return this.$store.getters["checkout/currentOrders"]();
    },
  },
};
</script>