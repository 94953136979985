import StatisticsClient from "../clients/StatisticsClient";
import SellerClient from "../clients/SellerClient";

class ProfileService {

    async deactivateProfile() {

    }

    async deleteProfile() {

    }

    async updateProfileInformation(profileInformation) {
        return await SellerClient.updateProfileInformation(
            profileInformation.city,
            profileInformation.email,
            profileInformation.firstName,
            profileInformation.lastName,
            profileInformation.mobile,
            profileInformation.state,
        );
    }

    async getSellerById(id) {
        const result = await SellerClient.getSellerInformation(id);
        
        const allSellerAds = [ ...result.activeAds, ...result.inactiveAds ];

        result.reviews.forEach((review, index) => {
            result.reviews[index].ad = allSellerAds.find(e => e.id == review.adId);
        });
        
        return result;
    }

    async getStatistics() {
        const result = await StatisticsClient.getStatistics();

        return result;
    }
}

export default new ProfileService();