import axios from "axios";
import constants from "../util/constants";


class BaseApiClient {

    get = async (route, options = {}, params = {}) => {
        return (await axios.get(constants.apiUrl + route, {
            ...this.getDefaultOptions(),
            ...options,
            params
        })).data;
    }

    post = async (route, data = {}, options = {}) => {
        return (await axios.post(constants.apiUrl + route,
            data,
            {
                ...this.getDefaultOptions(),
                ...options
            })).data;
    }

    put = async (route, data = {}, options = {}) => {
        return (await axios.put(constants.apiUrl + route,
            data,
            {
                ...this.getDefaultOptions(),
                ...options
            })).data;
    }

    patch = async (route, data = {}, options = {}) => {
        return (await axios.patch(constants.apiUrl + route,
            data,
            {
                ...this.getDefaultOptions(),
                ...options
            })).data;
    }

    delete = async (route, options = {}) => {
        return (await axios.delete(constants.apiUrl + route,
            {
                ...this.getDefaultOptions(),
                ...options
            })).data;
    }

    getDefaultOptions() {
        return {
            headers: this.getDefaultHeaders()
        };
    }

    getDefaultHeaders() {
        const authenticationToken = this.getAuthenticationToken();

        return {
            Authorization: authenticationToken ? `Bearer ${authenticationToken}` : null
        };
    }

    getAuthenticationToken() {
        if (this.authenticationToken)
            return this.authenticationToken;

        return this.authenticationToken = localStorage.getItem(constants.tokenLocalStorageKey);
    }

    setAuthenticationToken(token) {
        localStorage.setItem(constants.tokenLocalStorageKey, token);

        return this.authenticationToken = token;
    }

}

export default BaseApiClient;
