<template>
  <div class="prompt">
    <Modal v-if="isPromptVisible" @close="hidePrompt">
      <h2 class="m-b-s">{{ promptTitle }}</h2>
      <p class="m-b-m">{{ promptMessage }}</p>

      <div class="col-6 col-m-12 m-b-xs full-width-button">
        <MainCTA color="gray" @click="onConfirm">Yes</MainCTA>
      </div>
      <div class="col-6 col-m-12 full-width-button">
        <MainCTA color="red" @click="hidePrompt">no</MainCTA>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import MainCTA from "@/components/form/MainCTA.vue";

export default {
  name: "Prompt",
  components: {
    MainCTA,
    Modal,
  },
  data() {
    return {
      isPromptVisible: false,
      promptTitle: "",
      promptMessage: "",
      onPromptConfirm: null,
    };
  },
  created() {
    window.$prompt = this.showPrompt;
  },
  methods: {
    showPrompt(promptTitle, promptMessage, onPromptConfirm) {
      this.isPromptVisible = true;
      this.promptTitle = promptTitle;
      this.promptMessage = promptMessage;
      this.onPromptConfirm = onPromptConfirm;
    },
    onConfirm() {
      this.onPromptConfirm();

      this.hidePrompt();
    },
    hidePrompt() {
      this.isPromptVisible = false;
      this.promptTitle = "";
      this.promptMessage = "";
      this.onPromptConfirm = null;
    },
  },
};
</script>