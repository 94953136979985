<template>
  <div>
    <label class="field-label" v-if="label && label.length">
      {{ label }}
      <span v-if="mandatory" class="mandatory" >*</span>
    </label>
    <input
      v-model="value"
      class="text-field"
      type="number"
      autocomplete="off"
      step=".01"
      min="0"
      :placeholder="placeholder"
      @change="$emit('change', value)"
      @keyup="$emit('change', value)"
      @keyup.enter="$emit('submit')"
    />
    <span v-if="errorMessage && errorMessage.length" class="error-message">{{
      errorMessage
    }}</span>
  </div>
</template>

<script>
export default {
  name: "NumberField",
  data: function () {
    return {
      value: "",
    };
  },
  mounted() {
    this.value = this.defaultValue || "";
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    defaultValue: {
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: false
    },
  },
};
</script>