<template>
    <router-link v-if="href" :class="computedClass" :to="href" @click="onClick" >
        <slot />
    </router-link>
    <button v-else :class="computedClass" :disabled="disabled" @click="onClick" >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'MainCTA',
    computed: {
        computedClass() {
            let classString = "btn";

            if(this.negative)
                classString += " negative";

            if (this.color)
                classString += ` ${this.color}`;

            if(this.disabled)
                classString += " disabled";

            return classString; 
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
        }
    },
    props: {
        href: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        negative: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
    }
}
</script>