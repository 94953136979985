import BaseApiClient from "./BaseApiClient";


class AddressBookClient extends BaseApiClient {

    getAddressBook() {
        return this.get("/api/addressbook");
    }

    createAddress(
        referenceName,
        fullName,
        addressLine1,
        addressLine2,
        city,
        country,
        postcode,
    ) {
        return this.post("/api/addressbook", {
            referenceName,
            fullName,
            addressLine1,
            addressLine2,
            city,
            country,
            postcode,
        });
    }

    editAddress(
        id,
        referenceName,
        fullName,
        addressLine1,
        addressLine2,
        city,
        country,
        postcode,
    ) {
        return this.post(`/api/addressbook/${id}`, {
            referenceName,
            fullName,
            addressLine1,
            addressLine2,
            city,
            country,
            postcode,
        });
    }

    deleteAddress(addressId) {
        return this.delete(`/api/addressbook/${addressId}`);
    }
}

export default new AddressBookClient();
