import BaseApiClient from "./BaseApiClient";


class StatisticsClient extends BaseApiClient {

    getStatistics() {
        return this.get(`/api/statistics`)
    }
}

export default new StatisticsClient();
