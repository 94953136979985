import paymentService from "@/services/paymentService";

export default {
    namespaced: true,
    state: {
        cards: []
    },
    mutations: {
        setCards: (state, payload) => state.cards = payload,
    },
    actions: {
        setCards: async (context) => context.commit("setCards", await paymentService.getCards()),
        create: async (context, cardToken) => await paymentService.create(cardToken),
        remove: async (context, cardId) => await paymentService.remove(cardId),
    },
    getters: {
        cards: state => () => state.cards,
    }
};