<template>
  <div v-if="ad" class="ad-badge">
    <BookBadge
      :adId="ad.id"
      :image="`${constants.apiUrl}/static/${ad.images ? ad.images[0] : 'default' }.jpg`"
      :name="ad.bookTitle"
      :authorName="ad.authorName"
      :authorSlug="ad.authorSlug"
    />

    <div class="m-v-xxs">
      <router-link class="seller" :to="`/profile/${ad.user.id}`" >{{ `${ad.user.firstName} ${ad.user.lastName}` }}</router-link>
    </div>

    <VueStarRating
      :read-only="true"
      :rating="(ad.user.itemAsDescribed + ad.user.communication + ad.user.responseTime) / 3"
      :showRating="false"
      :starSize="22"
    />

    <div>
      <span class="pill">$ {{ ad.price.toFixed(2) }}</span>
    </div>

    <MainCTA v-if="cartAds.find(e => e.id == ad.id)" @click="() => removeFromBasket(ad)" color="red" >Remove</MainCTA>
    <MainCTA v-else-if="currentUser && ad.user.id == currentUser.id" :href="`/edit-ad/${ad.id}`" negative>Edit ad</MainCTA>
    <MainCTA v-else @click="() => addToBasket(ad)">Add to basket</MainCTA>
  </div>
</template>

<script>
import Vue from "vue";
import VueStarRating from "vue-star-rating";

import BookBadge from "@/components/BookBadge.vue";
import MainCTA from "@/components/form/MainCTA.vue";

import constants from "@/util/constants";

export default {
  name: "AdBadge",
  components: {
    BookBadge,
    VueStarRating,
    MainCTA,
  },
  data() {
    return {
      constants
    };
  },
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cartAds() {
      return this.$store.getters["cart/cartAds"]();
    },
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
  },
  methods: {
    addToBasket(ad) {
      this.$store.dispatch("cart/addToCart", ad);

      Vue.$toast.open({
        message: `Item "${this.ad.bookTitle}" added to basket!`,
        type: "success",
        position: "bottom",
      });
    },
    removeFromBasket(ad) {
      window.$prompt(
        "Remove from basket",
        `Are you sure that you want to remove "${ad.bookTitle}" from the basket?`,
        () => {
          this.$store.dispatch("cart/removeFromCart", ad.id);

          Vue.$toast.open({
            message: `Item "${ad.bookTitle}" removed from basket!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
  },
};
</script>