<template>
  <div class="address-card">
    <p class="name m-b-xs" v-if="address.referenceName" >{{ address.referenceName }}</p>

    <p v-if="address" class="address-information m-b-s">
      <span v-if="address.addressLine1" >{{ address.addressLine1 }}, </span>
      <span v-if="address.addressLine2" >{{ address.addressLine2 }}, </span>
      <span v-if="address.city" >{{ address.city }}, </span>
      <span v-if="address.postcode" >{{ address.postcode }}, </span>
      <span v-if="address.country" >{{ address.country.toUpperCase() }} </span>
    </p>

    <div class="flex-distributed-row">
      <div class="m-b-xxs m-r-xxs" >
        <MainCTA color="white" @click="() => $emit('edit', address)" >Edit</MainCTA>
      </div>
      <div class="m-b-xxs">
      <MainCTA color="white" @click="() => $emit('remove', address)" >Remove</MainCTA>
      </div>
    </div>
  </div>
</template>

<script>
import MainCTA from "./form/MainCTA.vue";

export default {
  name: "AddressCard",
  components: {
    MainCTA
  },
  props: {
    address: {
      type: Object,
      required: true,
    }
  }
};
</script>