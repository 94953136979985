<template>
  <div v-if="currentPage <= totalPages" class="pagination">
    <button v-if="shouldLeftArrowAppear" @click="() => onClick(currentPage - 1)">
      ‹
    </button>

    <!--First active button-->
    <button class="active" @click="() => onClick(currentPage)">
      {{ currentPage }}
    </button>

    <!-- Other buttons -->
    <button
      v-for="i in numberOfPageButtonsToRender"
      :key="i"
      @click="() => onClick(i + currentPage)"
    >
      {{ i + currentPage }}
    </button>

    <span v-if="shouldDotsAppear" >...</span>

    <button v-if="currentPage != totalPages" @click="() => onClick(totalPages)">
      {{ totalPages }}
    </button>

    <button v-if="shouldRightArrowAppear" @click="() => onClick(currentPage + 1)">
      ›
    </button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      numberOfPageButtons: 5
    };
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onClick(number) {
      this.$emit("click", number);
    },
  },
  computed: {
    shouldLeftArrowAppear() {
      return this.currentPage > 1;
    },
    shouldRightArrowAppear() {
      return (this.currentPage + this.numberOfPageButtons) < this.totalPages;
    },
    shouldDotsAppear() {
      return this.currentPage < this.totalPages - this.numberOfPageButtons;
    },
    numberOfPageButtonsToRender() {
      if (this.currentPage == this.totalPages)
        return 0;

      if (this.currentPage > this.totalPages - this.numberOfPageButtons)
        return this.totalPages - this.currentPage - 1;

      return this.numberOfPageButtons - 1;
    }
  }
};
</script>