import BaseApiClient from "./BaseApiClient";

class WatchlistClient extends BaseApiClient {

    addToWatchlist(adId) {
        return this.post(`/api/watchlist`, { adId });
    }

    removeFromWatchlist(adId) {
        return this.delete(`/api/watchlist/${adId}`);
    }

    getWatchlist() {
        return this.get("/api/watchlist");
    }
}

export default new WatchlistClient();
