<template>
  <div class="orange-body">
    <ContentLimiter>
      <div class="flex-center">
        <div class="card narrow">
          <ResetPasswordForm @submit="submitResetPassword" />
        </div>
      </div>
    </ContentLimiter>
  </div>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import ResetPasswordForm from "@/views/forms/ResetPasswordForm.vue";

export default {
  name: "ResetPassword",
  components: {
    ContentLimiter,
    ResetPasswordForm,
  },
  methods: {
    async submitResetPassword(data) {
      await this.$store.dispatch("authentication/resetPassword", {
        key: this.$route.query.key,
        newPassword: data.newPassword
      });

      Vue.$toast.open({
        message: "Password changed! Log in to continue.",
        type: "success",
        position: "bottom",
      });

      await this.$store.dispatch("authentication/logOut");

      this.$router.push("/account");
    },
  },
};
</script>