<template>
  <ContentLimiter>
    <div class="help-container card">
      <h1 class="m-b-s">Help</h1>

      <div>
        <div class="col-9 col-t-8 col-m-12">
          <VsaList>
            <VsaItem>
              <VsaHeading>
                <div class="flex-distributed-row">
                  <span>FAQs 1</span>
                  <img src="@/assets/svg/angle-down.svg" />
                </div>
              </VsaHeading>

              <VsaContent>
                <div class="sublist-container">
                  <VsaList>
                    <VsaItem>
                      <VsaHeading>
                        <div class="flex-distributed-row">
                          <span>How much is delivery?</span>
                          <img src="@/assets/svg/angle-down.svg" />
                        </div>
                      </VsaHeading>

                      <VsaContent>
                        <div class="p-h-s p-b-s">
                          The best way to contact us is by filling in the online
                          contact form. The form is designed to help you specify
                          the problem or the query and then direct it to the
                          member of staff best suited to deal with it. We are
                          always keen to hear from you and help with any queries
                          or problems. We endeavour to answer all queries within
                          24 hours.<br /><br />We are also very happy to receive
                          your feedback and suggestions. All emails are
                          personally read and replied to by our Customer Service
                          staff. Please send your emails to
                          help@support.huffbooks.com
                        </div>
                      </VsaContent>
                    </VsaItem>

                    <VsaItem>
                      <VsaHeading>
                        <div class="flex-distributed-row">
                          <span>When will my order arrive?</span>
                          <img src="@/assets/svg/angle-down.svg" />
                        </div>
                      </VsaHeading>
                      
                      <VsaContent>
                        <div class="p-h-s p-b-s">
                          The best way to contact us is by filling in the online
                          contact form. The form is designed to help you specify
                          the problem or the query and then direct it to the
                          member of staff best suited to deal with it. We are
                          always keen to hear from you and help with any queries
                          or problems. We endeavour to answer all queries within
                          24 hours.<br /><br />We are also very happy to receive
                          your feedback and suggestions. All emails are
                          personally read and replied to by our Customer Service
                          staff. Please send your emails to
                          help@support.huffbooks.com
                        </div>
                      </VsaContent>
                    </VsaItem>
                  </VsaList>
                </div>
              </VsaContent>
            </VsaItem>

            <VsaItem>
              <VsaHeading>
                <div class="flex-distributed-row">
                  <span>FAQs 2</span>
                  <img src="@/assets/svg/angle-down.svg" />
                </div>
              </VsaHeading>

              <VsaContent>
                <div class="sublist-container">
                  <VsaList>
                    <VsaItem>
                      <VsaHeading>
                        <div class="flex-distributed-row">
                          <span>How much is delivery?</span>
                          <img src="@/assets/svg/angle-down.svg" />
                        </div>
                      </VsaHeading>

                      <VsaContent>
                        <div class="p-h-s p-b-s">
                          The best way to contact us is by filling in the online
                          contact form. The form is designed to help you specify
                          the problem or the query and then direct it to the
                          member of staff best suited to deal with it. We are
                          always keen to hear from you and help with any queries
                          or problems. We endeavour to answer all queries within
                          24 hours.<br /><br />We are also very happy to receive
                          your feedback and suggestions. All emails are
                          personally read and replied to by our Customer Service
                          staff. Please send your emails to
                          help@support.huffbooks.com
                        </div>
                      </VsaContent>
                    </VsaItem>

                    <VsaItem>
                      <VsaHeading>
                        <div class="flex-distributed-row">
                          <span>When will my order arrive?</span>
                          <img src="@/assets/svg/angle-down.svg" />
                        </div>
                      </VsaHeading>

                      <VsaContent>
                        <div class="p-h-s p-b-s">
                          The best way to contact us is by filling in the online
                          contact form. The form is designed to help you specify
                          the problem or the query and then direct it to the
                          member of staff best suited to deal with it. We are
                          always keen to hear from you and help with any queries
                          or problems. We endeavour to answer all queries within
                          24 hours.<br /><br />We are also very happy to receive
                          your feedback and suggestions. All emails are
                          personally read and replied to by our Customer Service
                          staff. Please send your emails to
                          help@support.huffbooks.com
                        </div>
                      </VsaContent>
                    </VsaItem>
                  </VsaList>
                </div>
              </VsaContent>
            </VsaItem>
          </VsaList>
        </div>

        <div class="col-3 col-t-4 col-m-12">
          <router-link to="/forum" class="forum-link"> Check out our Forum. </router-link>
        </div>
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import { VsaList, VsaItem, VsaHeading, VsaContent } from "vue-simple-accordion";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";

export default {
  name: "Help",
  components: {
    ContentLimiter,
    NewsletterBlock,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  },
  created() {
    this.$store.dispatch("content/setHelpSection");
  }
};
</script>