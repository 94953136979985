<template>
  <div class="author-badge">
    <div
      class="image"
      :style="computedStyle"
    >
    </div>
    <router-link v-if="link && link.length" :to="link" >{{ name }}</router-link>
    <a v-else href="javascript:;">{{ name }}</a>
  </div>
</template>

<script>
import constants from "@/util/constants";

export default {
  name: "AuthorBadge",
  props: {
    name: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false,
      default: null,
    },
    image: {
      type: String,
      required: false,
      default: "",
    }
  },
  computed: {
    computedStyle() {
      return this.image ? `background-image: url(${constants.apiUrl}/static/${this.image})` : "";
    }
  }
};
</script>