<template>
  <ContentLimiter>
    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserLinksCard />
    </div>

    <div class="card col-9 col-t-8 col-m-12">
      <h2 class="m-b-m">Link a card to your account</h2>

      <div class="card-section" v-if="cards && cards.length">
        <h3 class="m-b-s">Linked cards</h3>

        <p v-if="!cards || !cards.length" class="m-b-m">
          There are still no cards linked to this account.
        </p>

        <PaymentCard
          v-for="card in cards"
          :key="card.id"
          :card="card"
          @remove="removeCard"
        />
      </div>
      <div v-else class="card-section">
        <h3 class="m-b-s">Add a card</h3>
        <p class="m-b-m">
          This field is populated by
          <a href="https://stripe.com" target="_blank">Stripe</a>, the most
          popular online payment API provider. <br /><br />After inserting the
          card data, press enter to add the card to your account.
        </p>

        <stripe-element-card
          @token="createCard"
          @error="onCardError"
          :pk="constants.stripeKey"
        />
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import Vue from "vue";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";

import constants from "@/util/constants";
import PaymentCard from "../components/PaymentCard.vue";

export default {
  name: "PaymentCards",
  components: {
    ContentLimiter,
    NewsletterBlock,
    UserLinksCard,
    StripeElementCard,
    PaymentCard,
  },
  data() {
    return {
      constants,
    };
  },
  created() {
    this.$store.dispatch("payment/setCards");
  },
  computed: {
    cards() {
      return this.$store.getters["payment/cards"]();
    },
  },
  methods: {
    async createCard(cardToken) {
      await this.$store.dispatch("payment/create", cardToken.id);

      Vue.$toast.open({
        message: `Card saved!`,
        type: "error",
        position: "bottom",
      });

      this.$store.dispatch("payment/setCreditCards");
    },
    onCardError(error) {
      Vue.$toast.open({
        message: `There was an error while loading your card.`,
        type: "error",
        position: "bottom",
      });
    },
    async removeCard(cardId) {
      // if (this.cards.length < 2) {
      //   Vue.$toast.open({
      //     message: `Last card cannot be removed.`,
      //     type: "info",
      //     position: "bottom",
      //   });

      //   return;
      // }

      window.$prompt(
        "Remove card",
        `Are you sure that you want to remove this card?`,
        async () => {
          await this.$store.dispatch("payment/remove", cardId);

          Vue.$toast.open({
            message: `Card removed!`,
            type: "success",
            position: "bottom",
          });

          this.$store.dispatch("payment/setCards");
        }
      );
    },
  },
};
</script>
