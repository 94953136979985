import newsletterService from "@/services/newsletterService";

export default {
    namespaced: true,
    state: {
        isSubscribed: false,
        subscribedEmail: null
    },
    mutations: {
        subscribe: (state, email) => {
            state.isSubscribed = true;
            state.subscribedEmail = email;
        },
        unsubscribe: (state) => state.isSubscribed = false,
    },
    actions: {
        subscribe: async (context, email) => {
            await newsletterService.subscribe(email);

            context.commit('subscribe', email);
        },
        unsubscribe: async (context) => {
            await newsletterService.unsubscribe(context.state.subscribedEmail);

            context.commit('unsubscribe');
        },
    },
    getters: {
        isSubscribed: state => () => state.isSubscribed,
        subscribedEmail: state => () => state.subscribedEmail,
    }
};