<template>
  <div>
    <FormRow>
      <h1>Name, email and password</h1>
    </FormRow>

    <FormRow>
      <TextField
        label="First name"
        mandatory
        :defaultValue="form.firstName.value"
        placeholder="e.g. John"
        :errorMessage="form.firstName.error"
        @change="(value) => set('firstName', value, form)"
      />
    </FormRow>

    <FormRow>
      <TextField
        label="Last name"
        mandatory
        :defaultValue="form.lastName.value"
        placeholder="e.g. Doe"
        :errorMessage="form.lastName.error"
        @change="(value) => set('lastName', value, form)"
      />
    </FormRow>

    <FormRow>
      <TextField
        label="Email"
        mandatory
        :defaultValue="form.email.value"
        placeholder="e.g. john.doe@example.com"
        :errorMessage="form.email.error"
        @change="(value) => set('email', value, form)"
      />
    </FormRow>

    <FormRow>
      <span class="m-r-xxs">
        <MainCTA @click="submit" :disabled="!validate(form)">Save</MainCTA>
      </span>

      <MainCTA v-if="currentUser.loginType == LoginTypes.Internal" href="/change-password" negative>Change password</MainCTA>
    </FormRow>
  </div>
</template>

<script>
import { set, validate, required, isEmail, maxLength } from "vue-val";

import TextField from "@/components/form/TextField.vue";
import FormRow from "@/components/form/FormRow.vue";
import MainCTA from "@/components/form/MainCTA.vue";

import { LoginTypes } from "@/util/constants/enums";

export default {
  name: "AccountInformationForm",
  components: {
    TextField,
    FormRow,
    MainCTA,
  },
  data() {
    return {
      LoginTypes,

      form: {
        firstName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        lastName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        email: {
          valid: false,
          error: null,
          constraints: [required, isEmail, maxLength(320)],
        },
      },
      set,
      validate,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.prePopulateFields();
  },
  methods: {
    prePopulateFields() {
      if (!this.user) return;

      set('firstName', this.user.firstName, this.form);
      set('lastName', this.user.lastName, this.form);
      set('email', this.user.email, this.form);
    },
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      this.$emit("submit", resultObject);
    },
  },
};
</script>