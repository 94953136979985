<template>
  <div>
    <div class="card-section">
      <h3 class="m-b-s">1. General information</h3>
      <FormRow>
        <TextField
          label="Book name"
          mandatory
          :defaultValue="form.bookTitle.value"
          :errorMessage="form.bookTitle.error"
          @change="(value) => set('bookTitle', value, form)"
        />
      </FormRow>
      <FormRow>
        <TextField
          label="Author name"
          mandatory
          :defaultValue="form.authorName.value"
          :errorMessage="form.authorName.error"
          @change="(value) => set('authorName', value, form)"
        />
      </FormRow>
      <FormRow>
        <DropdownField
          label="Format"
          mandatory
          :defaultValue="form.format.value"
          :errorMessage="form.format.error"
          @change="(value) => set('format', value, form)"
        >
          <option v-for="format in Formats" :key="format" :value="format">
            {{ FormatMapper[format] }}
          </option>
        </DropdownField>
      </FormRow>

      <FormRow>
        <DropdownField
          label="Language"
          mandatory
          :defaultValue="form.language.value"
          :errorMessage="form.language.error"
          @change="(value) => set('language', value, form)"
        >
          <option
            v-for="language in Object.keys(languages)"
            :key="language"
            :value="language"
          >
            {{ languages[language].name }}
          </option>
        </DropdownField>
      </FormRow>

      <FormRow>
        <TextareaField
          label="Description"
          :defaultValue="form.description.value"
          :errorMessage="form.description.error"
          @change="(value) => set('description', value, form)"
        />
      </FormRow>

      <FormRow>
        <label>Keywords</label>
        <div class="m-t-xs m-b-s">
          <multiselect
            v-model="form.keywords.value"
            :options="[]"
            :taggable="true"
            :multiple="true"
            @tag="
              (value) => {
                form.keywords.value.push(value);
              }
            "
            :close-on-select="true"
          ></multiselect>
        </div>

        <span class="additional-field-text">
          Type in keyword and press enter to enter next one
        </span>
      </FormRow>

      <FormRow>
        <DateField
          label="Publication date"
          :defaultValue="form.publicationDate.value"
          :errorMessage="form.publicationDate.error"
          @change="(value) => set('publicationDate', new Date(value).toISOString().substring(0,10), form)"
        />
      </FormRow>

      <FormRow>
        <TextField
          label="Publisher"
          :defaultValue="form.publisher.value"
          :errorMessage="form.publisher.error"
          @change="(value) => set('publisher', value, form)"
        />
      </FormRow>

      <FormRow>
        <TextField
          label="Edition"
          :defaultValue="form.edition.value"
          :errorMessage="form.edition.error"
          @change="(value) => set('edition', value, form)"
        />
      </FormRow>

      <FormRow>
        <TextField
          label="ISBN"
          :defaultValue="form.isbn.value"
          :errorMessage="form.isbn.error"
          @change="(value) => set('isbn', value, form)"
        />
      </FormRow>

      <FormRow>
        <TextField
          label="ISBN13"
          :defaultValue="form.isbn13.value"
          :errorMessage="form.isbn13.error"
          @change="(value) => set('isbn13', value, form)"
        />
      </FormRow>
    </div>

    <div class="card-section">
      <h3 class="m-b-s">2. Book state</h3>

      <FormRow>
        <DropdownField
          label="Book state"
          mandatory
          :defaultValue="form.bookState.value"
          :errorMessage="form.bookState.error"
          @change="(value) => set('bookState', value, form)"
        >
          <option
            v-for="bookState in BookStates"
            :key="bookState"
            :value="bookState"
          >
            {{ BookStateMapper[bookState] }}
          </option>
        </DropdownField>
      </FormRow>

      <p class="m-b-s">
        You can choose between 5 states as it helps us place your ad and make it
        easier to filter and find.
      </p>

      <FormRow>
        <TextField
          label="Cover"
          :defaultValue="form.coverState.value"
          :errorMessage="form.coverState.error"
          @change="(value) => set('coverState', value, form)"
        />
      </FormRow>

      <p class="m-b-s">
        Please describe the condition of the cover in a few words
      </p>

      <FormRow>
        <TextField
          label="Edges"
          :defaultValue="form.edgesState.value"
          :errorMessage="form.edgesState.error"
          @change="(value) => set('edgesState', value, form)"
        />
      </FormRow>

      <p class="m-b-s">
        Please describe the condition of the edges in a few words
      </p>

      <FormRow>
        <TextField
          label="Spine"
          :defaultValue="form.spineState.value"
          :errorMessage="form.spineState.error"
          @change="(value) => set('spineState', value, form)"
        />
      </FormRow>

      <p class="m-b-s">
        Please describe the condition of the spine in a few words
      </p>

      <FormRow>
        <TextField
          label="Page structure"
          :defaultValue="form.pageStructureState.value"
          :errorMessage="form.pageStructureState.error"
          @change="(value) => set('pageStructureState', value, form)"
        />
      </FormRow>

      <p class="m-b-s">For example: Is sound with no missing pages.</p>
    </div>

    <div class="card-section">
      <h3 class="m-b-s">3. Upload images</h3>

      <p class="m-v-s">
        To change the order of the images seen just drag them from one to
        another. Maximum number of images is 4 per ad.
      </p>

      <div class="image-container">
        <div>
          <ImagePicker
            :defaultImagePath="image1Url"
            @load="(value) => set('image1', value, form)"
          />
        </div>
        <div>
          <ImagePicker
            :defaultImagePath="image2Url"
            @load="(value) => set('image2', value, form)"
          />
        </div>
        <div>
          <ImagePicker
            :defaultImagePath="image3Url"
            @load="(value) => set('image3', value, form)"
          />
        </div>
        <div>
          <ImagePicker
            :defaultImagePath="image4Url"
            @load="(value) => set('image4', value, form)"
          />
        </div>
      </div>
    </div>

    <div class="card-section">
      <h3 class="m-b-s">4. Add location</h3>

      <p class="m-v-s">
        Please add the location from which you are placing the ad.
      </p>

      <FormRow>
        <DropdownField
          label="Seller state"
          mandatory
          :defaultValue="form.sellerState.value"
          :errorMessage="form.sellerState.error"
          @change="(value) => set('sellerState', value, form)"
        >
          <option
            v-for="state in states"
            :key="state.abbreviation"
            :value="state.abbreviation"
          >
            {{ state.name }}
          </option>
        </DropdownField>
      </FormRow>

      <FormRow>
        <TextField
          label="City"
          mandatory
          :defaultValue="form.sellerCity.value"
          :errorMessage="form.sellerCity.error"
          @change="(value) => set('sellerCity', value, form)"
        />
      </FormRow>
    </div>

    <div class="card-section">
      <h3 class="m-b-s">5. Add price</h3>

      <p class="m-v-s">Please add the items price.</p>

      <FormRow>
        <div class="col-2 col-t-3 col-m-8">
          <NumberField
            label="Price (in A$)"
            mandatory
            :defaultValue="form.price.value"
            :errorMessage="form.price.error"
            @change="(value) => set('price', value, form)"
          />
        </div>
      </FormRow>
    </div>

    <div class="card-section">
      <div class="m-r-s m-b-xs" style="display: inline-block">
        <MainCTA @click="submit" :disabled="!validate(form)">Save</MainCTA>
      </div>

      <MainCTA v-if="!isFormForEdit" @click="$emit('cancel')">Cancel</MainCTA>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {
  set,
  validate,
  required,
  isValue,
  maxLength,
  isNumeric,
} from "vue-val";

import TextField from "@/components/form/TextField.vue";
import FormRow from "@/components/form/FormRow.vue";
import DropdownField from "@/components/form/DropdownField.vue";
import TextareaField from "@/components/form/TextareaField.vue";
import NumberField from "@/components/form/NumberField.vue";
import DateField from "@/components/form/DateField.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import ImagePicker from "@/components/form/ImagePicker.vue";

import {
  Formats,
  FormatMapper,
  BookStates,
  BookStateMapper,
} from "@/util/constants/enums";
import languages from "@/util/constants/languages";
import states from "@/util/constants/australianStates";

export default {
  name: "ManageAdForm",
  data() {
    return {
      Formats,
      FormatMapper,
      BookStates,
      BookStateMapper,
      languages,
      states,

      form: {
        bookTitle: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        authorName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        format: {
          valid: false,
          error: null,
          constraints: [required],
        },
        language: {
          valid: false,
          error: null,
          constraints: [required],
        },
        description: {
          valid: true,
          error: null,
          constraints: [maxLength(500)],
        },
        keywords: {
          value: [],
          valid: true,
          error: null,
          constraints: [],
        },
        publicationDate: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        publisher: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        edition: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        isbn: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        isbn13: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },

        bookState: {
          valid: false,
          error: null,
          constraints: [required],
        },
        coverState: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        edgesState: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        spineState: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        pageStructureState: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        image1: {
          valid: true,
          error: null,
          constraints: [],
        },
        image2: {
          valid: true,
          error: null,
          constraints: [],
        },
        image3: {
          valid: true,
          error: null,
          constraints: [],
        },
        image4: {
          valid: true,
          error: null,
          constraints: [],
        },
        sellerState: {
          valid: false,
          error: null,
          constraints: [required],
        },
        sellerCity: {
          valid: true,
          error: null,
          constraints: [required, maxLength(320)],
        },
        price: {
          valid: true,
          error: null,
          constraints: [required, isNumeric],
        },
      },
      set,
      validate,
      image1Url: null,
      image2Url: null,
      image3Url: null,
      image4Url: null,
    };
  },
  computed: {
    isFormForEdit() {
      // If it already has an id, it is for editing.
      return this.ad.id;
    }
  },
  created() {
    if (this.ad) {
      set("bookTitle", this.ad.bookTitle, this.form);
      set("authorName", this.ad.authorName, this.form);
      set("format", this.ad.format, this.form);
      set("language", this.ad.language, this.form);
      set("description", this.ad.description, this.form);
      set("keywords", this.ad.keywords || [], this.form);

      if (this.ad.publicationDate)
        set("publicationDate", new Date(this.ad.publicationDate).toISOString().substring(0,10), this.form);
        
      set("publisher", this.ad.publisher, this.form);
      set("edition", this.ad.edition, this.form);
      set("isbn", this.ad.isbn, this.form);
      set("isbn13", this.ad.isbn13, this.form);
      set("bookState", this.ad.bookState, this.form);
      set("coverState", this.ad.coverState, this.form);
      set("edgesState", this.ad.edgesState, this.form);
      set("spineState", this.ad.spineState, this.form);
      set("pageStructureState", this.ad.pageStructureState, this.form);

      if (this.ad.images) {
        this.ad.images.forEach((image, index) => {
          this[`image${index+1}Url`] = image;
          set(`image${index+1}`, image, this.form);
        });
      }

      set("sellerState", this.ad.sellerState, this.form);
      set("sellerCity", this.ad.sellerCity, this.form);
      set("price", this.ad.price, this.form);
    }
  },
  props: {
    ad: {
      type: Object,
      required: false,
    },
  },
  components: {
    Multiselect,
    FormRow,
    TextField,
    DropdownField,
    TextareaField,
    MainCTA,
    ImagePicker,
    DateField,
    NumberField,
  },
  methods: {
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      resultObject.bookId = this.ad.bookId;

      this.$emit("submit", resultObject);
    },
  },
};
</script>