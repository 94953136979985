<template>
  <button type="button" class="heart-button" @click="$emit('click')">
    <img v-if="checked" src="@/assets/svg/heart-full.svg" />
    <img v-else src="@/assets/svg/heart-hollow.svg" />
  </button>
</template>

<script>
export default {
  name: "HeartIcon",
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
};
</script>