import BaseApiClient from "./BaseApiClient";


class SellerClient extends BaseApiClient {

    getSellerInformation(sellerId) {
        return this.get(`/api/sellers/${sellerId}`);
    }

    createPost(topicId, title, content) {
        return this.post(`/api/forum/${topicId}`, {
            title,
            content
        });
    }

    getNewPosts(
        sortBy,
        sortOrder,
        page,
        pageSize,
    ) {
        return this.get(`/api/forum/new`, {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
        });
    }

    getTopPosts(
        sortBy,
        sortOrder,
        page,
        pageSize,
    ) {
        return this.get(`/api/forum/top`, {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
        });
    }

    getNewPostsForTopic(
        topicId,
        sortBy,
        sortOrder,
        page,
        pageSize) {
        return this.get(`/api/forum/${topicId}/new`, {}, {
            sort: `${sortBy},${sortOrder}`,
            page,
            pageSize,
        });
    }

    createPostComment(postId, content) {
        return this.post(`/api/forum/${postId}/comments`, {
            content
        });
    }

    updateProfileInformation(
        city,
        email,
        firstName,
        lastName,
        mobile,
        state,
    ) {
        return this.put(`/api/users/me`, {
            city,
            email,
            firstName,
            lastName,
            mobile,
            state,
        });
    }
}

export default new SellerClient();
