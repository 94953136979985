<template>
  <div class="desktop-header-container">
    <div class="header-content">
      <router-link to="/">
        <img class="desktop-header-logo" src="@/assets/svg/logo.svg" />
      </router-link>

      <DesktopSearchBar />

      <div class="button-container">
        <MainCTA @click="showAdvancedSearch" negative
          ><span class="m-r-xxs">Advanced</span>
          <span class="desktop-content"> search</span></MainCTA
        >
        <router-link class="btn" to="/create-ad">
          <span>Post Ad</span>
        </router-link>
      </div>
    </div>

    <div class="flex-distributed-row">
      <div class="subheader-content">
        <a class="subheader-link">
          <span>Shop by category </span>
          <img src="@/assets/svg/angle-down.svg" />

          <SubjectsMenu />
        </a>
        <a class="subheader-link" href="javascript:;" @click="navigateToBestsellers">
          Bestsellers
        </a>
        <router-link class="subheader-link" to="/forum">Our forum</router-link>
      </div>

      <CartIndicator />
    </div>

    <nav class="main-nav">
      <NotificationsButton v-if="currentUser" />

      <router-link to="/help">Help</router-link>
      <router-link to="/contact">Contact us</router-link>

      <router-link v-if="currentUser" to="/watchlist">
        <img src="@/assets/svg/heart-hollow.svg" />
        <span>Watchlist</span>
      </router-link>

      <router-link v-if="currentUser" to="/profile"> Profile </router-link>

      <a href="javascript:;" v-if="currentUser" @click="logOut">Sign out</a>
      <router-link v-else to="/account">Sign in / Join</router-link>
    </nav>
  </div>
</template>

<script>
import MainCTA from "@/components/form/MainCTA.vue";

import NotificationsButton from "@/components/notifications/NotificationsButton.vue";
import CartIndicator from "@/components/CartIndicator.vue";
import DesktopSearchBar from "@/components/header/desktop/DesktopSearchBar.vue";
import SubjectsMenu from "@/components/SubjectsMenu.vue";

export default {
  name: "DesktopMainHeader",
  components: {
    MainCTA,
    NotificationsButton,
    CartIndicator,
    DesktopSearchBar,
    SubjectsMenu,
  },
  methods: {
    showAdvancedSearch() {
      this.$store.dispatch("showAdvancedSearch");
    },
    navigateToBestsellers() {
      this.$router.push("/results?subject=33&subSubject=404");
    },
    async logOut() {
      await this.$store.dispatch("authentication/logOut");

      window.location.reload();
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["authentication/currentUser"]();
    },
  },
};
</script>