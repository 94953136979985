import ForumClient from "../clients/ForumClient";

class ForumService {
    async filter({ topicId, authorId, sortBy, sortOrder, page, postId }) {
        return await ForumClient.filter(topicId, authorId, sortBy, sortOrder, page, postId);
    }

    async getPostById(postId) {
        const post = (await this.filter({
            postId,
            sortBy: "id",
            sortOrder: "asc",
            page: 0,
        })).content[0];

        post.comments = await ForumClient.getPostComments(postId);

        return post;
    }

    async getCurrentUserComments() {
        return await ForumClient.getCurrentUserComments();
    }

    async createPost({ topicId, title, content }) {
        return await ForumClient.createPost(topicId, title, content);
    }

    async getNewPosts({
        sortBy,
        sortOrder,
        page,
        pageSize,
    }) {
        return await ForumClient.getNewPosts(
            sortBy,
            sortOrder,
            page,
            pageSize
        );
    }

    async getTopPosts({
        sortBy,
        sortOrder,
        page,
        pageSize,
    }) {
        return await ForumClient.getTopPosts(
            sortBy,
            sortOrder,
            page,
            pageSize
        );
    }

    async getNewPostsForTopic({
        topicId,
        sortBy,
        sortOrder,
        page,
        pageSize
    }) {
        return await ForumClient.getNewPostsForTopic(
            topicId,
            sortBy,
            sortOrder,
            page,
            pageSize
        );
    }

    async createPostComment({ postId, content }) {
        return await ForumClient.createPostComment(postId, content);
    }

    async editPost({ postId, content }) {
        return await ForumClient.editPost(postId, content);
    }

    async removePost(postId) {
        return await ForumClient.removePost(postId);
    }

    async likePost(postId) {
        return await ForumClient.likePost(postId);
    }

    async dislikePost(postId) {
        return await ForumClient.dislikePost(postId);
    }


    async editComment({ commentId, content }) {
        return await ForumClient.editComment(commentId, content);
    }

    async removeComment(commentId) {
        return await ForumClient.removeComment(commentId);
    }

    async likeComment({ postId, commentId }) {
        return await ForumClient.likeComment(postId, commentId);
    }

    async dislikeComment({ postId, commentId }) {
        return await ForumClient.dislikeComment(postId, commentId);
    }
}

export default new ForumService();