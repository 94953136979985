<template>
  <div v-if="cartAds" class="order-status-container card">
    <div class="flex-distributed-row m-b-s">
      <h3>Order summary</h3>
      <span class="primary-color-text">{{ cartAds.length }} item{{ cartAds.length > 1 ? "s" : "" }}</span>
    </div>

    <div v-for="ad in cartAds" :key="ad.id" class="item">
      <span class="col-10"
        >{{ ad.bookTitle }} ({{ ad.format ? FormatMapper[ad.format] : "Format not specified" }}, {{ ad.language ? ad.language.toUpperCase() : "Language not specified" }})</span
      >
      <span class="col-2">A$ {{ ad.price.toFixed(2) }}</span>
    </div>

    <div class="item">
      <strong class="col-10">Subtotal:</strong>
      <span class="col-2">A$ {{ subtotal }}</span>
    </div>

    <div class="item">
      <strong class="col-10">Fees:</strong>
      <strong class="col-2">A$ {{ transactionFee.toFixed(2) }}</strong>
    </div>

    <div class="item">
      <strong class="col-10">Total:</strong>
      <span class="col-2 primary-color-text">A$ {{ (+subtotal + transactionFee).toFixed(2) }}</span>
    </div>
  </div>
</template>

<script>
import constants from "@/util/constants";

export default {
  name: "OrderStatusCard",
  computed: {
    cartAds() {
      if (this.ads && this.ads.length)
        return this.ads;

      return this.$store.getters["cart/cartAds"]();
    },
    subtotal() {
      const cartAds = this.cartAds;

      if (!cartAds || !cartAds.length)
        return 0;
      
      return cartAds.map(e => e.price).reduce((a, b) => a + b).toFixed(2);
    },
    transactionFee() {
      const multiplicationFactor = (constants.huffbooksFeeInPercents + constants.stripeFeeInPercents) / 100;

      return this.subtotal * multiplicationFactor;
    },
  },
  props: {
    ads: {
      required: false,
      default: null
    }
  }
};
</script>