import PaymentClient from "../clients/PaymentClient";

class PaymentService {
    async getCards() {
        return await PaymentClient.getCards();
    }

    async create(cardToken) {
        return await PaymentClient.create(cardToken);
    }

    async remove(cardId) {
        return await PaymentClient.remove(cardId);
    }
}

export default new PaymentService();