<template>
  <div>
    <h1 class="m-b-s">Forgot password</h1>

    <FormRow>
      <TextField
        label="Your e-mail address"
        :defaultValue="form.email.value"
        placeholder="e.g. name@example.com"
        :errorMessage="form.email.error"
        @change="value => set('email', value, form)"
      />

      <span class="additional-field-text">You will receive an e-mail with a secret link to reset your password.
      Check your inbox!</span>
    </FormRow>

    <div class="form-row flex-distributed-row full-width-button m-t-m">
      <MainCTA @click="submit" :disabled="!validate(form)">Submit</MainCTA>
    </div>
  </div>
</template>

<script>
import {
  set,
  validate,
  required,
  isEmail,
  maxLength,
} from "vue-val";

import FormRow from "@/components/form/FormRow.vue";
import TextField from "@/components/form/TextField.vue";
import MainCTA from "@/components/form/MainCTA.vue";

export default {
  name: "ForgotPasswordForm",
  components: {
    FormRow,
    TextField,
    MainCTA,
  },
  data() {
    return {
      form: {
        email: {
          valid: false,
          error: null,
          constraints: [required, isEmail, maxLength(320)],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    submit() {
      const resultObject = {};

      Object.keys(this.form)
        .forEach(key => resultObject[key] = this.form[key].value);

      this.$emit("submit", resultObject);
    },
  }
};
</script>