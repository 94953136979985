<template>
  <Modal v-if="isPickerVisible" @close="hidePicker">
    <div v-if="!books.length" class="spinner-container">
      <Spinner />
    </div>

    <div v-else>
      <h2 class="m-t-xs m-b-s">Books that match your query:</h2>

      <p class="m-b-m" >Select the books want to include in your search.</p>

      <div class="book-container m-b-m" >
        <button
          v-for="book in books"
          :key="book.id"
          :class="chosenBooks.find(e => e.id == book.id) ? 'active' : ''"
          @click="() => toggleBook(book)"
        >
          <strong>{{ book.title }}</strong>
          <span>{{ book.author }}</span>
        </button>
      </div>

      <MainCTA @click="chooseBooks" :disabled="!chosenBooks.length" >Search Ads</MainCTA>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import Spinner from "@/components/Spinner.vue";
import MainCTA from '@/components/form/MainCTA.vue';

import urlHelper from "@/util/helpers/urlHelper";

export default {
  name: "BookPicker",
  components: {
    Modal,
    Spinner,
    MainCTA,
  },
  data() {
    return {
      chosenBooks: []
    };
  },
  computed: {
    books() {
      return this.$store.getters["search/bookPickerBooks"]();
    },
    isPickerVisible() {
      return this.$store.getters["search/isBookPickerVisible"]();
    },
  },
  methods: {
    toggleBook(book) {
      if(this.chosenBooks.find(e => e.externalId == book.externalId)) {

        this.chosenBooks.splice(this.chosenBooks.map(e => e.id).indexOf(book.id), 1);
      } else {
        this.chosenBooks.push(book);
      }
    },
    chooseBooks() {
      this.$store.dispatch("search/onBookPickerBooksChosen", this.chosenBooks);
      this.$store.dispatch("search/hideBookPicker");
    },
    hidePicker() {
      this.$store.dispatch("search/hideBookPicker");
    },
  },
};
</script>