<template>
  <div v-if="order && order.ads" class="order-wrapper">
    <OrderItem v-for="ad in order.ads" :key="ad.id" :ad="ad" />

    <div class="order-value">
      <p class="order-number m-t-s m-b-xs">
        Order number: <strong>{{ order.orderNumber }}</strong>
      </p>

      <div class="delivery-details">
        <span
          >Deliver to:<br />
          {{ order.user.firstName }} {{ order.user.lastName }}<br />
          {{ order.shippingAddressLine1 }},
          {{ order.shippingAddressLine2 || "" }}
          {{ order.shippingCity }},
          {{ order.shippingState.toUpperCase() }}
        </span>
      </div>

      <div class="order-value-item total-value m-b-s">
        <strong>Total order value: A$ {{ totalValue.toFixed(2) }}</strong>
      </div>

      <div v-if="!order.orderReview">
        <MainCTA v-if="reviewFormVisible" @click="reviewFormVisible = false"
          >Cancel</MainCTA
        >
        <MainCTA v-else @click="reviewFormVisible = true"
          >Leave a review</MainCTA
        >

        <div v-if="reviewFormVisible" class="card m-t-s">
          <ReviewForm @submit="submitReview" />
        </div>
      </div>

      <div v-else class="m-t-s">
        <h3 class="m-b-xxs" >
          Your review:
          <span
            :class="
              order.orderReview.reviewType == 'POSITIVE'
                ? 'green-text'
                : 'red-text'
            "
            >{{ order.orderReview.comment }}</span
          >
        </h3>
        <h4>
          Item as described ({{ order.orderReview.itemAsDescribed }}),
          Communication ({{ order.orderReview.communication }}),
          Response time ({{ order.orderReview.responseTime }})
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import MainCTA from "@/components/form/MainCTA.vue";
import OrderItem from "@/components/OrderItem.vue";

import ReviewForm from "@/views/forms/ReviewForm";

export default {
  name: "OrderWrapper",
  components: {
    MainCTA,
    OrderItem,
    ReviewForm,
  },
  data() {
    return {
      reviewFormVisible: false,
    };
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalValue() {
      if (!this.order || !this.order.ads) return 0;

      return this.order.ads.map((e) => e.price).reduce((a, b) => a + b);
    },
  },
  methods: {
    async submitReview(review) {
      review.orderId = this.order.id;

      await this.$store.dispatch("order/review", review);

      this.$store.dispatch("order/setHistory");
    },
  },
};
</script>
