<template>
  <div class="subjects-container">
    <div
      v-for="(section, index) in sections"
      :key="index"
      class="subject-item col-3 col-t-6 col-m-12"
    >
      <VsaList>
        <VsaItem v-for="subject in section" :key="subject.id">
          <VsaHeading>
            <span
              :class="
                searchFilters.subject == subject.id
                  ? 'subject-link primary-color-text'
                  : 'subject-link'
              "
              v-html="subject.title"
              @click="() => setSubject(subject.id)"
            ></span>
          </VsaHeading>

          <VsaContent>
            <VsaList>
              <VsaItem
                v-for="subSubject in subject.subSubjects"
                :key="subSubject.id"
              >
                <VsaHeading>
                  <span
                    :class="
                      searchFilters.subSubject == subSubject.id
                        ? 'sub-subject-link primary-color-text'
                        : 'sub-subject-link'
                    "
                    v-html="subSubject.title"
                    @click="() => setSubSubject(subSubject.id)"
                  ></span>
                </VsaHeading>

                <VsaContent>
                  <span
                    v-for="subSubSubject in subSubject.subSubSubjects"
                    :key="subSubSubject.id"
                    :class="
                      searchFilters.subSubSubject == subSubSubject.id
                        ? 'sub-sub-subject-link primary-color-text'
                        : 'sub-sub-subject-link'
                    "
                    v-html="subSubSubject.title"
                    @click="() => setSubSubSubject(subSubSubject.id)"
                  ></span>
                </VsaContent>
              </VsaItem>
            </VsaList>
          </VsaContent>
        </VsaItem>
      </VsaList>
    </div>

    <div class="subject-item"></div>
  </div>
</template>

<script>
import { VsaList, VsaItem, VsaHeading, VsaContent } from "vue-simple-accordion";
import "vue-simple-accordion/dist/vue-simple-accordion.css";

import urlHelper from "@/util/helpers/urlHelper";

export default {
  name: "SubjectsMenu",
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  },
  data() {
    return {
      sections: null,
      submitTimer: null,
    };
  },
  computed: {
    computedSections() {
      return this.sections;
    },
    searchFilters() {
      return this.$store.getters["search/filters"]();
    },
  },
  async created() {
    await this.$store.dispatch("setSubjects");

    this.setFormatedSubjects();
  },
  methods: {
    setFormatedSubjects() {
      this.sections = this.chunkify(
        this.$store.getters.subjects(),
        4
      ).filter((s) => s.length);
    },
    chunkify(a, n, balanced) {
      if (n < 2) return [a];

      var len = a.length,
        out = [],
        i = 0,
        size;

      if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
          out.push(a.slice(i, (i += size)));
        }
      } else if (balanced) {
        while (i < len) {
          size = Math.ceil((len - i) / n--);
          out.push(a.slice(i, (i += size)));
        }
      } else {
        n--;
        size = Math.floor(len / n);
        if (len % size === 0) size--;
        while (i < size * n) {
          out.push(a.slice(i, (i += size)));
        }
        out.push(a.slice(size * n));
      }

      return out;
    },
    setSubject(subjectId) {
      this.$store.dispatch("search/setFilterSubject", subjectId);
      this.$store.dispatch("search/setFilterSubSubject", null);
      this.$store.dispatch("search/setFilterSubSubSubject", null);

      this.search();
    },
    setSubSubject(subSubjectId) {
      this.$store.dispatch("search/setFilterSubSubject", subSubjectId);
      this.$store.dispatch("search/setFilterSubSubSubject", null);

      this.search();
    },
    setSubSubSubject(subSubSubjectId) {
      this.$store.dispatch("search/setFilterSubSubSubject", subSubSubjectId);

      this.search();
    },
    search() {
      if (this.submitTimer) clearTimeout(this.submitTimer);

      this.submitTimer = setTimeout(() => {
        const filters = this.$store.getters["search/filters"]();

        this.$router.push(`/results?${urlHelper.toQueryString(filters)}`);
      }, 2000);
    },
  },
  watch: {
    searchFilters() {
      this.setFormatedSubjects();
    }
  },
};
</script>