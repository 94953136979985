import BaseApiClient from "./BaseApiClient";


class OrderClient extends BaseApiClient {

    createOrder(
        shippingType, // STANDARD,EXPRESS,IN_PERSON
        email,
        fullName,
        shippingAddressLine1,
        shippingAddressLine2,
        shippingCity,
        shippingState,
        shippingPostcode,
        billingAddressLine1,
        billingAddressLine2,
        billingCity,
        billingState,
        billingPostcode,
        ads, //list of ad ids (integers),
        cardId
    ) {
        return this.post("/api/orders", {
            shippingType,
            email,
            fullName,
            shippingAddressLine1,
            shippingAddressLine2,
            shippingCity,
            shippingState,
            shippingPostcode,
            billingAddressLine1,
            billingAddressLine2,
            billingCity,
            billingState,
            billingPostcode,
            ads,
            cardId
        })
    }

    reviewOrder(
        orderId,
        itemAsDescribed,
        communication,
        responseTime,
        reviewType,
        comment
    ) {
        return this.post(`/api/orders/${orderId}/reviews`, {
            itemAsDescribed,
            communication,
            responseTime,
            reviewType,
            comment
        })
    }

    getMyOrders() {
        return this.get("/api/orders");
    }
}

export default new OrderClient();
