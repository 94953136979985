<template>
  <div class="social-share">
    <a
      :href="`http://www.facebook.com/sharer.php?href=${shareUrl}`"
      target="_blank"
    >
      <img src="@/assets/svg/social/facebook-dark.svg" />
    </a>
    <a
      :href="`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`"
      target="_blank"
    >
      <img src="@/assets/svg/social/linkedin-dark.svg" />
    </a>
    <a :href="`http://twitter.com/share?url=${shareUrl}`" target="_blank">
      <img src="@/assets/svg/social/twitter-dark.svg" />
    </a>
    <a
      :href="`http://pinterest.com/pin/create/button/?url=${shareUrl}`"
      target="_blank"
    >
      <img src="@/assets/svg/social/pinterest-dark.svg" />
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialShare",
  props: {
      shareUrl: {
          type: String,
          required: false,
          default: 'https://huffbooks.com'
      }
  }
};
</script>