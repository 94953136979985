import adminService from "@/services/adminService";

export default {
    namespaced: true,
    state: {
        content: null,
        users: [],
        orders: []
    },
    mutations: {
        setContent: (state, payload) => state.content = payload,
        setUsers: (state, payload) => state.users = payload,
        setOrders: (state, payload) => state.orders = payload,
    },
    actions: {
        setContent: async (context) => {
            context.commit("setContent", await adminService.getContent());
        },
    
        updateContent: async (context, updatedContent) => {
            await adminService.updateContent(updatedContent);
        },
    
        filterUsers: async (context, filters) => {
            context.commit("setUsers", await adminService.filterUsers(filters));
        },
    
        filterOrders: async (context, filters) => {
            context.commit("setOrders", await adminService.filterOrders(filters));
        },
    
        banUser: async (context, userId) => {
            await adminService.banUser(userId);
        },

        unbanUser: async (context, userId) => {
            await adminService.unbanUser(userId);
        },
    
        sendNewsletter: async (context, newsletter) => {
            await adminService.sendNewsletter(newsletter);
        },
    },
    getters: {
        content: state => () => state.content,
        users: state => () => state.users,
        orders: state => () => state.orders,
    }
};