import orderService from "@/services/orderService";

export default {
    namespaced: true,
    state: {
        history: null
    },
    mutations: {
        setHistory: (state, payload) => state.history = payload,
    },
    actions: {
        async setHistory(context) {
            context.commit("setHistory", await orderService.getMyOrders());
        },
        async review(context, review) {
            await orderService.reviewOrder({
                orderId: review.orderId,
                itemAsDescribed: review.itemAsDescribedRating,
                communication: review.communicationRating,
                responseTime: review.responseTimeRating,
                reviewType: review.type.toUpperCase(),
                comment: review.comment,
            });
        },
    },
    getters: {
        history: state => () => state.history,
    }
};