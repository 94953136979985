import adService from "@/services/adService";

export default {
    namespaced: true,
    state: {
        createAds: [],
        currentAd: null,
        editAd: null,

        recommended: null,
        bestsellers: null,
        childrens: null,
        educational: null,
        adsByAuthor: null,
        latest: null
    },
    mutations: {
        setCreateAds: (state, payload) => state.createAds = payload,
        setCurrentAd: (state, payload) => state.currentAd = payload,
        setEditAd: (state, payload) => state.editAd = payload,

        setRecommended: (state, payload) => state.recommended = payload,
        setBestsellers: (state, payload) => state.bestsellers = payload,
        setChildrens: (state, payload) => state.childrens = payload,
        setEducational: (state, payload) => state.educational = payload,
        setAdsByAuthor: (state, payload) => state.adsByAuthor = payload,
        setLatest: (state, payload) => state.latest = payload,
    },
    actions: {
        setCreateAds: (context, ads) => context.commit("setCreateAds", ads), 
        setCreateAdsFromBooks: async (context, books) => {
            const ads = [];
            
            books.forEach(book => {
                ads.push({
                    bookId: book.externalId,
                    bookTitle: book.title,
                    authorName: book.author,
                    authorSlug: book.authorSlug,
                    isbn10: book.isbn10,
                    isbn13: book.isbn13,
                    publisher: book.publisher,
                    edition: book.edition,
                    description: book.synopsis
                });
            });

            context.commit('setCreateAds', ads)
        },
        setCurrentAd: async (context, adId) => context.commit('setCurrentAd', await adService.getById(adId)),
        setEditAd: async (context, adId) => context.commit('setEditAd', await adService.getById(adId)),

        create: async (context, ad) => {
            await adService.createAd(ad);
        },
        edit: async (context, ad) => {
            await adService.editAd(ad);
        },
        remove: async (context, adId) => {
            await adService.removeAd(adId);
        },

        setLatest: async (context) => context.commit('setLatest', await adService.getLatest()),
        setRecommended: async (context) => context.commit('setRecommended', await adService.getRecommendedItems()),
        setBestsellers: async (context) => context.commit('setBestsellers', await adService.getBestsellers()),
        setChildrens: async (context) => context.commit('setChildrens', await adService.getChildrensBooks()),
        setEducational: async (context) => context.commit('setEducational', await adService.getEducationalBooks()),
        setAdsByAuthor: async (context, authorName) => context.commit('setAdsByAuthor', await adService.getByAuthorName(authorName)),

        postAdComment: async (context, payload) => await adService.postAdComment(payload.adId, payload.comment),
        removeAdComment: async (context, adAndCommentId) => await adService.removeAdComment(adAndCommentId),
    },
    getters: {
        createAds: state => () => state.createAds,
        currentAd: state => () => state.currentAd,
        editAd: state => () => state.editAd,

        recommended: state => () => state.recommended,
        bestsellers: state => () => state.bestsellers,
        childrens: state => () => state.childrens,
        educational: state => () => state.educational,
        adsByAuthor: state => () => state.adsByAuthor,
        latest: state => () => state.latest,
    }
};