<template>
  <div v-if="card" class="payment-card">
    <p class="name m-b-xs" v-if="card.brand">
      {{ card.brand }}
    </p>

    <p class="card-information m-b-s">
      <span v-if="card.last4">**** **** **** {{ card.last4 }}</span>
    </p>

    <div class="flex-distributed-row">
      <div class="m-b-xxs">
        <MainCTA color="white" @click="() => $emit('remove', card.cardId)"
          >Remove</MainCTA
        >
      </div>
    </div>
  </div>
</template>

<script>
import MainCTA from "./form/MainCTA.vue";

export default {
  name: "PaymentCard",
  components: {
    MainCTA,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
};
</script>