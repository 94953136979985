<template>
  <div class="dropdown-container">
    <label class="field-label" v-if="label && label.length">
      {{ label }}
      <span v-if="mandatory" class="mandatory">*</span>
    </label>

    <div class="select-wrapper">
      <select
        v-model="value"
        :readonly="readonly"
        @change="$emit('change', value)"
        class="dropdown-field"
      >
        <slot />
      </select>

      <img src="@/assets/svg/angle-down.svg" />
    </div>

    <span v-if="errorMessage && errorMessage.length" class="error-message">{{
      errorMessage
    }}</span>
  </div>
</template>

<script>
export default {
  name: "DropdownField",
  data: function () {
    return {
      value: "",
    };
  },
  mounted() {
    this.value = this.defaultValue || "";
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    defaultValue: {
      required: false,
      default: "",
    },
    errorMessage: {
      type: String,
      required: false,
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>