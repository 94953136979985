import notificationService from "@/services/notificationService";

export default {
    namespaced: true,
    state: {
        notifications: []
    },
    mutations: {
        setNotifications: (state, payload) => state.notifications = payload,
    },
    actions: {
        async setNotifications(context) {
            context.commit("setNotifications", await notificationService.getNotifications());
        },
        async markAllNotificationsForUserAsRead() {
            await notificationService.markAllNotificationsForUserAsRead();
        },
    },
    getters: {
        notifications: state => () => state.notifications,
    }
};