<template>
  <ContentLimiter>
    <div class="error-message-container" >
      <div class="status-code-container" >
        <img src="@/assets/svg/logo-icon-only.svg" />
        <span>404</span>
      </div>

      <h1>Oops!</h1>
      <h2>It seems that the page you were looking for doesn't exist (at least, not anymore).</h2>
    </div>
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";

export default {
  name: "Watchlist",
  components: {
    ContentLimiter,
  }
};
</script>