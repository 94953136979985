export default {
    apiUrl: "https://huffbooksapi.marbleit.rs",
    contactEmail: "support@huffbooks.com",
    socialLinks: {
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com",
        twitter: "https://twitter.com",
        pinterest: "https://pinterest.com",
    },
    tokenLocalStorageKey: 'auth-token',
    cartLocalStorageKey: 'cart',
    huffbooksFeeInPercents: 5,
    stripeKey: "pk_test_51JYEicHJ90Nk2ZUrCNS7cLX5JcMeD25kaN8CKF3ZxIur5vh5J8iNLwec6hNSRRPhAqgKPBOxvfwNl7UBNXQxcgDR00q0BkCxha",
    stripeFeeInPercents: 10,
    facebookAppId: "3220366454894457",
    googleAppId: "211710078624-okjvidk15bo6pmjg9fm9nf4poo5h6k7l.apps.googleusercontent.com",
    googleAnalyticsId: "UA-1234567-8",
    maxBooksAllowedForSearch: 10,
    notificationsPollingInterval: 60000,
    highlyRatedSellerLimit: 4.5,
    cameraMaxFileSize: 600,


    "Stripe": {
        "TestSecretKey": "sk_test_51I8gowI7NQxWJ2Md2zda3gsqW993uSqgNM7BuO4ISgf9cWY8dUtjLbvu2dLX6GBlCooVJB63OSIa6qrWpSsZim300052SMw92k",
        "LiveSecretKey": "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"
    },
    "Fees": {
        "ThirdPartyPercentageFee": "2.9", // Percentage
        "ThirdPartyStaticFee": "30", // In cents
        "HuffbooksFee": "8" // Percentage
    },
    "Ads": {
        "PerPage": 21
    },
    "Twilio": {
        "ApiKey": "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",
        "SenderEmail": "branko.gluvajic@marbleit.rs",
        "SenderName": "Branko Marble"
    },
    "Facebook": {
        "AppId": "1029048047609759",
        "AppSecret": "5a90f42cc61ad1ed4849bd9d50b02f5e"
    },
    "Google": {
        "ClientId": "579622156803-314blnh0q2a2u2u9kdqvnrnaj6sin6th.apps.googleusercontent.com",
        "ClientSecret": "5Y8lyfehP6Ay583xDsg-3uSR"
    }
}