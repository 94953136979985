<template>
  <div class="mobile-header-container">
    <ContentLimiter>
      <div class="header-content">
        <img
          @click="openMobileMenu"
          class="menu-burger"
          src="@/assets/svg/menu-burger.svg"
        />

        <router-link class="mobile-header-logo" to="/">
          <img src="@/assets/svg/logo-one-line.svg" />
        </router-link>

        <CartIndicator />
      </div>

      <div class="search-content">
        <MobileSearchBar />

        <button class="subjects" @click="toggleSubjectsMenu">
          <img src="@/assets/svg/subjects.svg" />
        </button>
        
        <SubjectsMenu v-if="isSubjectsMenuShown" />
      </div>
    </ContentLimiter>
  </div>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter";
import CartIndicator from "@/components/CartIndicator";
import SubjectsMenu from "@/components/SubjectsMenu";
import MobileSearchBar from '@/components/header/mobile/MobileSearchBar.vue';

export default {
  components: {
    ContentLimiter,
    CartIndicator,
    SubjectsMenu,
    MobileSearchBar,
  },
  data() {
    return {
      searchQuery: "",
      isSubjectsMenuShown: false,
    };
  },
  name: "MobileMainHeader",
  methods: {
    openMobileMenu() {
      this.$store.dispatch("showMobileMenu");
    },
    toggleSubjectsMenu() {
      this.isSubjectsMenuShown = !this.isSubjectsMenuShown;
    }
  },
};
</script>