<template>
  <ContentLimiter>
    <Modal
      v-if="newAddressModalVisible"
      @close="() => (newAddressModalVisible = false)"
    >
      <h1 class="m-b-s">Add a new address</h1>

      <ManageAddressForm
        @submit="createAddress"
        @cancel="() => (newAddressModalVisible = false)"
      />
    </Modal>

    <Modal
      v-if="editAddressModalVisible"
      @close="() => (editAddressModalVisible = false)"
    >
      <h1 class="m-b-s">Edit address</h1>

      <ManageAddressForm
        :address="editedAddress"
        @submit="
          (updatedAddress) => editAddress(updatedAddress, editedAddress.id)
        "
        @cancel="() => (editAddressModalVisible = false)"
      />
    </Modal>

    <div class="col-3 col-t-4 col-m-12 m-b-s">
      <UserLinksCard />
    </div>
    <div class="card col-9 col-t-8 col-m-12">
      <h1 class="m-b-m">Address book</h1>

      <p v-if="addresses" class="m-b-m">
        You have {{ addresses.length }} addresses in your address book.
      </p>

      <div
        v-if="addresses && addresses.length"
        class="address-card-container card-section"
      >
        <AddressCard
          v-for="address in addresses"
          :key="address.id"
          :address="address"
          @edit="setEditAddress"
          @remove="deleteAddress"
        />
      </div>

      <div class="card-section m-b-s">
        <MainCTA @click="() => (newAddressModalVisible = true)">
          Add a new address
        </MainCTA>
      </div>
    </div>

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import Vue from "vue";

import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import UserLinksCard from "@/components/UserLinksCard.vue";
import AddressCard from "@/components/AddressCard.vue";
import MainCTA from "@/components/form/MainCTA.vue";
import Modal from "@/components/modal/Modal.vue";

import ManageAddressForm from "@/views/forms/ManageAddressForm.vue";

export default {
  name: "Contact",
  components: {
    ContentLimiter,
    NewsletterBlock,
    UserLinksCard,
    AddressCard,
    MainCTA,
    Modal,
    ManageAddressForm,
  },
  data() {
    return {
      newAddressModalVisible: false,
      editAddressModalVisible: false,
    };
  },
  created() {
    this.$store.dispatch("address/setCurrentUserAddresses");
  },
  computed: {
    addresses() {
      return this.$store.getters["address/currentUserAddresses"]();
    },
    editedAddress() {
      return this.$store.getters["address/editedAddress"]();
    },
  },
  methods: {
    createAddress(address) {
      this.$store.dispatch("address/createAddress", address);
      this.newAddressModalVisible = false;

      Vue.$toast.open({
        message: `Address created!`,
        type: "success",
        position: "bottom",
      });
    },
    setEditAddress(address) {
      this.editAddressModalVisible = true;
      this.$store.dispatch("address/setEditedAddress", address);
    },
    editAddress(updatedAddress, id) {
      updatedAddress.id = id;

      this.$store.dispatch("address/editAddress", updatedAddress);
      this.editAddressModalVisible = false;

      Vue.$toast.open({
        message: `Address updated!`,
        type: "success",
        position: "bottom",
      });
    },
    deleteAddress(address) {
      window.$prompt(
        "Remove address?",
        `Are you sure that you want to remove this address?`,
        () => {
          this.$store.dispatch("address/deleteAddress", address);

          Vue.$toast.open({
            message: `Address removed!`,
            type: "info",
            position: "bottom",
          });
        }
      );
    },
  },
};
</script>