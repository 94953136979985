<template>
  <div>
    <div class="card-section">
      <h3 class="m-b-m">Add a review</h3>

      <div class="m-b-m">
        <span class="m-r-xs">
          <MainCTA
            color="green"
            :negative="!(form.type.value == 'positive')"
            @click="() => changeRatingType('positive')"
            >Positive</MainCTA
          >
        </span>
        <MainCTA
          color="red"
          :negative="!(form.type.value == 'negative')"
          @click="() => changeRatingType('negative')"
          >Negative</MainCTA
        >
      </div>

      <FormRow>
        <span>Item as described</span>
        <VueStarRating
          @click="() => set('itemAsDescribedRating', form.itemAsDescribedRating.value, form)"
          v-model="form.itemAsDescribedRating.value"
          :showRating="false"
          :starSize="16"
        />
      </FormRow>
      <FormRow>
        <span>Communication</span>
        <VueStarRating
          @click="() => set('communicationRating', form.communicationRating.value, form)"
          v-model="form.communicationRating.value"
          :showRating="false"
          :starSize="16"
        />
      </FormRow>
      <FormRow>
        <span>Response time</span>
        <VueStarRating
          @click="() => set('responseTimeRating', form.responseTimeRating.value, form)"
          v-model="form.responseTimeRating.value"
          :showRating="false"
          :starSize="16"
        />
      </FormRow>

      <FormRow>
        <TextareaField
          :defaultValue="form.comment.value"
          placeholder="Leave your comment"
          :errorMessage="form.comment.error"
          @change="(value) => set('comment', value, form)"
        />
      </FormRow>
    </div>

    <div class="form-row flex-distributed-row full-width-button">
      <MainCTA @click="submit" :disabled="!validate(form)">Submit</MainCTA>
    </div>
  </div>
</template>

<script>
import { set, validate, required } from "vue-val";
import VueStarRating from "vue-star-rating";

import FormRow from "@/components/form/FormRow.vue";
import TextareaField from "@/components/form/TextareaField.vue";
import MainCTA from "@/components/form/MainCTA.vue";

export default {
  name: "ReviewForm",
  components: {
    FormRow,
    TextareaField,
    MainCTA,
    VueStarRating,
  },
  data() {
    return {
      form: {
        type: {
          valid: false,
          error: null,
          constraints: [required],
        },
        itemAsDescribedRating: {
          value: 1,
          valid: true,
          error: null,
          constraints: [],
        },
        communicationRating: {
          value: 1,
          valid: true,
          error: null,
          constraints: [],
        },
        responseTimeRating: {
          value: 1,
          valid: true,
          error: null,
          constraints: [],
        },
        comment: {
          valid: true,
          error: null,
          constraints: [],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    changeRatingType(type) {
      this.set('type', type, this.form);

      this.form = { ...this.form };
    },
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      this.$emit("submit", resultObject);
    },
  },
};
</script>