<template>
  <ContentLimiter>
    <HomeHeroDesktop />
    <HomeHeroMobileSlider />

    <AuthorContainerDesktop />
    <AuthorCarouselMobile />

    <RowCarousel
      title="Latest Ads"
      :items="$store.getters['ad/latest']()"
      />
    <RowCarousel
      v-if="$store.getters['authentication/currentUser']()"
      title="Recommended for you"
      :items="$store.getters['ad/recommended']()"
      />
    <RowCarousel
      title="Bestsellers"
      :items="$store.getters['ad/bestsellers']()"
      />
    <RowCarousel
      title="Children's books"
      :items="$store.getters['ad/childrens']()"
      />
    <RowCarousel
      title="Educational"
      :items="$store.getters['ad/educational']()"
      />

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import RowCarousel from '@/components/RowCarousel.vue';
import AuthorContainerDesktop from '@/components/AuthorContainerDesktop.vue';
import AuthorCarouselMobile from '@/components/AuthorCarouselMobile.vue';
import HomeHeroDesktop from '@/components/HomeHeroDesktop.vue';
import HomeHeroMobileSlider from '@/components/HomeHeroMobileSlider.vue';
import NewsletterBlock from '@/components/NewsletterBlock.vue';

import adService from "@/services/adService";

import constants from "@/util/constants";

export default {
  name: "Home",
  data() {
    return {
      adService
    };
  },
  async created() {
    this.$store.dispatch("content/setLandingSection");

    if (localStorage.getItem(constants.tokenLocalStorageKey))
      this.$store.dispatch("ad/setRecommended");

    this.$store.dispatch("ad/setBestsellers");
    this.$store.dispatch("ad/setChildrens");
    this.$store.dispatch("ad/setEducational");
  },
  components: {
    ContentLimiter,
    RowCarousel,
    AuthorContainerDesktop,
    AuthorCarouselMobile,
    HomeHeroDesktop,
    HomeHeroMobileSlider,
    NewsletterBlock,
  }
};
</script>