<template>
  <div v-if="landingSection && landingSection.author1Text" class="author-container">
    <AuthorBadge
      :name="landingSection.author1Text"
      :image="landingSection.author1Image"
      :link="landingSection.author1Link"
    />
    <AuthorBadge
      :name="landingSection.author2Text"
      :image="landingSection.author2Image"
      :link="landingSection.author2Link"
    />
    <AuthorBadge
      :name="landingSection.author3Text"
      :image="landingSection.author3Image"
      :link="landingSection.author3Link"
    />
    <AuthorBadge
      :name="landingSection.author4Text"
      :image="landingSection.author4Image"
      :link="landingSection.author4Link"
    />
    <AuthorBadge
      :name="landingSection.author5Text"
      :image="landingSection.author5Image"
      :link="landingSection.author5Link"
    />
    <AuthorBadge
      :name="landingSection.author6Text"
      :image="landingSection.author6Image"
      :link="landingSection.author6Link"
    />
  </div>
</template>

<script>
import AuthorBadge from "@/components/AuthorBadge.vue";

export default {
  name: "AuthorContainerDesktop",
  components: {
    AuthorBadge,
  },
  computed: {
    landingSection() {
      return this.$store.getters["content/landingSection"]();
    },
  },
};
</script>