<template>
  <ContentLimiter>
    <div class="basket-wrapper">
      <div class="col-3 col-t-4 col-m-12 m-b-s">
        <UserBasketCard />
      </div>

      <div class="card basket-card-block col-9 col-t-8 col-m-12 m-b-m">
        <h3 class="m-b-m">Shopping basket details</h3>

        <p class="m-b-m" v-if="!items.length">Your basket is empty. You can go back to the <router-link to="/">Home</router-link> page to add items to your basket.</p>
        <BasketItem v-for="(item, index) in items" :key="index" :ad="item" />

        <PaymentBlock v-if="items.length" />
      </div>
    </div>

    <RowCarousel
      v-if="$store.getters['authentication/currentUser']()"
      title="Recommended for you"
      :items="$store.getters['ad/recommended']()"
      />
    <RowCarousel
      v-else
      title="Latest Ads"
      :items="$store.getters['ad/latest']()"
      />

    <NewsletterBlock />
  </ContentLimiter>
</template>

<script>
import ContentLimiter from "@/components/ContentLimiter.vue";
import NewsletterBlock from "@/components/NewsletterBlock.vue";
import UserBasketCard from "@/components/UserBasketCard.vue";
import BasketItem from "@/components/BasketItem.vue";
import PaymentBlock from "@/components/PaymentBlock.vue";
import RowCarousel from "@/components/RowCarousel.vue";

import adService from "@/services/adService";

import constants from "@/util/constants";

export default {
  name: "Basket",
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getLatestAdData();

    if (localStorage.getItem(constants.tokenLocalStorageKey))
      this.$store.dispatch("ad/setRecommended");
  },
  computed: {
    cartAds() {
      return this.$store.getters["cart/cartAds"]();
    },
  },
  methods: {
    async getLatestAdData() {
      this.items = [];

      const ads = await this.$store.getters["cart/cartAds"]();

      ads.forEach((ad) =>
        adService
          .getById(ad.id)
          .then((retrievedAdObject) => this.items.push(retrievedAdObject))
      );
    },
  },
  watch: {
    cartAds() {
      this.getLatestAdData();
    },
  },
  components: {
    ContentLimiter,
    NewsletterBlock,
    UserBasketCard,
    BasketItem,
    PaymentBlock,
    RowCarousel,
  },
};
</script>