<template>
    <div v-if="images" class="book-images-container" >
      <div class="thumbnail-container" >
        <div class="thumbnail" v-for="(image, i) in images" :key="i" @click="index = i" :style="`background-image: url(${image});`" ></div>

        <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
      </div>

      <div @click="index = 0" class="current-image-container" :style="`background-image: url(${images[0]});`" ></div>
    </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  name: 'BookImages',
  data() {
    return {
      index: null
    };
  },
  props: {
    images: {
      type: Array,
      required: false
    }
  },
  components: {
    VueGallerySlideshow,
  }
}
</script>
