<template>
  <div>
    <h2 class="m-b-m">Please, fill in your details for this order:</h2>

    <div class="card-section">
      <FormRow>
        <TextField
          label="E-mail"
          placeholder="We need this to send you an order confirmation"
          mandatory
          :defaultValue="form.email.value"
          :errorMessage="form.email.error"
          @change="(value) => set('email', value, form)"
        />
      </FormRow>
    </div>

    <div class="card-section">
      <h3 class="m-b-m">1. Shipping</h3>

      <RadioField
        :defaultValue="form.shippingType.value"
        :options="
          Object.keys(ShippingTypes).map((key) => {
            return {
              text: ShippingTypeMapper[ShippingTypes[key]],
              value: ShippingTypes[key],
            };
          })
        "
        @change="changeShippingType"
      />

      <span class="shipping-description">
        <strong>Standard shipping</strong>: You propose that sellers send you
        ordered books via standard courier. They might contact you if that is
        not possible.
        <br />
        <br />
        <strong>No shipping / In person</strong>: You and the seller have agreed
        to meet in person for the transaction. You are only paying for the item.
      </span>
    </div>

    <div
      class="card-section"
      v-if="form.shippingType.value == ShippingTypes.Shipping"
    >
      <div class="card-section">
        <h3 class="m-b-m">2. Delivery address</h3>

        <FormRow>
          <AddressPicker @select="preFillDeliveryAddress" />
        </FormRow>
        
        <div :key="shippingFormKey">
        <FormRow>
          <TextField
            label="Full name"
            placeholder="First and last name. For example: John Smith"
            mandatory
            :defaultValue="form.deliveryAddressFullName.value"
            :errorMessage="form.deliveryAddressFullName.error"
            @change="(value) => set('deliveryAddressFullName', value, form)"
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Address line 1"
            placeholder="Street address, PO box, company name, c/o"
            mandatory
            :defaultValue="form.deliveryAddressLine1.value"
            :errorMessage="form.deliveryAddressLine1.error"
            @change="(value) => set('deliveryAddressLine1', value, form)"
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Address line 2"
            placeholder="e.g. apartment, suite, unit, building, floor"
            :defaultValue="form.deliveryAddressLine2.value"
            :errorMessage="form.deliveryAddressLine2.error"
            @change="(value) => set('deliveryAddressLine2', value, form)"
          />
        </FormRow>
        <FormRow>
          <DropdownField
            label="State"
            mandatory
            :defaultValue="form.deliveryAddressState.value"
            :errorMessage="form.deliveryAddressState.error"
            @change="(value) => set('deliveryAddressState', value, form)"
          >
            <option
              v-for="state in states"
              :key="state.abbreviation"
              :value="state.abbreviation"
            >
              {{ state.name }}
            </option>
          </DropdownField>
        </FormRow>
        <FormRow>
          <TextField
            :defaultValue="form.deliveryAddressCity.value"
            mandatory
            label="City"
            :errorMessage="form.deliveryAddressCity.error"
            @change="(value) => set('deliveryAddressCity', value, form)"
          />
        </FormRow>
        <FormRow>
          <div class="col-6">
            <TextField
              label="Postcode"
              placeholder="If you don't have a postcode or ZIP, please write 'No Postcode'"
              :defaultValue="form.deliveryAddressPostCode.value"
              mandatory
              :errorMessage="form.deliveryAddressPostCode.error"
              @change="(value) => set('deliveryAddressPostCode', value, form)"
            />
          </div>
        </FormRow>
        </div>
      </div>

      <div class="card-section">
        <h3 class="m-b-m">3. Billing address</h3>

        <RadioField
          :defaultValue="true"
          :options="[
            { text: 'Same as delivery address', value: true },
            { text: 'Different from delivery address', value: false },
          ]"
          @change="changeBillingAddressFormVisibility"
        />

        <div v-if="!deliveryAndBillingAddressAreSame">
          <FormRow>
            <AddressPicker @select="preFillBillingAddress" />
          </FormRow>

          <div :key="billingFormKey" >
            <FormRow>
              <TextField
                label="Full name"
                placeholder="First and last name. For example: John Smith"
                mandatory
                :defaultValue="form.billingAddressFullName.value"
                :errorMessage="form.billingAddressFullName.error"
                @change="(value) => set('billingAddressFullName', value, form)"
              />
            </FormRow>
            <FormRow>
              <TextField
                label="Address line 1"
                placeholder="Street address, PO box, company name, c/o"
                mandatory
                :defaultValue="form.billingAddressLine1.value"
                :errorMessage="form.billingAddressLine1.error"
                @change="(value) => set('billingAddressLine1', value, form)"
              />
            </FormRow>
            <FormRow>
              <TextField
                label="Address line 2"
                placeholder="e.g. apartment, suite, unit, building, floor"
                :defaultValue="form.billingAddressLine2.value"
                :errorMessage="form.billingAddressLine2.error"
                @change="(value) => set('billingAddressLine2', value, form)"
              />
            </FormRow>
            <FormRow>
              <DropdownField
                label="State"
                mandatory
                :defaultValue="form.billingAddressState.value"
                :errorMessage="form.billingAddressState.error"
                @change="(value) => set('billingAddressState', value, form)"
              >
                <option
                  v-for="state in states"
                  :key="state.abbreviation"
                  :value="state.abbreviation"
                >
                  {{ state.name }}
                </option>
              </DropdownField>
            </FormRow>
            <FormRow>
              <TextField
                :defaultValue="form.billingAddressCity.value"
                mandatory
                label="City"
                :errorMessage="form.billingAddressCity.error"
                @change="(value) => set('billingAddressCity', value, form)"
              />
            </FormRow>
            <FormRow>
              <div class="col-6">
                <TextField
                  label="Postcode"
                  placeholder="If you don't have a postcode or ZIP, please write 'No Postcode'"
                  :defaultValue="form.billingAddressPostCode.value"
                  mandatory
                  :errorMessage="form.billingAddressPostCode.error"
                  @change="(value) => set('billingAddressPostCode', value, form)"
                />
              </div>
            </FormRow>
          </div>
        </div>
      </div>
    </div>

    <div class="card-section">
      <h3
        v-if="form.shippingType.value == ShippingTypes.InPerson"
        class="m-b-m"
      >
        4. Credit card
      </h3>
      <h3 v-else class="m-b-s">2. Credit card</h3>

      <p class="m-b-m">
        This field is populated by
        <a href="https://stripe.com" target="_blank">Stripe</a>, the most
        popular online payment API provider. <br /><br />After inserting the
        card data, press <strong>enter</strong> to submit the card.
      </p>

      <stripe-element-card
        @token="(card) => set('card', card, form)"
        @error="onCardError"
        :pk="constants.stripeKey"
      />

      <p v-if="!form.card.value" class="smaller-text">No card submitted</p>
    </div>

    <div class="button-container">
      <MainCTA @click="submit" :disabled="!validate(form)">Order</MainCTA>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { set, validate, required, maxLength, isEmail } from "vue-val";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

import DropdownField from "@/components/form/DropdownField.vue";
import RadioField from "@/components/form/RadioField.vue";
import TextField from "@/components/form/TextField.vue";
import FormRow from "@/components/form/FormRow.vue";
import MainCTA from "@/components/form/MainCTA.vue";

import constants from "@/util/constants";
import states from "@/util/constants/australianStates";
import { ShippingTypes, ShippingTypeMapper } from "@/util/constants/enums";
import AddressPicker from "../../components/form/AddressPicker.vue";

export default {
  name: "NotificationSettingsForm",
  components: {
    FormRow,
    DropdownField,
    RadioField,
    TextField,
    MainCTA,
    StripeElementCard,
    AddressPicker,
  },
  data() {
    return {
      // Hack used for re-renders.
      shippingFormKey: 0,
      billingFormKey: 0,

      constants,
      states,
      ShippingTypes,
      ShippingTypeMapper,

      deliveryAndBillingAddressAreSame: true,

      form: {
        email: {
          valid: false,
          error: null,
          constraints: [required, isEmail, maxLength(320)],
        },
        shippingType: {
          value: ShippingTypes.Shipping,
          valid: true,
          error: null,
          constraints: [required, maxLength(320)],
        },
        deliveryAddressFullName: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        deliveryAddressLine1: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        deliveryAddressLine2: {
          valid: true,
          error: null,
          constraints: [maxLength(320)],
        },
        deliveryAddressCity: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        deliveryAddressState: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },
        deliveryAddressPostCode: {
          valid: false,
          error: null,
          constraints: [required, maxLength(320)],
        },

        billingAddressFullName: {
          valid: true,
          error: null,
          constraints: [],
        },
        billingAddressLine1: {
          valid: true,
          error: null,
          constraints: [],
        },
        billingAddressLine2: {
          valid: true,
          error: null,
          constraints: [],
        },
        billingAddressCity: {
          valid: true,
          error: null,
          constraints: [],
        },
        billingAddressState: {
          valid: true,
          error: null,
          constraints: [],
        },
        billingAddressPostCode: {
          valid: true,
          error: null,
          constraints: [],
        },

        card: {
          valid: false,
          error: null,
          constraints: [required],
        },
      },
      set,
      validate,
    };
  },
  methods: {
    onCardError(error) {
      console.error(error);

      Vue.$toast.open({
        message: `There was an error during card processing!`,
        type: "error",
        position: "bottom",
      });
    },
    changeShippingType(shippingType) {
      this.form.shippingType.value = shippingType;

      Object.keys(this.form).forEach((key) => {
        if (
          (key.startsWith("delivery") || key.startsWith("billing")) &&
          !key.endsWith("AddressLine2")
        ) {
          const isFieldAutomaticallyValid =
            this.form.shippingType.value == ShippingTypes.InPerson;

          if (isFieldAutomaticallyValid) this.form[key].valid = true;

          this.form[key].constraints = isFieldAutomaticallyValid
            ? []
            : [required, maxLength(320)];

          set(key, this.form[key].value, this.form);
        }
      });

      this.changeBillingAddressFormVisibility(
        "" + this.deliveryAndBillingAddressAreSame
      );
    },
    changeBillingAddressFormVisibility(deliveryAndBillingAddressAreSame) {
      this.deliveryAndBillingAddressAreSame =
        deliveryAndBillingAddressAreSame == "true";

      Object.keys(this.form).forEach((key) => {
        if (key.startsWith("billing") && !key.endsWith("AddressLine2")) {
          const isFieldAutomaticallyValid =
            this.deliveryAndBillingAddressAreSame;

          if (isFieldAutomaticallyValid) this.form[key].valid = true;

          this.form[key].constraints = isFieldAutomaticallyValid
            ? []
            : [required, maxLength(320)];

          set(key, this.form[key].value, this.form);
        }
      });
    },
    preFillDeliveryAddress(address) {
      set("deliveryAddressFullName", address.fullName, this.form);
      set("deliveryAddressLine1", address.addressLine1, this.form);
      set("deliveryAddressLine2", address.addressLine2, this.form);
      set("deliveryAddressCity", address.city, this.form);
      set("deliveryAddressState", address.country, this.form);
      set("deliveryAddressPostCode", address.postcode, this.form);

      this.shippingFormKey += 1;
    },
    preFillBillingAddress(address) {
      set("billingAddressFullName", address.fullName, this.form);
      set("billingAddressLine1", address.addressLine1, this.form);
      set("billingAddressLine2", address.addressLine2, this.form);
      set("billingAddressCity", address.city, this.form);
      set("billingAddressState", address.country, this.form);
      set("billingAddressPostCode", address.postcode, this.form);

      this.billingFormKey +=1;
    },
    submit() {
      const resultObject = {};

      Object.keys(this.form).forEach(
        (key) => (resultObject[key] = this.form[key].value)
      );

      if (this.deliveryAndBillingAddressAreSame) {
        resultObject.billingAddressFullName =
          resultObject.deliveryAddressFullName;
        resultObject.billingAddressLine1 = resultObject.deliveryAddressLine1;
        resultObject.billingAddressLine2 = resultObject.deliveryAddressLine2;
        resultObject.billingAddressCity = resultObject.deliveryAddressCity;
        resultObject.billingAddressState = resultObject.deliveryAddressState;
        resultObject.billingAddressPostCode =
          resultObject.deliveryAddressPostCode;
      }

      this.$emit("submit", resultObject);
    },
  },
};
</script>